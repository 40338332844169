import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"


const initialState = {
    Question_Answer : [],
    loading:false,
    error:null
}

// const usarid = localStorage.getItem("usarid")

export const fetchQuestionAnser = createAsyncThunk('fetchquestionanswer', async () => {
    const common = useSelector(state => state.commonReducer)

    const response = await axios.post(`${Base_Url}/FarmerQuestion`,
    {
        "START": "1",
        "END": "100",
        "WORD": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": `${common.language_id}`,
        "USER_ID": `${common.usarid}`,
        "PLOT_ID": "1"
      }
    )

    return response.data.DATA
})


const questionAnswerSlice = createSlice({
    name:'question_answer',
    initialState,
    extraReducers:(builder) => {
        builder.addCase(fetchQuestionAnser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchQuestionAnser.fulfilled, (state, action) => {
            state.loading = false;
            state.Question_Answer = action.payload;
        });
        builder.addCase(fetchQuestionAnser.rejected, (state, action) =>{
            state.loading = false;
            state.error = action.error.message;
        });
    }
})

export default questionAnswerSlice.reducer