import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Base_Url } from '../Base_Url/Base_Url'
import { fetchQuestionAnser } from '../redux/QuestionAnswer'
import { AuthContext } from '../useContext/AuthContext'
import not_found from '../images/not-found.png'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'

const Quaestion_Answer = () => {
    const navigate = useNavigate()
    const [Question_Answer, setQuestion_Answer] = useState([])

    const common = useSelector(state => state.commonReducer)

    // const {Question_Answer, loading} = useSelector((state) => state.questionAnswer)
    console.log("Question_Answer", Question_Answer)

    const location = useLocation()

    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const dispatch = useDispatch()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        axios.post(`${Base_Url}/FarmerQuestion`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`,
                "USER_ID": `${common.usarid}`,
                "PLOT_ID": `${common.PLOT_ID}`
            }
        ).then((res) => {
            console.log("res",res.data.DATA);
            setQuestion_Answer(res.data.DATA)
        })

    }, [])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("प्रश्न आणि उत्तर यादी"))
    }, [])


    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    const details = (i) => {
        navigate('/question_and_answer_details',{state:i})
    }

    useEffect(() => {
        dispatch(fetchQuestionAnser())
    }, [])
    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="question-answer">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="plot-add-button-blk">
                                <a style={{ cursor: "pointer" }} onClick={() => navigate('/add_question')} className="button-style">{t(`प्रश्न विचारा`)} </a>
                            </div>
                        </div>
                        {Question_Answer.length === 0 && (
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className='text-center'>
                                <img src={not_found} alt="" style={{width:"30%"}}/>
                                </div>                                
                            </div>
                        )}
                        {Question_Answer.map((i) => {
                            return (
                                <>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card info-card sales-card">
                                            <div className="card-body" >

                                                <div className="row" onClick={() => {details(i)}}>
                                                    <div className="col-lg-2 col-md-2 col-sm-2">
                                                        <div className="question-answer-list-prod-img">
                                                            <img src={i.IMAGE_URL} className="img-fluid" alt="" style={{ width: "100px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-10 col-md-10 col-sm-10">
                                                        <div className="question-answer-list-outer-blk" style={{ display: "flex" }}>
                                                            <div style={{ width: "716px" }}>
                                                                <h5><strong>{i.NEWQUETION}?</strong></h5>
                                                            </div>
                                                            <h5><strong className='date'>{i.REG_DATE}</strong></h5>
                                                        </div>
                                                        {/* <p className='question'><strong>उत्तर: </strong></p> */}
                                                        <p className='status' style={{ marginBottom: "0", fontSize: "11px", marginTop: "0" }}>{i.STATUS}</p>
                                                    </div>
                                                </div>
                                                <hr />

                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })}

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Quaestion_Answer