import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import {Base_Url} from '../Base_Url/Base_Url'


const initialState = {
    labProduct: [],
    loading:false,
    error:null
}




export const fetchLab = createAsyncThunk('fetchlab', async (_, {getState}) => {
    const common = getState().commonReducer

    const response = await axios.post(`${Base_Url}/Get_Data`,
    {"START":"0","END":"1000","WORD":"","GET_DATA":"LabCategory_List","ID1":`${common.usarid}`,"ID2":"","ID3":"","STATUS":"","START_DATE":"","END_DATE":"","EXTRA1":"","EXTRA2":"","EXTRA3":"","LANG_ID":`${common.language_id}`}

    )

    return response.data.DATA

})



const labSlice = createSlice({
    name:"lab",
    initialState,
    extraReducers:(builder) =>{
        builder.addCase(fetchLab.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchLab.fulfilled, (state, action) => {
            state.loading = false;
            state.labProduct = action.payload;
        });
        builder.addCase(fetchLab.rejected, (state, action) => {
            state.loading = true;
            state.error = action.error.message;
        });
    }
})


export default labSlice.reducer;