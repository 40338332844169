import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Base_Url } from "../Base_Url/Base_Url";
import logo from '../images/logo.png'
import not_found from '../images/not-found.png'

const SimpleSlider = ({ settings }) => {
    const [productGallery, setProductGallery] = useState([])

    const common = useSelector((state) => state.commonReducer)

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data.DATA);
            setProductGallery(res.data.DATA)
        })
    }, [])

    const navigate = useNavigate()

    const productDetails = (i) => {
        navigate('/product_details', { state: i })
    }


    return (
        <Slider {...settings}>
            {productGallery.map((i) => {
                return (
                    <div>
                        <div className="card card-slide card-width" onClick={() => productDetails(i)} style={{cursor:"pointer"}}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "14px", marginRight: "14px", padding: "10px", borderRadius:"8px" }}>
                                <img src={i.PRODUCT_IMAGE === null ? logo : i.PRODUCT_IMAGE} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "100px", height:"150px" }} />
                            </div>
                            <h4 style={{ fontSize: "1.2rem", marginTop: "10px", textAlign: "center", fontWeight:"600" }}>{i.PRODUCT_NAME.slice(0, 7)}</h4>
                            <p style={{marginTop:"0",paddingLeft:"16px", marginBottom:"10px", display:"flex", justifyContent:"center"}}>{`${i.PRODUCT_DESCRIPTION.slice(3, 20)}...`}</p>
                            
                        </div>
                    </div>
                )
            })}
            {/* <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Commercial Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (25k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 599</span><s>&#8377;699</s></p>
                    </div>
                </div>
                <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Residential Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (25k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 499</span><s>&#8377;899</s></p>
                    </div>
                </div>
                <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Drainage Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (25k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 399</span><s>&#8377;499</s></p>
                    </div>
                </div>
                <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Water Supply Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (30k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 499</span><s>&#8377;599</s></p>
                    </div>
                </div>
                <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Water Supply Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (30k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 499</span><s>&#8377;599</s></p>
                    </div>
                </div>

                <div>
                    <div className="card card-slide">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={not_found} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "85%" }} />
                        </div>
                        <h4 style={{fontSize:"1.2rem", marginTop:"10px", textAlign:"center" }}>Water Supply Services</h4>
                        <div style={{paddingLeft:"17px", paddingBottom:"10px"}}>
                            <i class="bi bi-star-fill" style={{fontSize:"15px", color:"#ff3b00d1"}}></i><span style={{marginLeft:"10px"}}>4.1 (30k)</span>
                        </div>
                        <p className="googlefont" style={{paddingLeft:"15px"}}><span style={{color:"green", fontWeight:"500", marginLeft:"5px", marginRight:"10px"}}>&#8377; 499</span><s>&#8377;599</s></p>
                    </div>
                </div> */}

        </Slider>
    );
}


export default SimpleSlider
