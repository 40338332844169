import axios from "axios";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Base_Url } from "../Base_Url/Base_Url";
import { updateState } from "../redux/Persist";
import { AuthContext } from "../useContext/AuthContext";
import { animateScroll } from "react-scroll";

const usarname = localStorage.getItem("usarname")
const usarid = localStorage.getItem("usarid")
const mobile = localStorage.getItem("mobile")


const Address = () => {
    const navigate = useNavigate()
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [taluka, setTaluka] = useState([])
    const [stateInput, setStateInput] = useState("")
    const [cityInput, setCityInput] = useState("")
    const [talukaInput, setTalukaInput] = useState("")
    const [village, setVillage] = useState("")
    const [pincode, setPincode] = useState("")
    const [id, setId] = useState()
    const [cityId, setCityId] = useState("")
    const [stateInput1, setStateInput1] = useState(true)
    const [cityInput1, setCityInput1] = useState(true)
    const [talukaInput1, setTalukaInput1] = useState(true)
    const [village1, setVillage1] = useState(true)
    const [pincode1, setPincode1] = useState(true)
    const [loading, setLoading] = useState(false)

    const common = useSelector(state => state.commonReducer)

    const location = useLocation()

   

    const dispatch = useDispatch()


    const { sidebar, setNav, setMainNav, setSidebar,setnewDemo, setColorChange } = useContext(AuthContext)

    const { handleSubmit } = useForm()

    const Styles = {
        sidebar1: {
            display: "none"
        },
        sidebar4: {
            color: "red",
            marginLeft: "5px"
        }
    }

    useEffect(() => {
        setnewDemo(t("पत्ता निवडा"))
        setColorChange(false)
    },[])

    const Submit = () => {
        if (!stateInput || stateInput === 'Select State') {
            setStateInput1(false)
        } else if (!cityInput || cityInput === 'Select City') {
            setCityInput1(false)
        } else if (!talukaInput || talukaInput === 'Select Taluka') {
            setTalukaInput1(false)
        } else if (!village) {
            setVillage1(false)
        } else if (!pincode) {
            setPincode1(false)
        } else {
            setLoading(true)

            axios.post(`${Base_Url}/UserRegistration`,
                {
                    "USER_ID": `${common.usarid}`,
                    "FULL_NAME": `${common.usarname}`,
                    "MOBILE_NO": `${common.mobile}`,
                    "PINCODE": `${pincode}`,
                    "EMAIL": "",
                    "DATE_OF_BIRTH": "",
                    "ADHARCARD_NUMBER": "",
                    "ADDRESS": "",
                    "TOKEN": "",
                    "STATE_ID": `${stateInput}`,
                    "CITY_ID": `${cityInput}`,
                    "TALUKA_ID": `${talukaInput}`,
                    "VILLAGE_NAME": `${village}`,
                    "PROFILE_PHOTO": "",
                    "TASK": "UPDATE",
                    "EXTRA1": "",
                    "EXTRA2": "",
                    "EXTRA3": "",
                    "LANG_ID": `${common.language_id}`
                }
            ).then((res) => {
                console.log(res.data.DATA)
                setLoading(false)
                dispatch(updateState({
                    newusar:"ok"
                }))
                navigate('/')
            })
        }

        
    }

    useEffect(() => {
        if (common.usarname) {
            setMainNav(true)
            setNav(true)
        }
    }, [])

    const pin = (e) => {
        const value = e.target.value
        if (!Number(value)) {
            return setPincode("")
        } else if (value.length <= 6) {
            return setPincode(value)
        }
    }

    const getState = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "State_List",
                "ID1": "",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            },

        ).then((res) => {
            console.log(res.data.DATA);
            setState(res.data.DATA)
        })
    }
    const getCity = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "City_List",
                "ID1": "1",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA)
            setCity(res.data.DATA)
        })
    }

    useEffect(() => {
        setStateInput("1")
        setCityInput("1")
        setTalukaInput("1")
    },[stateInput, cityInput, talukaInput])



    const getTaluka = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "Taluka_List",
                "ID1": "1",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA)
            setTaluka(res.data.DATA)
        })
    }


    useEffect(() => {
        getState()
        getCity()
        getTaluka()
        if (window.innerWidth > 768) {
            setSidebar(true)
        }
    }, [window.innerWidth])


    return (
        <div className="main">

            <main id="main"  className={sidebar ? 'sidebar1' : 'sidebar2'}>



                <section className="section dashboard" id="Dashboard-form">
                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">                                        
                                        <div className="row active ">
                                            <div className="xdemo1" id="xaddress1">
                                                <div className="row active">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <h5 className="card-title">{t(`पत्ता निवडा`)}</h5>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t(`राज्य`)}</label>
                                                            <select className="form-select minimal" value={stateInput} onChange={(e) => setStateInput(e.target.value)} title="Select State" name="state_name" id="state_name"
                                                                districturl="/district/">
                                                                {/* <option selected>राज्य निवडा</option> */}
                                                                {state.map((state, index) => {
                                                                    return (
                                                                        <option value={state.STATE_ID} key={index}>{state.STATE_NAME}</option>
                                                                    )
                                                                })}


                                                            </select>
                                                            <div style={stateInput1 ? Styles.sidebar1 : Styles.sidebar4} >{t(`कृपया तुमचे राज्य निवडा`)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t(`जिल्हा`)}</label>
                                                            <select className="form-select minimal" value={cityInput} onChange={(e) => setCityInput(e.target.value)} name="district_name" id="district_name"
                                                                talukaurl="/taluka/">
                                                                {/* <option selected >जिल्हा निवडा</option> */}
                                                                {city.map((city, index) => {
                                                                    return (
                                                                        <option value={city.CITY_ID} key={index}>{city.CITY_NAME}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div style={cityInput1 ? Styles.sidebar1 : Styles.sidebar4} >{t(`कृपया तुमचा जिल्हा निवडा`)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t(`तालुका`)}</label>
                                                            <select className="form-select minimal" value={talukaInput} onChange={(e) => setTalukaInput(e.target.value)} name="taluka_name" id="taluka_name"
                                                                mastervillageurl="/master_village/">
                                                                {/* <option selected>तालुका निवडा</option> */}
                                                                {taluka.map((taluka, index) => {
                                                                    return (
                                                                        <option value={taluka.TALUKA_ID} key={index}>{taluka.TALUKA_NAME}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div style={talukaInput1 ? Styles.sidebar1 : Styles.sidebar4} >{t(`कृपया तुमचा तालुका निवडा`)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t(`गाव`)}</label>
                                                            <input type="text" className="form-control minimal" value={village} onChange={(e) => setVillage(e.target.value)} placeholder="गावाचे नाव टाका" />
                                                            <div style={village1 ? Styles.sidebar1 : Styles.sidebar4} >{t(`कृपया गावाचे नाव टाका`)}</div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t(`पिनकोड`)}</label>
                                                            <input type="text" className="form-control minimal" value={pincode} onChange={(e) => pin(e)} placeholder="पिनकोड क्रमांक टाका" />
                                                            <div style={pincode1 ? Styles.sidebar1 : Styles.sidebar4} >{t(`कृपया पिनकोड क्रमांक टाका`)}</div>

                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                            <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                                <button className="btn btn-success text-center" onClick={handleSubmit(Submit)}>
                                                    {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "37px" }}>
                                                        <RotatingLines
                                                            strokeColor="green"
                                                            strokeWidth="4"
                                                            animationDuration="0.75"
                                                            width="21"
                                                            visible={true}
                                                        /></span>}
                                                    {t(`प्रस्तुत करा`)}</button>
                                                {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

            </main>



        </div>
    )
}


export default Address