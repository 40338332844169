import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"


const initialState = {
    crop:[],
    loading:false,
    error:null
}


export const fetchCrop = createAsyncThunk('fetchcrop', async() => {
    const common = useSelector((state) => state.commonReducer)
    const response = await axios.post(`${Base_Url}/Get_Data`,
    {
        "START": "1",
        "END": "100",
        "WORD": "",
        "GET_DATA": "All_Crop_List",
        "ID1": "1",
        "ID2": "",
        "ID3": "",
        "STATUS": "",
        "START_DATE": "",
        "END_DATE": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": `${common.language_id}`
      }
    )
    return response.data.DATA
})

const cropSlice = createSlice({
    name:'crop',
    initialState,
    extraReducers:(builder) =>{
        builder.addCase(fetchCrop.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchCrop.fulfilled, (state, action) => {
            state.loading = false;
            state.crop = action.payload;
        });
        builder.addCase(fetchCrop.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export default cropSlice.reducer;