import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import plant from '../images/plant2.png'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import autoimmune_disease from '../images/autoimmune-disease_1.png'
import product_gallery from '../images/brand-identity.png'
import lab from '../images/report.png'


const Mobile_Navbar = () => {
    const navigate = useNavigate()

    const { setNav, nav } = useContext(AuthContext)
    const common = useSelector(state => state.commonReducer)

    const { t } = useTranslation(); 
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])


    return (
        <div>
            {nav && <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
                <div className="container">
                    <header className="mob-footer">
                        <nav className="bottom-nav">
                            <div className="bottom-nav-item active">
                                <div className="bottom-nav-link">
                                    <a onClick={() => navigate('/')} style={{ cursor: "pointer" }}>
                                        <i className="bi bi-shop"></i>
                                        <span>{t('मुख्यपृष्ठ')}</span>
                                    </a>
                                </div>
                            </div>                            
                            <div className="bottom-nav-item">
                                <div className="bottom-nav-link">
                                    <a onClick={() => navigate('/product_gallery')} style={{ cursor: "pointer", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"4px" }}>
                                        {/* <i className="bi bi-handbag"></i> */}
                                        <img src={product_gallery} alt="" />
                                        <span>{t('उत्पादन गॅलरी')}</span>
                                    </a>
                                </div>
                            </div>
                            <div className="bottom-nav-item">
                                <div className="bottom-nav-link">
                                    <a onClick={() => navigate('/lab')} style={{ cursor: "pointer", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"3px"  }}>
                                        {/* <i className="bi bi-eyedropper"></i> */}
                                        <img src={lab} alt="" />
                                        <span>{t('लॅब')}</span>
                                    </a>
                                </div>
                            </div>
                            <div className="bottom-nav-item">
                                <div className="bottom-nav-link">
                                    <a onClick={() => navigate('/crop')} style={{ cursor: "pointer" }}>
                                        <i class="bi bi-flower3"></i>
                                        <span>{t('पिके')}</span>
                                    </a>
                                </div>
                            </div>
                            {/* <div className="bottom-nav-item">
                                <div className="bottom-nav-link">
                                    <a onClick={() => navigate('/deseascontrol')} style={{ cursor: "pointer" }}>
                                   <img src={autoimmune_disease} alt="" style={{width:"38px"}} /><br/>
                                        <span style={{fontSize:"12px"}}>{t('रोग नियंत्रण')}</span>
                                    </a>
                                </div>
                            </div> */}

                            {/* <div className="bottom-nav-item">
                                <div className="bottom-nav-link">
                                    <a >
                                        <i className="bi bi-share"></i>
                                        <span>Share</span>
                                    </a>
                                </div>
                            </div> */}

                            {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}




                        </nav>
                    </header>
                </div>
            </section>}
        </div>
    )
}

export default Mobile_Navbar