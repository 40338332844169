import { createContext, useRef, useState } from "react"


const initialState = {
    loading:false
}

export const AuthContext = createContext(initialState)

const AuthContextProvider = ({children}) => {
    const [sidebar, setSidebar] = useState(false)
    const [nav, setNav] = useState(false)
    const [leftArrow, setLeftArrow] = useState(false)
    const [colorchange, setColorChange] = useState(true)
    const [newDemo2, setnewDemo] = useState("")
    const [mainNav, setMainNav] = useState(false)
        const [product, setProduct] = useState([])

    const anchorRef = useRef(null)

    return(
        <AuthContext.Provider value={{sidebar, setSidebar,product, setProduct, anchorRef, nav, setNav, mainNav, setMainNav, leftArrow, setLeftArrow, colorchange, setColorChange, newDemo2, setnewDemo}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;