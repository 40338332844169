import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import produc_gallery2 from '../images/produc-gallery2.jpg'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser'
import axios from 'axios'
import { color } from '@mui/system'
import { Base_Url } from '../Base_Url/Base_Url'
import { toast } from 'react-toastify'
import logo from '../images/logo.png'
import { useForm } from 'react-hook-form'
import { RotatingLines } from 'react-loader-spinner'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Carousel } from 'react-bootstrap'
import { animateScroll } from 'react-scroll'


const user_id = localStorage.getItem("usarid")
const mobile = localStorage.getItem("mobile")
const usarname = localStorage.getItem("usarname")
const Product_Details = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [remark, setRemark] = useState("")
    const [remark1, setRemark1] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState()
    const [modal, setModal] = useState("")
    const [image, setImage] = useState([])

    const common = useSelector(state => state.commonReducer)


    const location = useLocation()
    console.log("location", location.state);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const Styles = {
        usarname: {
            display: "none"
        },
        usarname2: {
            marginLeft: '5px',
            color: "red"
        },
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("उत्पादन माहिती"))
    }, [])

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "1000", "WORD": "", "GET_DATA": "ProductImage_List", "ID1": `${location.state.PRODUCT_ID}`, "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data);
            setImage(res.data.DATA)
        })
    }, [])


    const navigate = useNavigate()

    const { handleSubmit } = useForm()
    const sendEnquiry = () => {
        console.log("remark", remark);
        setLoading1(true)
        if (!remark) {
            return setRemark1(true)
        } else {
            setLoading(true)
            axios.post(`${Base_Url}/ProductEnquiry/1`,
                {
                    "USER_ID": `${common.usarid}`,
                    "PRODUCT_ID": `${location.state.PRODUCT_ID}`,
                    "REMARK": `${remark}`,
                    "EXTRA1": "",
                    "EXTRA2": "",
                    "EXTRA3": "",
                    "TASK": "ADD",
                    "FULL_NAME": `${common.usarname}`,
                    "MOBILE_NO": `${common.mobile}`
                }
            ).then((res) => {
                console.log(res.data.DATA)
                setLoading(false)
                setModal("modal")
                navigate('/product_enquiry_list')
                toast.success(t("उत्पादन चौकशी यशस्वीरित्या पाठवली"))
            })
        }


    }

    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])


    return (
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body new-car-body" style={{height:"250px"}}>
                                    <Carousel className='text-center'>
                                        {image.map((i) => {
                                            return (
                                                <Carousel.Item>
                                                    <img
                                                        className=" h-100"
                                                        src={i.PRODUCT_IMAGE}
                                                        alt="First slide"
                                                    />
                                                </Carousel.Item>
                                            )
                                        })}
                                    </Carousel>
                                
                            </div>
                        </div>
                    </div>
                    <div id="product-description">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="product-name-blk"><h4>{location.state.PRODUCT_NAME}</h4></div>
                                    </div>
                                    <div className="prod-descrip ">
                                        <h5>{t('तपशील')}</h5>
                                        <p>{parse(location.state.PRODUCT_DESCRIPTION)}</p>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                        <div className="mob-view-btn">
                                            <button className="btn btn-success text-center" data-bs-toggle="modal" data-bs-target="#exampleModal2">{t('चौकशी पाठवा')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header" style={{ background: "#559f55", color: "#ffffff" }}>
                                    <h5 class="modal-title text-center" id="exampleModalLongTitle" style={{ fontSize: "1.5rem", marginLeft: "28%" }}>{t('उत्पादन चौकशी')}</h5>
                                    <button type="button" class="close" style={{ border: "none", background: "transparent" }} data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "28px", color: "#fff" }}>&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>{t('शेरा')}</h4>
                                            <div className="col-lg-12">
                                                <textarea type="text" cols={4} rows={5} placeholder={`${t('शेरा')}`} value={remark} onChange={(e) => setRemark(e.target.value)} style={{ width: "100%", resize: "none", borderRadius: "6px" }} ></textarea>
                                            </div>
                                            <div style={remark1 ? Styles.usarname2 : Styles.usarname} >{t('कृपया तुमची टिप्पणी टाका')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer text-center">
                                    <button type="button" class="btn btn-success" data-bs-dismiss={!remark ? "" : "modal"} aria-label="Close" disabled={loading} onClick={handleSubmit(sendEnquiry)}>
                                        {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "35px" }}>
                                            <RotatingLines
                                                strokeColor="green"
                                                strokeWidth="4"
                                                animationDuration="0.75"
                                                width="21"
                                                visible={true}
                                            /></span>}
                                        {t('प्रस्तुत करणे')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </div >
    )
}

export default Product_Details