import axios from 'axios'
import i18next from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Base_Url } from '../Base_Url/Base_Url'
import login_bg from '../images/login-bg.jpg'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'

const Login = () => {
  const [mobile, setMobile] = useState('')
  const navigate = useNavigate()
  const [mobileN1, setMobile1] = useState(false)
  const [mobileN2, setMobile2] = useState(false)
  const [loading, setLoading] = useState(false)
  const common = useSelector(state => state.commonReducer)
  const { handleSubmit } = useForm()

  const location = useLocation()

  useEffect(() => {
    animateScroll.scrollToTop({
        duration:0
    })
},[location.pathname])

  const { setMainNav, setNav, setSidebar } = useContext(AuthContext)
  const mobileNumber = (e) => {
    const value = e.target.value
    if (!Number(value)) {
      return setMobile("");
    } else if (value.length <= 10) {
      return setMobile(value)
    }


  }

  useEffect(() => {
    setSidebar(false)
  }, [])

  const Style = {
    usarname: {
      display: "none"
    },
    usarname2: {
      marginTop: '-12px',
      marginLeft: '5px',
      color: "red"
    },
    usarname3: {
      color: "red"
    }
  }
  const loginOtp = () => {
    const patern2 = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    if (!mobile) {
      return setMobile1(false), 
       setMobile1(true)
    } else if (!patern2.test(mobile)) {
      return setMobile1(false),
      setMobile2(true)
    }
    else if (mobile.length === 10) {
      return setLoading(true),
        axios.post(`${Base_Url}/UserRegistration/1`,
          {
            "MOBILE_NO": `${mobile}`
          }).then((res) => {
            console.log(res.data.DATA)
            const register = res.data.DATA
            if (register[0].ResponseMessage === "New Farmer") {
              axios.post(`${Base_Url}/OTP_Mobile`,
                {
                  "MOBILE_NO": `${mobile}`
                }).then((res) => {
                  console.log(res.data.DATA)
                  const otp = res.data.DATA
                  setLoading(false)
                  navigate("/loginotp", { state: { otp, mobile } })
                })
            } else {
              return alert("Please Sign up First"),
                navigate('/register')
            }
          })
    }
    setMobile2(true)
  }

  useEffect(() => {
    setMainNav(true)
    setNav(false)
    localStorage.clear()
  }, [])



  const { t } = useTranslation(); 
  useEffect(() => {
      const lang = common.language
      i18next.changeLanguage(lang)
  },[])



  return (
    <div>
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container-fluid">
          <div className="row">

            <div className="none-effect col-lg-7 col-md-7 ">
              <div className="col-xl-7 b-center bg-size">
                <img className="bg-img-cover bg-center" src={login_bg} alt="looginpage" /></div>
            </div>
            <div className="col-lg-5 col-md-5 d-flex flex-column ">

              <div className="d-flex justify-content-center">
                {/* <!--   <a href="index.html" className="login-logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span className="d-none d-lg-block"><img src="images/logo.png" className="img-fluid" alt="Krushi doot logo"></span>
                </a> --> */}
              </div>

              <div className="card mt-10 mb-3">

                <div className="card-body mr-t5 " id="login-form">

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">{t('आपल्या खात्यात लॉगिन करा')}</h5>
                    <p className="text-center small">{t('परत स्वागत आहे! आपल्या खात्यात लॉग इन करा.')}</p>
                  </div>

                  <form className="row g-3 needs-validation" >

                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">{t('मोबाइल नंबर')}</label>
                      <div className="input-group has-validation">
                        <input type="text" name="username" className="form-control login-input" value={mobile} onChange={(e) => mobileNumber(e)} id="yourUsername" required placeholder={`${t('तुमचा मोबाइल नंबर टाका')}`} />
                      </div>
                      <div style={mobileN1 ? Style.usarname2 : Style.usarname} >{t('कृपया तुमचा मोबाइल नंबर टाका')}</div>
                      <div style={mobileN2 ? Style.usarname2 : Style.usarname} >{t('कृपया तुमचा वैध मोबाइल नंबर टाका')}</div>
                    </div>

                    {/* <!-- <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control login-input" id="yourPassword" required>
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div> --> */}


                    <div className="col-12">
                      {/* <!-- <button className="btn btn-success text-center" type="submit">Login <i className="icon-right-arrow"></i></button> --> */}
                      <button style={{ cursor: "pointer" }} disabled={loading} className="btn btn-success text-center" onClick={handleSubmit(loginOtp)}>
                        {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "47px" }}><RotatingLines
                          strokeColor="green"
                          strokeWidth="4"
                          animationDuration="0.75"
                          width="21"
                          visible={true}
                        /></span>}
                        {t('लॉगिन करा')}</button>
                    </div>
                    <div className="col-12">
                      <p className="small mb-0">{t('खाते नाही का?')} <a onClick={() => navigate('/register')} className="hover-register">{t('खाते तयार करा')} </a></p>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}

export default Login