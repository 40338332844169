import axios from 'axios'
import React, { useContext, useState } from 'react'
import login_bg from '../images/login-bg.jpg'
import { Base_Url } from '../Base_Url/Base_Url'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RotatingLines } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'

const Register = () => {
    const [fname, setFname] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [newChecked, setChecked] = useState(false)
    const [newChecked1, setChecked1] = useState(false)
    const [fullname, setFullName] = useState(false)
    const [email1, setEmail1] = useState(false)
    const [mobile1, setMobile1] = useState(false)
    const [loading, setLoading] = useState(false)
    const common = useSelector(state => state.commonReducer)
    const navigate = useNavigate()

    const { setMainNav, setNav, setSidebar } = useContext(AuthContext)

    const { handleSubmit } = useForm()

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const Style = {
        usarname: {
            display: "none"
        },
        usarname2: {
            marginBottom: "14px",
            color: "red"
        },
        usarname3: {
            color: "red"
        }
    }


    const mobileNumber = (e) => {
        const value = e.target.value;
        if (!Number(value)) {
            return setMobile("")
        } else if (value.length <= 10) {
            return setMobile(value)
        }
    }

    console.log("Lang", typeof (Lang))
    const signup = () => {
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const patern2 = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/



        if (!fname) {
            return setFullName(true)
        } else if (!email) {
            return setEmail1(true)
        } else if (!mobile) {
            return setMobile1(true)
        } else if (newChecked === false) {
            return setChecked1(true)
        }
        else if (!pattern.test(email)) {
            return alert("Please Enter Valid Email")
        } else if (!patern2.test(mobile)) {
            return alert("Please Enter Valid Mobile Number")
        }

        else if (mobile.length === 10) {
            return setLoading(true),
                axios.post(`${Base_Url}/UserRegistration/1`,
                    {
                        "MOBILE_NO": `${mobile}`
                    }
                ).then((res) => {
                    console.log(res.data.DATA)
                    const register = res.data.DATA;
                    if (register[0].ResponseMessage === "Already exist") {
                        axios.post(`${Base_Url}/OTP_Mobile`,
                            {
                                "MOBILE_NO": `${mobile}`
                            }).then((res) => {
                                console.log(res.data.DATA)
                                const otp = res.data.DATA
                                setLoading(false)
                                navigate("/loginotp", { state: { otp, mobile, fname, email } })
                            })
                    }

                    else {
                        return alert("Already Register"),
                            navigate('/login')
                    }
                })


        }
    }

    useEffect(() => {
        setMainNav(true)
        setNav(false)
        localStorage.clear()
      }, [])


    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])




    return (
        <div>
            <main>
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="none-effect col-lg-7 col-md-7 ">
                                <div className="col-xl-7 b-center bg-size">
                                    <img className="bg-img-cover bg-center" src={login_bg} alt="looginpage" /></div>
                            </div>
                            <div className="col-lg-5 col-md-5 d-flex flex-column ">
                                <div className="d-flex justify-content-center">
                                    {/* <!--   <a href="index.html" className="login-logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span className="d-none d-lg-block"><img src="images/logo.png" className="img-fluid" alt="Krushi doot logo"></span>
                </a> --> */}
                                </div>

                                <div className="card mt-5 mb-3">

                                    <div className="card-body" id="login-form">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">{t('खाते तयार करा')}</h5>
                                            <p className="text-center small">{t('खाते तयार करण्यासाठी आपले वैयक्तिक तपशील प्रविष्ट करा')}</p>
                                        </div>

                                        <form className="row g-3 needs-validation" novalidate>
                                            <div className="col-12">
                                                <label for="yourName" className="form-label">{t('पूर्ण नाव')}</label>
                                                <input type="text" name="name" className="form-control login-input" placeholder={`${t('कृपया तुमचे पूर्ण नाव टाका')}`} value={fname} onChange={(e) => setFname(e.target.value)} id="yourName" required />
                                                <div style={fullname ? Style.usarname3 : Style.usarname} >{t('कृपया तुमचे पूर्ण नाव टाका!')}</div>
                                            </div>

                                            <div className="col-12">
                                                <label for="yourEmail" className="form-label">{t('ईमेल')} </label>
                                                <input type="email" name="email" className="form-control login-input" placeholder={`${t('कृपया तुमचा ईमेल आयडी टाका')}`} value={email} onChange={(e) => setEmail(e.target.value)} id="yourEmail" required />
                                                <div style={email1 ? Style.usarname3 : Style.usarname}>{t('कृपया तुमचा वैध ईमेल आयडी प्रविष्ट करा!')}</div>
                                            </div>

                                            <div className="col-12">
                                                <label for="yourUsername" className="form-label">{t('मोबाइल नंबर')}</label>

                                                <input type="text" name="username" className="form-control login-input" placeholder={`${t('कृपया तुमचा मोबाइल नंबर टाका')}`} value={mobile} onChange={(e) => mobileNumber(e)} id="yourUsername" required />
                                                <div style={mobile1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा मोबाइल नंबर टाका!')}</div>
                                            </div>

                                            <div className="col-12 last">
                                                <div className="form-check ">
                                                    <input type='checkbox' style={{ width: "32px", marginLeft: "-12px", cursor:"pointer" }} onChange={() => setChecked(!newChecked)} />
                                                    <label className="form-check-label" for="acceptTerms">
                                                        <p style={{ color: "green" }}>{t('मी अट्टी व शर्तीशी सहमत आहे')}</p></label>
                                                    <div style={newChecked1 ? Style.usarname2 : Style.usarname}>{t('मी गोपनीयता धोरणाशी सहमत आहे')}</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-success text-center" disabled={loading} onClick={handleSubmit(signup)}>
                                                    {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "65px" }}>
                                                        <RotatingLines
                                                            strokeColor="green"
                                                            strokeWidth="4"
                                                            animationDuration="0.75"
                                                            width="21"
                                                            visible={true}
                                                        /></span>}
                                                    {t('खाते तयार करा')}</button>
                                                {/* <!-- <button className="btn btn-success text-center" type="submit">Sign Up</button> --> */}
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </div>
    )
}

export default Register