import { t } from 'i18next'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'

const Question_And_Answer_Details = () => {
    const location = useLocation()

    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
    setLeftArrow(false)
    setColorChange(false)
    setnewDemo(t("प्रश्न आणि उत्तर तपशील"))
  }, [])

    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="question-answer">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">                            
                            <div className="card info-card sales-card">
                                <div className="card-body" >
                                    <div className="row" style={{ cursor: "pointer" }} >
                                        <div className="col-lg-3 col-md-3 col-sm-3" style={{ marginBottom: "30px" }}>
                                            <div className="question-answer-list-prod-img">
                                                <img src={location.state.IMAGE_URL} className="img-fluid" width='50%' alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9">
                                            <div className="question-answer-list-outer-blk" style={{ display: "flex" }}>
                                                <div>
                                                    <h5><strong>{location.state.NEWQUETION}</strong></h5>
                                                </div>
                                            </div>
                                            <p>{t(`उत्तर`)}:-</p>
                                            <p style={{ marginTop: "0" }} className='question'><strong>{location.state.DESCRIPTION}</strong></p>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}

export default Question_And_Answer_Details