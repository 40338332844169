import React, { useContext, useEffect, useState } from 'react'
import test_tube from '../images/test-tube.png'
import flask from '../images/flask.png'
import leaf from '../images/leaf.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import { fetchLab } from '../redux/Lab'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import not_found from '../images/not-found.png'
import { animateScroll } from 'react-scroll'


const Lab = (props) => {
    const navigate = useNavigate()
    const [labProduct, setLabProduct] = useState([])

    const { sidebar, setLeftArrow, colorchange, setColorChange , setnewDemo} = useContext(AuthContext)

    const common = useSelector(state => state.commonReducer)

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    // const { labProduct } = useSelector((state) => state.Lab)
    // console.log("labProduct", labProduct);
    const dispatch = useDispatch()

    useEffect(() => {

    },[])
    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo("Lab")
    },[])


    useLayoutEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
    {"START":"0","END":"1000","WORD":"","GET_DATA":"LabCategory_List","ID1":`${common.usarid}`,"ID2":"","ID3":"","STATUS":"","START_DATE":"","END_DATE":"","EXTRA1":"","EXTRA2":"","EXTRA3":"","LANG_ID":`${common.language_id}`}

    ).then((res) => {
        setLabProduct(res.data.DATA)
    })
    }, [])


    const { t } = useTranslation(); 
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])

    useEffect(() => {
        if(window.location.origin + "/" == window.location.href){
          return setLeftArrow(true)
        }
      },[])



    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>

                <section className="section dashboard" id="disease">
                    <div className="row" id="lab">
                        
                        {labProduct.length === 0 &&
                                <div className='text-center'>
                                    <img src={not_found} className="img-fluid" alt="" />
                                </div>}
                            {labProduct.map((i) => {
                                return (
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <div className="card info-card sales-card">
                                            <div className="card-body">
                                                <a style={{ cursor: "pointer" }} onClick={() => navigate('/laboratory',{state:i})}>
                                                    <img src={i.LCAT_IMAGE} style={{width:"80%", borderRadius:"8px", height:"155px"}} width="64" className="img-fluid" alt="" />
                                                    <div className="product-name-blk">
                                                        <div className="text-center">
                                                            <div className="product-title">
                                                                <h5>{i.LCAT_NAME}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="btn read-more ">
                                                            <button type="button"> {t('चौकशी पाठवा')}  </button>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}    



                    </div>
                </section>

            </main>

        </div>
    )
}

export default Lab