import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser'
import logo from '../images/logo.png'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Desease_control_2 from './Desease_Control_2'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'

// import parse from 'html-react-parser'


const Crop_List_Details = () => {
    const location = useLocation()
    console.log("location",location.state);
    const { sidebar, setLeftArrow, setnewDemo, setColorChange } = useContext(AuthContext)
    const [value, setValue] = React.useState('1');

  
    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log("newValue",newValue);
      };

      useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        setLeftArrow(false)
        setnewDemo(location.state.CROP_NAME)
        setColorChange(false)
    }, [])

    return (
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <Box sx={{ width: '100%', typography: 'body1' , marginBottom:"30px"}}>
                        <TabContext value={value}                        
                        >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example"
                                TabIndicatorProps={{
                                    sx :{backgroundColor:"red"}
                                }}
                                >
                                    <Tab label={t("तपशील")} value="1" />
                                    <Tab label={t("रोग नियंत्रण")} value="2" />                                   
                                </TabList>
                            </Box>                          
                                                       
                       
                    <TabPanel value="1">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div
                                                className="text-center">
                                                <img style={{ height: "299px" }} src={location.state.CROP_IMAGE} className="img-fluid img-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="product-name-blk"><h4>{location.state.CROP_NAME}</h4></div>
                                        </div>
                                        <div className="prod-descrip ">
                                            <p>{parse(location.state.CROP_DESCRIPTION)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <Desease_control_2 value={location.state}/>
                    </TabPanel>
                    </TabContext>
                    </Box>
                </section>
            </main>
        </div>
    )
}

export default Crop_List_Details