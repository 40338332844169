import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"

const initialState = {
    Desease : [],
    loading:false,
    error:null
}

export const fetchDesease = createAsyncThunk('fetchdesease', async () => {
    const common = useSelector(state => state.commonReducer)

    const response = await axios.post(`${Base_Url}/Get_Data`,
    {
        "START": "1",
        "END": "100",
        "WORD": "",
        "GET_DATA": "DiseaseControl_List",
        "ID1": `${common.usarid}`,
        "ID2": "",
        "ID3": "",
        "STATUS": "",
        "START_DATE": "",
        "END_DATE": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": `${common.language_id}`
      }
    )
    return response.data.DATA
})


const desesaseSlice = createSlice({
    name:"desease",
    initialState,
    extraReducers:(builder) => {
        builder.addCase(fetchDesease.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchDesease.fulfilled, (state, action) => {
            state.loading = false;
            state.Desease = action.payload;
        });
        builder.addCase(fetchDesease.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})


export default desesaseSlice.reducer