import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { fetchSchedule } from '../redux/Schedule'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useState } from 'react'
import not_found from '../images/not-found.png'
import { t } from 'i18next'


const Schedule = () => {
  const { sidebar, setColorChange, setnewDemo } = useContext(AuthContext)
  const [schedule, setSchedule] = useState([])
  const [days, setDays] = useState(false)
  const [yes, setYes] = useState("")
  const [no, setNo] = useState([])

  const common = useSelector(state => state.commonReducer)
  var txt;


  const navigate = useNavigate()

  const location = useLocation()
  console.log("plot_id", location.state.PLOT_ID);

  // const { schedule } = useSelector((state) => state.schedule)
  console.log("schedule", schedule)

  

  const dispatch = useDispatch()

  const Styles = {
    sidebar1: {
      marginLeft: "300px"
    },
    sidebar2: {
      marginLeft: ""
    }
  }

  useEffect(() => {
    setColorChange(false)
    setnewDemo(t("वेळापत्रक"))
  }, [])

  useEffect(() => {
    axios.post(`${Base_Url}/Schedule`,
      {
        "START": "1",
        "END": "100",
        "WORD": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": `${common.language_id}`,
        "USER_ID": "33",
        "PLOT_ID": "128",
        "SCHEDULE_TYPE": "1"
      }
    ).then((res) => {
      console.log("res", res.data.DATA);
      setSchedule(res.data.DATA);
      console.log("sd", res.data.DATA.SCHEDULE_DAY);
    })

  }, [])

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    })
  }, [location.pathname])

  useEffect(() => {
    dispatch(fetchSchedule())
  })

  const Schedule_Details = (i) => {
    navigate('/Schedule_Details', { state: i })
  }







  const handlemsg = (i) => {
    console.log("idata", i);
    if (window.confirm('Are you sure your schedule is completed?') == true) {
      txt = "you press ok";
      // console.log("data",txt);

      console.log("data", days);
      axios.post(`${Base_Url}/UserScheduleConfirmation `,
        {
          "USER_ID": "33",
          "PLOT_ID": "128",
          "SCHEDULE_ID": `${i.SCHEDULE_ID}`,
          "IS_APPLIED": "Yes",
          "CROP_ID": "1",
          "CROPVARIETY_ID": "1",
          "EXTRA1": "",
          "EXTRA2": "",
          "EXTRA3": "",
          "LANG_ID": `${common.language_id}`
        }
      ).then((res) => {
        console.log("da", res.data.ResponseMessage);
        setDays(true);
        //  console.log("da",res.data.ResponseMessage);



        setYes(res.data.ResponseMessage);
        console.log(" yes ", yes);
        yes.map(i => console.log("yes", i[0].ResponseMessage))

      }).catch((err) => {
        console.log(err.message);
      })

    }
    else {


      txt = "you press cancel"
      // console.log("data",txt);

      console.log("data", days);
      axios.post(`${Base_Url}/UserScheduleConfirmation `,
        { "USER_ID": "33", "PLOT_ID": "128", "SCHEDULE_ID": `${i.SCHEDULE_ID}`, "IS_APPLIED": "No", "CROP_ID": "1", "CROPVARIETY_ID": "1", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": "1" }




      ).then((res) => {
        console.log("da", res.data);
        setDays(false);
        //  console.log("da",res.data.ResponseMessage);

        setNo(res.data);
        console.log("no", no);
      }).catch((err) => {
        console.log(err.message);
      })
    }

    console.log("days", days);
  }





  return (
    <div style={{ marginBottom: "400px" }} className="main">
      <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="plot-add-button-blk">
            <a className="schedule-button nav-link collapsed" style={{ cursor: "pointer", border: "none" }} data-bs-toggle="modal" data-bs-target="#exampleModal1" data-bs-whatever="@mdo" onClick={() => {

            }}>पुढील वेळापत्रक </a>
          </div>
        </div>

        {schedule.length === 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className='text-center'>
              <img src={not_found} alt="" style={{ width: "30%" }} />
            </div>
          </div>
        )}


        <section className="section dashboard" id="schedule">

          {schedule.map((i, index) => {
            return (
              <div className="col-lg-12 col-md-12 col-sm-12" key={index} style={{ cursor: "pointer" }} onClick={() => Schedule_Details(i)}>
                <div className="card" id="farming-tipg-blk">
                  <div className="card-header">{i.SCHEDULE_DAY} Days Work</div>
                  <div className="card-body">
                    <h5 className="card-title1">{i.TITLE}</h5>
                    {parse(i.DESCRIPTION)}
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                    <div className="read-btn" style={{ fontSize: "14px", float: "right", padding: "5px 16px" }}> अधिक माहिती  </div>
                  </div>
                </div>
              </div>
            )
          })}

        </section>

      </main>




      <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "forestgreen" }}>
              <h1 className="modal-title fs-5" id="exampleModalLabel" style={{ color: "white", textAlign: "center", width: "100%" }}>वेळापत्रक निवडा</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              //  onClick={()=>handle()}
              ></button>
            </div>
            <div className="modal-body">
              <ul style={{ listStyleType: "none", padding: "10px" }} >
                {schedule.map((i, index) => {
                  return (
                    <div onClick={() => handlemsg(i)} style={{ cursor: "pointer" }}>
                      <li key={index} style={{ padding: "10px", color: "black", borderColor: "grey", borderBottom: "solid", borderBottomWidth: "1px" }}><a onClick={() => handlemsg(i)} style={{ background: "none" }}>{i.SCHEDULE_DAY} Day's work</a>


                        {i.IS_APPLIED === "Yes" ? (<i class="fa-sharp fa-solid fa-check" style={{
                          fonSize: "21px",
                          color: "green", float: "right"
                        }}></i>) :
                          (
                            <i class="bi bi-x"
                              style={{
                                fonSize: "21px",
                                color: "red", float: "right",
                                fontSize: "21px",
                                position: "relative",
                                left: "3px"
                              }}
                            ></i>
                          )
                        }
                      </li>
                    </div>
                  )
                })}



                {/* <li key={index} style={{ padding: "10px", color: "black", borderColor: "grey", borderBottom: "solid", borderBottomWidth: "1px" }} onClick={()=>handlemsg()}   ><span>{i.SCHEDULE_DAY}</span> <i class="fa-sharp fa-solid fa-check" style={{
                  fonSize: "21px",
                  color: "green", float: "right"
                }}></i> </li>
                <li style={{ padding: "10px", color: "black", borderColor: "grey", borderBottom: "solid", borderBottomWidth: "1px" }} onClick={()=>handlemsg()} >{i.SCHEDULE_DAY}<i class="fa-sharp fa-solid fa-check" style={{
                  fonSize: "21px",
                  color: "green", float: "right"
                }}></i></li>
                <li style={{ padding: "10px", color: "black", borderColor: "grey", borderBottom: "solid", borderBottomWidth: "1px" }}  onClick={()=>handlemsg()} >{i.SCHEDULE_DAY}<i class="fa-sharp fa-solid fa-check" style={{
                  fonSize: "21px",
                  color: "green", float: "right"
                }}></i></li> */}
              </ul>
            </div>

          </div>
        </div>
      </div >





    </div >
  )
}

export default Schedule