import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../useContext/AuthContext'
import product from '../images/produc1.jpg'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import not_found from '../images/not-found.png'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'


const Crops = () => {
  const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
  const [cropList, setCropList] = useState([])

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    animateScroll.scrollToTop({
        duration:0
    })
},[location.pathname])

  const common = useSelector(state => state.commonReducer)


  const productDetails = (details) => {
    navigate('/crop_list_details', { state: details })
  }
  useEffect(() => {
    axios.post(`${Base_Url}/Get_Data`,
      {
        "START": "1",
        "END": "10000",
        "WORD": "",
        "GET_DATA": "ALL_Crop_List",
        "ID1": "",
        "ID2": "",
        "ID3": "",
        "STATUS": "",
        "START_DATE": "",
        "END_DATE": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": `${common.language_id}`
      }
    ).then((res) => {
      console.log(res.data);
      setCropList(res.data.DATA)
    })
  }, [])

  useEffect(() => {
    setLeftArrow(false)
    setColorChange(false)
    setnewDemo(t("पिके"))
  }, [])


  return (
    <div className="main">
      <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
        <section className="section dashboard" id="question-answer">
          <div className="row" style={{marginTop:"15px"}}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              {cropList.length === 0 &&
                <div className='text-center'>
                  <img src={not_found} className="img-fluid" alt="" />
                </div>}
              {cropList.map((i) => {
                return (
                  <div className="card info-card sales-card">
                    <div className="card-body" >
                      <div className="row" style={{ cursor: "pointer" }} onClick={() => productDetails(i)}>
                        <div className="col-lg-3 col-md-3 col-sm-3" style={{ marginBottom: "30px" }}>
                          <div className="question-answer-list-prod-img">
                            <img src={i.CROP_IMAGE} className="img-fluid" width='50%' alt="" />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                          <div className="question-answer-list-outer-blk" style={{ display: "flex" }}>
                            <div>
                              <h5><strong>{i.CROP_NAME}</strong></h5>
                            </div>
                          </div>
                          <p style={{marginTop:"0"}} className='question'><strong>{parse(i.CROP_DESCRIPTION.slice(0, 200))}{i.CROP_DESCRIPTION >= 200 ? "" : "..."}</strong></p>
                          <i class="bi bi-arrow-right-circle-fill" style={{ float: "right", fontSize: "20px", color: "#ff5200" }}></i>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
        </section>
      </main>
    </div >
  )
}

export default Crops