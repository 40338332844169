import axios from 'axios'
import i18next from 'i18next'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'

const usarid = localStorage.getItem("usarid")

const Add_Question = () => {
    const navigate = useNavigate()
    const{sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [question, setQuestion] = useState("")
    const [image, setImage] = useState()
    const [question1, setQuestion1] = useState(false)
    const [image1, setImage1] = useState(false)
    const [loading, setLoading] = useState(false)

    const common = useSelector(state => state.commonReducer)

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const submit = () => {
        if(!question){
            return setQuestion1(true)
        }else if(!image){
           return setImage1(true)
        }else{
            return setLoading(true), 
            axios.post(`${Base_Url}/FarmerQuestion/1`,
        {
            "NEWQUETION_ID": "",
            "NEWQUETION": `${question}`,
            "DESCRIPTION": "test",
            "IMAGE_URL":`${image.slice(23,10000000000000000000000)}`,
            "USER_ID": `${common.usarid}`,
            "PLOT_ID": `${common.PLOT_ID}`,
            "TASK": "ADD",
            "EXTRA1": "",
            "EXTRA2": "",
            "EXTRA3": "",
            "LANG_ID": `${common.language_id}`
          }
        ).then((res) =>{
            console.log(res.data)
            toast.success(t("तुमचा प्रश्न यशस्वीरीत्या सबमिट झाला आहे तुम्हाला २४ तासामध्ये उत्तर मिळेल."))
            setLoading(false)
            navigate('/question_answer')
            console.log("image",image)
        }).catch((ERR) => {
            console.log(ERR)
        })

        }

   
    }

    
    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("प्रश्न विचारा"))
    },[])
  
    const imageUpload = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        setImage(base64)
    }

    const convertBase64 = (file) => {
        return new Promise ((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result)
            };

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const Styles = {
        sidebar1:{
            marginLeft:"300px"
        },
        sidebar2:{
            marginLeft:""
        },
        usarname3: {
            color: "red"
        },
        usarname: {
            display: "none"
        },
    }

    const { t } = useTranslation(); 
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])


    return (
        <div class="main">
            <main id="main"  className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section class="section dashboard" id="Dashboard-form">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card info-card sales-card">
                                <div class="card-body" >
                                    <div class="row">
                                        <div class="row">
                                            {/* <div class="col-md-4 col-sm-4 col-lg-4 ">
                                                <div class="form-group">
                                                    <label htmlFor="inputNanme4" class="form-label">श्रेणी</label>
                                                    <select class="form-select minimal" name="" id="">
                                                        <option>श्रेणी</option>
                                                        <option></option>
                                                        <option></option>
                                                    </select>
                                                </div>
                                            </div> */}

                                            <div class="col-md-6 col-sm-6 col-lg-6 ">
                                                <div class="form-group">
                                                    <label htmlFor="inputNanme4" class="form-label">{t('प्रश्न विचारा')} </label>
                                                    <textarea type="text" class="form-control d-lg-block " id="inputNanme4" placeholder={`${t('प्रश्न')}`} value={question} wrap='hard' style={{resize:"none", height:"53px"}} onChange={(e) => setQuestion(e.target.value)} required></textarea>
                                                    {/* <textarea type="text" wrap='' class="form-control d-lg-none d-sm-block" id="inputNanme4" placeholder="प्रश्न"   value={question} onChange={(e) => setQuestion(e.target.value)}></textarea> */}
                                                </div>
                                                <div style={question1 ? Styles.usarname3 : Styles.usarname} >{t('कृपया तुमचा प्रश्न प्रविष्ट करा!')}</div>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-lg-6">
                                                <div class="form-group">
                                                    <label htmlFor="inputNanme4" class="form-label" >{t('फोटो')}  </label>
                                                    <input type="file" class="form-control " onChange={(e) => imageUpload(e)} required/>
                                                </div>
                                                <div style={image1 ? Styles.usarname3 : Styles.usarname} >{t('कृपया तुमचा फोटो निवडा!')}</div>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                                <button disabled={loading} onClick={() => submit()} class="btn btn-success text-center">
                                                    {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "40px" }}>
                                                        <RotatingLines
                                                            strokeColor="green"
                                                            strokeWidth="4"
                                                            animationDuration="0.75"
                                                            width="21"
                                                            visible={true}
                                                        /></span>}
                                                    {t('प्रस्तुत करणे')}</button>
                                                {/* <!-- <button class="btn btn-success text-center" type="submit">Submit </button> --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </div>
    )
}

export default Add_Question