import axios from 'axios'
import i18next from 'i18next'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import not_found from '../images/not-found.png'
import { animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'


const Product_Enquiry_List = () => {
    const { sidebar, setSidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [product_enquiry_list, setProduct_enquiry_list] = useState([])

    const common = useSelector(state => state.commonReducer)

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        axios.post(`${Base_Url}/ProductEnquiry`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`,
                "USER_ID": `${common.usarid}`
            }
        ).then((res) => {
            console.log("res", res.data);
            setProduct_enquiry_list(res.data.DATA);
        })
    }, [])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("उत्पादन चौकशी"))
    }, [])



    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])



    
    return (
        <div className='main'>
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <div className="row" id="product-blk" style={{marginTop:"15px"}}>
                    {product_enquiry_list.length === 0 && (
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='text-center'>
                                <img src={not_found} alt="" style={{ width: "30%" }} />
                            </div>
                        </div>
                    )}
                    {product_enquiry_list.map((i) => {
                        return (
                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ cursor: "pointer" }}>
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md-12">

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6'>
                                                        <p><strong> {t('चौकशी नं.')} </strong>: {i.PE_ID}</p>
                                                    </div>
                                                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6 '>
                                                        <p style={{ float: "right" }}><strong>{t('तारीख')} : </strong>{i.REG_DATE}</p>
                                                    </div>
                                                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6 '>
                                                        <p>{t('श्रेणी')} : {i.PCAT_NAME}<br />
                                                            {t('उत्पादन')} : <span style={{ color: "green" }}>{i.PRODUCT_NAME}</span><br />
                                                            {t('टिप्पणी')} : <span style={{ fontWeight: "600" }}>{i.REMARK}</span><br />
                                                            {t('ऐड्मिन टिप्पणी')} : {i.ADMIN_REMARK}</p>

                                                    </div>
                                                    <div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6 '>
                                                        <p style={{ float: "right", color: "#ff7600" }}><strong>{i.STATUS}</strong></p>
                                                    </div>
                                                    {/* <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 '>
                                                    <p>टिप्पणी : good</p>
                                                </div>
                                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 '>
                                                    <p>ऐड्मिन टिप्पणी : </p>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </main>
        </div>
    )
}

export default Product_Enquiry_List