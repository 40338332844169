import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Base_Url } from "../Base_Url/Base_Url";
import logo from '../images/logo.png'
import not_found from '../images/not-found.png'

const SimpleSlider = ({ settings }) => {
    const [videoList, setVideoList] = useState([])

    const common = useSelector((state) => state.commonReducer)

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "10000",
                "WORD": "",
                "GET_DATA": "Video_List",
                "ID1": "",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log("res", res.data);
            setVideoList(res.data.DATA);

        })
    }, [videoList])


    const navigate = useNavigate()




    return (
        <Slider {...settings}>
            {videoList.map((i) => {
                return (
                    <div>
                        <div className="card card-slide" style={{ cursor: "pointer", width:"270px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "8px" }}>
                                {/* <img src={i.PRODUCT_IMAGE === null ? logo : i.PRODUCT_IMAGE} className="img-fluid" alt="" style={{ borderRadius: "7px", width: "100px", height: "150px" }} /> */}
                                <a href={`https://www.youtube.com/watch?v=${i.Video_URL}`} target="_blank" style={{ marginTop: "-19px" }}>
                                    <i class="bi bi-play-circle position"></i>
                                    <img src={i.VIDEO_IMG === "" ? logo : i.VIDEO_IMG} className="img-fluid" style={{ borderRadius: "7px", width: "100%", height: "180px" }} alt="..." />
                                </a>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <h6 style={{fontSize:"15px", fontWeight:"600"}}>{i.Title.slice(0, 10)}</h6>
                                </div>
                                <div className="col-7">
                                    <h6 style={{fontSize:"13px"}}>{i.REG_DATE}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Slider>
    );
}


export default SimpleSlider
