import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Footer from '../component/Footer'
import Language from '../component/Language'
import Login from '../component/Login'
import Navbar from '../component/Navbar'
import LoginOtp from '../component/LoginOtp'
import Address from '../component/Address'
import { AuthContext } from '../useContext/AuthContext'
import Dashboard from '../component/Dashboard.jsx'
import Select_Plot from '../component/Select_Plot'
import Mobile_Navbar from '../component/Mobile_Navbar'
import Schedule from '../component/Schedule'
import Plot_Info from '../component/Plot_Info'
import Quaestion_Answer from '../component/Quaestion_Answer'
import Add_Question from '../component/Add_Question'
import Faq from '../component/Faq'
import Desease_control from '../component/Desease_control'
import Add_New_Plot from '../component/Add_New_Plot'
import Product_Gallery from '../component/Product_Gallery'
import Product_Details from '../component/Product_Details'
import Register from '../component/Register'
import Farmer_Scouting_Report from '../component/Farmer_Scouting_Report'
import Lab_Inquery from '../component/Lab_Inquery'
import Lab from '../component/Lab'
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'
import Schedule_Details from '../component/ScheduleDetails'
import Desease_control_list from '../component/Desease_control_list'
import FaqDetails from '../component/FaqDetails'
import Profile from '../component/Profile'
import { useSelector } from 'react-redux'
import About_Us from '../component/About_Us'
import Lab_Enquiry_List from '../component/Lab_Enquiry_List'
import Product_Enquiry_List from '../component/Product_Enquiry_List'
import Privacy_Policy from '../component/Privacy_Policy'
import Contact_Us from '../component/Contact_Us'
import Weather from '../component/Weather'
import i18next from 'i18next'
import Crops from '../component/Crops'
import Crop_List_Details from '../component/Crop_List_Details'
import Video_List from '../component/Video_List'
import Notifications from '../component/Notifications'
import Notifications_Details from '../component/Notifications_Details'
import Plot_Info_Update from '../component/Plot_Info_Update'
import DeseasControl from '../component/DeseasControl'
import Question_And_Answer_Details from '../component/Question_And_Answer_Details'
import Social_Media from '../component/Social_Media'
import myLogo from '../images/logo.jpeg'


const App = () => {
    const { setSidebar, anchorRef } = useContext(AuthContext)

    const data = localStorage.getItem("usarname")

    const common = useSelector(state => state.commonReducer)

   


    const handleClose = (e) => {
        if (window.innerWidth < 768) {
            if (anchorRef.current && anchorRef.current.contains(e.target)) {
                return;
            }

            setSidebar(false)
        }

    }


    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])





    const { setMainNav, setNav, setLeftArrow, setColorChange } = useContext(AuthContext)

    useEffect(() => {
        if (common.usarname) {
            <Navigate to='/featured_dashboard' />
            setNav(true)
        }


    }, [])

    useEffect(() => {
        if(window.location.origin + "/" == window.location.href){
          return setColorChange(true)
        }
      },[])

    console.log("href",window.location.href);
    console.log("href",window.location.origin);

    


    return (
        <div onClick={handleClose}>
            <BrowserRouter>
                <Navbar />
                <div>                    
                    {common.usarid ? (<Routes>
                        {/* <Route path='*' element={<Navigate to='/' />} /> */}
                        <Route path='/address' element={<Address />} />
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/select_plot' element={<Select_Plot />} />
                        <Route path='/plot_info' element={<Plot_Info />} />
                        <Route path='/schedule' element={<Schedule />} />
                        <Route path='/question_answer' element={<Quaestion_Answer />} />
                        <Route path='/add_question' element={<Add_Question />} />
                        <Route path='/faq' element={<Faq />} />
                        <Route path='/disease_control_list' element={<Desease_control_list />} />
                        <Route path='/new_plot' element={<Add_New_Plot />} />
                        <Route path='/product_gallery' element={<Product_Gallery />} />
                        <Route path='/product_details' element={<Product_Details />} />
                        <Route path='/farmer_scouting_report' element={<Farmer_Scouting_Report />} />
                        <Route path='/laboratory' element={<Lab_Inquery />} />
                        <Route path='/lab' element={<Lab title="Lab"/>} />
                        <Route path='/Schedule_Details' element={<Schedule_Details />} />
                        <Route path='/disease_control' element={<Desease_control />} />
                        <Route path='/faq_details' element={<FaqDetails />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/aboutus' element={<About_Us />} />
                        <Route path='/lab_enquiry_list' element={<Lab_Enquiry_List />} />
                        <Route path='/product_enquiry_list' element={<Product_Enquiry_List />} />
                        <Route path='/privacy_policy' element={<Privacy_Policy />} />
                        <Route path='/contact_us' element={<Contact_Us />} />
                        <Route path='/weather' element={<Weather />} />
                        <Route path='/crop' element={<Crops />} />
                        <Route path='/crop_list_details' element={<Crop_List_Details />} />
                        <Route path='/video_list' element={<Video_List />} />
                        <Route path='/notification' element={<Notifications />} />
                        <Route path='/notifications_detail' element={<Notifications_Details />} />
                        <Route path='/plot_info_update' element={<Plot_Info_Update />} />
                        <Route path='/deseascontrol' element={<DeseasControl />} />
                        <Route path='/question_and_answer_details' element={<Question_And_Answer_Details />} />
                        <Route path='/social_media' element={<Social_Media />} />
                    </Routes>
                    ) : (
                        <Routes>
                            <Route path='*' element={<Navigate to='/language' />} />
                            <Route path='/language' element={<Language />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='/register' element={<Register />} />
                            <Route path='/loginotp' element={<LoginOtp />} />
                        </Routes>
                    )}

                    <Mobile_Navbar />
                    {/* <Footer /> */}
                </div>
            </BrowserRouter>
        </div>
    )
}

export default App