import SimpleSlider from './SimpleSlider'
import React from 'react';

class Parent extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        selectedFooter: 0
      };
  
      this.settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
          {
            breakpoint: 320,
            settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          },
          {
            breakpoint: 768,
            settings: { infinite: false }
          }        
        ]
      };
    } 
  
    render() {
      return (
        <div className="container">
          <SimpleSlider settings={this.settings} />
        </div>
      );
    }
  }
  

  export default Parent