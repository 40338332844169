import { configureStore } from "@reduxjs/toolkit";
import bannerSliceReducer from './Banner'
import cropSliceReducer from './Crop'
import plotListSliceReducer from './PlotList'
import questionAnswerSliceReducer from './QuestionAnswer'
import scheduleSliceReducer from './Schedule'
import desesaseSliceReducer from './Desease'
import labSliceReducer from './Lab'
import productSliceReducer from './ProductGallery'
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import commonSlice from './Persist'

const persistConfig = {key: "krishivikas", version:1, storage}

const persistReducerBlock = persistReducer(persistConfig, commonSlice)


export const store = configureStore({
    reducer:{
        commonReducer:persistReducerBlock,
        banner:bannerSliceReducer,
        crop:cropSliceReducer,
        plotlist:plotListSliceReducer,
        questionAnswer : questionAnswerSliceReducer,
        schedule:scheduleSliceReducer,
        Desease:desesaseSliceReducer,
        Lab:labSliceReducer,
        productGallery:productSliceReducer
     }
})