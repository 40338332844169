import React, { useContext, useRef } from 'react'
import { useState } from 'react'
import { AuthContext } from '../useContext/AuthContext'
import { RotatingLines } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { Base_Url } from '../Base_Url/Base_Url'
import axios from 'axios'
import { useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { updateState } from '../redux/Persist'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import profile from '../images/profile.png'
import { animateScroll } from 'react-scroll'

const usarname = localStorage.getItem("usarname")
const usarid = localStorage.getItem("usarid")
const mobilelocal = localStorage.getItem("mobile")
const emaillocal = localStorage.getItem("email")

const villagelocal = localStorage.getItem("village")

const Profile = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [fname, setFname] = useState("")
    const [email, setEmail] = useState("")
    const [email1, setEmail1] = useState("")
    const [mobile, setMobile] = useState("")
    const [address, setAddress] = useState("")
    const [loading, setLoading] = useState(false)
    const [fullname, setFullname] = useState(false)
    const [email2, setEmail2] = useState(false)
    const [mobile2, setMobile2] = useState(false)
    const [address1, setAddress1] = useState(false)
    const [stateinput, setStateInput] = useState("")
    const [cityinput, setCityInput] = useState("")
    const [talukainput, setTalukaInput] = useState("")
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [taluka, setTaluka] = useState([])
    const inputFile = useRef(null)
    const [imageUpload1, setImageupload] = useState("")
    const [profilePhoto, setProfilePhoto] = useState("")


    const [village, setVillage] = useState("")
    const [village1, setVillage1] = useState(false)
    const [image, setImage] = useState()

    const common = useSelector(state => state.commonReducer)

    const dispatch = useDispatch()

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("माझे प्रोफाइल"))
    }, [])


    const { handleSubmit } = useForm()

    const getState = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "State_List",
                "ID1": "",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            },

        ).then((res) => {
            console.log(res.data.DATA);
            setState(res.data.DATA)
        })
    }

    const navigate = useNavigate()

    const getCity = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "City_List",
                "ID1": "1",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA)
            setCity(res.data.DATA)
        })
    }

    const getTaluka = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "Taluka_List",
                "ID1": "1",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA)
            setTaluka(res.data.DATA)
        })
    }

    useLayoutEffect(() => {
        setFname(common.usarname)
        setMobile(common.mobile)
        setEmail(common.email)
        setImage(common.Profile)

        setVillage(common.village)
    }
        , [])


    const submit = () => {
        console.log("profilePhoto", image);
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (!fname) {
            return setFullname(true)
        }
        // else if (!email) {
        //     return setEmail1(true)
        // } 
        // else if (!address) {
        //     return setAddress1(true)
        // }
        //  else if (!pattern.test(email)) {
        //     return setEmail2(true)
        // }
        else if (!village) {
            return setVillage1(true)
        }
        else {
            setLoading(true)
            axios.post(`${Base_Url}/UserRegistration`,
                {
                    "USER_ID": `${common.usarid}`,
                    "FULL_NAME": `${fname}`,
                    "MOBILE_NO": `${mobile}`,
                    "PINCODE": "",
                    "EMAIL": `${email}`,
                    "DATE_OF_BIRTH": "",
                    "ADHARCARD_NUMBER": "",
                    "ADDRESS": `${address}`,
                    "TOKEN": "",
                    "STATE_ID": `${stateinput}`,
                    "CITY_ID": `${cityinput}`,
                    "TALUKA_ID": `${talukainput}`,
                    "VILLAGE_NAME": `${village}`,
                    "PROFILE_PHOTO": `${image.slice(22, 1000000000000000000000000000000000)}`,
                    "TASK": "UPDATE",
                    "EXTRA1": "",
                    "EXTRA2": "",
                    "EXTRA3": "",
                    "LANG_ID": `${common.language_id}`
                }
            ).then((res) => {
                console.log("res", res.data);
                const data = res.data.DATA1[0].PROFILE_PHOTO
                setLoading(false)
                dispatch(updateState({
                    usarname: `${fname}`,
                    email: `${email}`,
                    mobile: `${mobile}`,
                    Profile: `${data}`

                }))
                // localStorage.setItem("usarname", fname)
                // localStorage.setItem("email", email)
                // localStorage.setItem("mobile", mobile)
                toast.success(t("प्रोफाइल यशस्वीरित्या अपडेट केली."))
                navigate('/')

            })
        }
    }


    useEffect(() => {
        getState()
        getCity()
        getTaluka()
    }, [])

    const Style = {
        usarname: {
            display: "none"
        },
        usarname2: {
            marginLeft: "5px",
            marginTop: "5px",
            color: "red"
        },
        usarname3: {
            color: "red"
        }
    }



    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])


    const fileOpen = () => {
        inputFile.current.click()
        setImageupload(image)
    }


    const imageUpload = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        setImage(base64)
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result)
            };

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }





    return (
        <div>
            <div className="main">
                <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>

                    <section className="section dashboard" id="Dashboard-form">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="row">
                                            
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div onClick={(e) => fileOpen()} className="form-group" style={{cursor:"pointer", width: "100%", textAlign: "center" }}>
                                                        <img style={{ height: "120px", borderRadius:"50%", background:"transparent",width:"120px", objectFit:"cover" }} src={image === "" ? profile : image} alt="" />
                                                        <i  style={{ position: "relative", top: "41px", left: "-21px", fontSize: "24px", color: "#e93d3d" }} class="bi bi-plus-circle-fill" />
                                                    </div>
                                                    <input type="file" ref={inputFile} onChange={(e) => imageUpload(e)} style={{ display: "none" }} />
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('पूर्ण नाव')}  </label>
                                                        <input type="text" required className="form-control" value={fname} onChange={(e) => setFname(e.target.value)} id="inputNanme4" placeholder={`${t('पूर्ण नाव')}`} />
                                                        <div style={fullname ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचे पूर्ण नाव टाका')}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('मोबाईल नंबर')}   </label>
                                                        <input type="text" required className="form-control" disabled style={{ cursor: "not-allowed" }} value={mobile} id="inputNanme4" placeholder={`${t('मोबाईल नंबर')}`} />
                                                        <div style={mobile2 ? Style.usarname2 : Style.usarname}>{t('कृपया वैध मोबाइल नंबर टाका')}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('ई मेल')}  </label>
                                                        <input type="text" required className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="inputNanme4" placeholder={`${t('ई मेल')}`} />
                                                        <div style={email1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा ई मेल आइडी टाका')} </div>
                                                        <div style={email2 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा वैध ई मेल आइडी टाका')}</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('राज्य')}</label>
                                                        <select className="form-select minimal" name="" id="" style={{ cursor: "pointer" }} value={stateinput} onChange={(e) => setStateInput(e.target.value)}>
                                                            {state.map((i) => {
                                                                return (
                                                                    <option value={i.STATE_ID}>{i.STATE_NAME}</option>
                                                                )
                                                            })}
                                                            {/* <option value="">राज्य</option> */}
                                                        </select>
                                                        {/* <input type="text" value={mobile} onChange={(e) => mobileNumber(e)} id="inputNanme4" placeholder="मोबाईल नंबर  " /> */}
                                                        {/* <div style={state1 ? Style.usarname2 : Style.usarname}>कृपया तुमचे राज्य निवडा</div> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('जिल्हा')}</label>
                                                        <select className="form-select minimal" name="" id="" style={{ cursor: "pointer" }} value={cityinput} onChange={(e) => setCityInput(e.target.value)}>
                                                            {city.map((i) => {
                                                                return (
                                                                    <option value={i.CITY_ID}>{i.CITY_NAME}</option>
                                                                )
                                                            })}
                                                        </select>

                                                        {/* <div style={address1 ? Style.usarname2 : Style.usarname}>कृपया तुमचा जिल्हा निवडा </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('तालुका')}</label>
                                                        <select className="form-select minimal" name="" id="" style={{ cursor: "pointer" }} value={talukainput} onChange={(e) => setTalukaInput(e.target.value)}>
                                                            {taluka.map((i) => {
                                                                return (
                                                                    <option value="">{i.TALUKA_NAME}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {/* <input type="text" className="form-control" id="inputNanme4" placeholder="पिन कोड " /> */}
                                                        {/* <div style={taluka1 ? Style.usarname2 : Style.usarname}>कृपया तुमचा तालुका निवडा</div> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('गाव')}  </label>
                                                        <input type="text" required className="form-control" value={village} onChange={(e) => setVillage(e.target.value)} id="inputNanme4" placeholder={`${t('गाव')}`} />
                                                        <div style={village1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचे गाव टाका')}</div>
                                                    </div>
                                                </div>



                                                {/* <div className="col-md-12 col-sm-12 col-lg-12 ">
                                                    <div className="form-group">
                                                        <label for="inputNanme4" className="form-label">{t('पत्ता')}</label>
                                                        
                                                        <textarea type="text" className="form-control  d-sm-block" id="inputNanme4" placeholder="पत्ता " value={address} onChange={(e) => setAddress(e.target.value)} cols={4} rows={5} style={{ resize: "none" }}></textarea>
                                                        
                                                        
                                                         <div style={address1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा पत्ता टाका')}</div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12 col-sm-12 col-lg-12 mr-t2" style={{marginBottom:"2rem"}}>
                                                    <button disabled={loading} className="btn btn-success text-center" onClick={handleSubmit(submit)}>
                                                        {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "42px" }}>
                                                            <RotatingLines
                                                                strokeColor="green"
                                                                strokeWidth="4"
                                                                animationDuration="0.75"
                                                                width="21"
                                                                visible={true}
                                                            /></span>}
                                                        {t('प्रस्तुत करा')}</button>
                                                    {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                                                </div>
                                            



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </main>
            </div>
        </div>
    )
}

export default Profile