import React, { useContext, useState } from 'react'
import p_vector from '../images/p_vector.jpg'
import produc_gallery1 from '../images/produc-gallery1.jpg'
import produc_gallery2 from '../images/produc-gallery2.jpg'
import produc_gallery3 from '../images/produc-gallery3.jpg'
import produc_gallery4 from '../images/produc-gallery4.jpg'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../images/logo.png'
import parse from 'html-react-parser'
import { useEffect } from 'react'
import { fetchProduct } from '../redux/ProductGallery'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { width } from '@mui/system'
import { RotatingLines } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import not_found from '../images/not-found.png'
import i18next from 'i18next'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { animateScroll } from 'react-scroll'
import CardSlider from './CardSlider';

const Product_Gallery = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const common = useSelector(state => state.commonReducer)
    const { sidebar, setLeftArrow, setColorChange, setnewDemo, product, setProduct } = useContext(AuthContext)
    const [productGallery, setProductGallery] = useState([])
    const [search, setSearch] = useState("")
    const [responsive, setResponsive] = useState(false)

    console.log("productGallery", productGallery);
    const dispatch = useDispatch()
    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }


    useEffect(() => {
        if (window.innerWidth > 768) {
            return setResponsive(true)
        }
    })
    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 0
        })
    }, [location.pathname])

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "ProductCategory_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data);
            setProductGallery(res.data.DATA)
        })
    }, [])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("उत्पादन गॅलरी"))
    }, [])




    const productDetails = (i) => {
        console.log(i)
        navigate('/product_details', { state: i })
    }

    useEffect(() => {
        setLoading(true)
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log("res", res.data.DATA);
            setProduct(res.data.DATA)
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        dispatch(fetchProduct())
    }, [])



    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])





    const subProduct = (product) => {
        console.log("product", product);
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": `${product.PCAT_ID}`, "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log("res", res.data.DATA);
            setProduct(res.data.DATA)
        })
    }



    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="xrow">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {productGallery.length === 0 &&
                                <div className='text-center'>
                                    <img src={not_found} className="img-fluid" alt="" />
                                </div>}
                            {responsive ? (
                                <>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card info-card sales-card">
                                            {productGallery.length > 0 && <div className="card-body">

                                                <div className="row" >
                                                    <div className="col-lg-12 col-md-12 co-sm-12 col-12"    >

                                                        <div className='categoryname' style={{ display: "flex", gap: "15px", overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap" }} >
                                                            <ul>


                                                                {
                                                                    productGallery.map((i, index) => {
                                                                        return (
                                                                            <li style={{ display: "inline-block", padding: "0px 15px" }}>

                                                                                <div className="card" style={{ height: "151px" }}>
                                                                                    <div style={{ height: "104px" }}>
                                                                                        <a onClick={() => subProduct(i)} key={index} style={{cursor:"pointer"}}>
                                                                                            <div className="farm-prodct-icon-circle" style={{position:"relative", top:"-19px"}}>
                                                                                                <img src={i.PCAT_IMAGE} style={{ borderRadius: "50%", width: "97px", height: "80px", position: "relative", top: "17px" }} />
                                                                                            </div>
                                                                                            <div className='plot-add-button-blk' style={{marginTop:"18px"}}>
                                                                                                <a> {i.PCAT_NAME.slice(0, 20)}</a>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                        )
                                                                    })
                                                                }


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {productGallery.length > 0 && <div className="card info-card sales-card">
                                        <div className="card-body">
                                            <CardSlider cards={productGallery} />

                                        </div>
                                    </div>}
                                </>
                            )}

                        </div>
                        {productGallery.length > 0 && <div className="row">
                            <div className="col-lg-12" style={{ marginBottom: "20px" }}>
                                <TextField style={{ width: "100%", borderRadius: "20px" }}
                                    onChange={(e) => setSearch(e.target.value)}
                                    id="outlined-basic" label="Search for Products.." variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon sx={{ color: "#e93d3d" }} />
                                            </InputAdornment>
                                        )
                                    }}
                                />

                            </div>
                        </div>}
                        {product.length > 0 && <div className="row" id="product-gallery">
                            {product.filter((value) => {
                                if (search === "") {
                                    return value
                                } else if (value.PRODUCT_NAME.toLowerCase().includes(search.toLowerCase())) {
                                    return value
                                }
                            }).map((i) => {
                                return (
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }} onClick={() => productDetails(i)}>
                                        <div className="card info-card sales-card text-center">
                                            <div className="card-body">
                                                <a >
                                                    <img src={i.PRODUCT_IMAGE === null ? logo : i.PRODUCT_IMAGE} className="img-fluid" alt="" />
                                                    <div className="product-name-blk productname">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="product-title text-center w-100">
                                                                <h5 style={{ fontSize: "1rem" }}>{i.PRODUCT_NAME.slice(0, 10)}..</h5>
                                                                <p style={{ marginTop: "0", fontWeight: "normal" }}>{`${parse(i.PRODUCT_DESCRIPTION.slice(3, 20))}...`}</p>
                                                            </div>

                                                        </div>
                                                        {/* <div className="btn read-more ">
                                                        <i class="bi bi-arrow-right-circle-fill"></i>
                                                        </div> */}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Product_Gallery