import axios from 'axios'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RotatingLines } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import close from '../images/close.png'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { resetState, updateState } from '../redux/Persist'
import { animateScroll } from 'react-scroll'
import Checkbox from '@mui/material/Checkbox';



const usarid = localStorage.getItem("usarid")
const language = localStorage.getItem("language")
const Add_New_Plot = () => {
    const [cropVerietyList, setCropVerietyList] = useState([])
    const [soil, setSoil] = useState([])
    const [type, setType] = useState('text')
    const [variety, setVariety] = useState("")
    const [zameen, setZameen] = useState("")
    const [sinchan, setSinchan] = useState(["सिंचन स्रोत"])
    const [variety1, setVariety1] = useState(false)
    const [lagvad, setLagvad] = useState(false)
    const [zameen1, setZameen1] = useState(false)
    const [sinchan1, setSinchan1] = useState(false)
    const [local, setLocal] = useState("Local")
    const [local1, setLocal1] = useState(false)
    const [export1, setExport] = useState("")
    const [bedana, setBedana] = useState("")
    const [localexport, setLocalExport] = useState("")
    const [plot, setPlot] = useState("")
    const [plot3, setPlot3] = useState("")
    const [antar, setAntar] = useState(false)

    const [plot2, setPlot2] = useState("")
    const [problem, setProblem] = useState("")
    const [plot1, setPlot1] = useState(false)
    const [problem1, setProblem1] = useState(false)
    const navigate = useNavigate()
    const { sidebar, setLeftArrow, setnewDemo, setColorChange } = useContext(AuthContext)
    const [date, setDate] = useState("")
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [sinchantype, setSinchanType] = useState(false)
    const [vihir, setVihir] = useState(false)
    const [borwel, setBorwel] = useState(false)
    const [kalva, setKalva] = useState(false)
    const [tale, setTale] = useState(false)
    const [sinchantype1, setSinchanType1] = useState(" ")
    const [vihir1, setVihir1] = useState(" ")
    const [borwel1, setBorwel1] = useState(" ")
    const [kalva1, setKalva1] = useState(" ")
    const [tale1, setTale1] = useState(" ")

    const common = useSelector(state => state.commonReducer)
    console.log("location.state", location.state);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        setnewDemo(t("नवीन प्लॉट जोडा"))
        setColorChange(false)
    },[])
    const cropVeriety = () => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "CropVariety_List",
                "ID1": `${location.state}`,
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA)
            setCropVerietyList(res.data.DATA)
        })
    }

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "SoilType_Lang", "ID1": `${common.usarid}`, "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log("SoilType_Lang", res.data.DATA);
            setSoil(res.data.DATA)
        })
    }, [])

    useEffect(() => {
        setLeftArrow(false)
       
    }, [])



    const { handleSubmit } = useForm()


    const plotNumber3 = (e) => {
        const value = e.target.value;
        if (!Number(value)) {
            return setPlot3("")
        } else if (value.length == 5) {
            return;
        }

        setPlot3(value)


    }

    const plotNumber = (e) => {
        const value = e.target.value;
        if (!Number(value)) {
            return setPlot("")
        } else if (value.length == 5) {
            return;
        }

        setPlot(value)
    }

    const plotNumber2 = (e) => {
        const value = e.target.value;
        if (!Number(value)) {
            return setPlot2("")
        } else if (value.length == 5) {
            return;
        }

        setPlot2(value)
    }


const dispatch = useDispatch()

    const submit = () => {
        console.log("plot2", plot2);
        console.log("plot3", plot3);
        if (!variety) {
            return setVariety1(true)
        } else if (!date) {
            return setLagvad(true)
        } else if (!zameen) {
            return setZameen1(true)
        } else if (sinchan[0] === 'सिंचन स्रोत') {
            return setSinchan1(true)
        } else if (location.state === "7") {
            if (!plot3 || !plot2) {
                return setAntar(true)
            }
        }
        else if (!plot) {
            return setPlot1(true)
        } else if (!problem) {
            return setProblem1(true)
        } else {
            setLoading(true)

            axios.post(`${Base_Url}/PlotMaster`,
                {
                    "USER_ID": `${common.usarid}`,
                    "PLOT_ID": "",
                    "CROP_ID": `${location.state}`,
                    "CROPVARIETY_ID": `${variety}`,
                    "CHATANI_ID": "1",
                    "ST_ID": `${zameen}`,
                    "WATER_SOURCE": `${sinchan}`,
                    "PLOT_SRUCTURE": "y",
                    "METHOD_OF_WATER": "Drip",
                    "CROP_DISTANCE": `${plot3 * plot2}`,
                    "EXPORT_LOCAL": `${local} ${export1} ${bedana} ${localexport}`,
                    "PLOT_AREA": `${plot}`,
                    "LATITUDE": "10.00",
                    "LONGITUDE": "10.00",
                    "PREVIOUS_YEAR_PROBLEM": `${problem}`,
                    "SOWING_DATE": `${date}`,
                    "TASK": "ADD",
                    "EXTRA1": "",
                    "EXTRA2": "",
                    "EXTRA3": "",
                    "LANG_ID": `${common.language_id}`
                }
            ).then((res) => {
                console.log(res.data)
                setLoading(false)
                toast.success(<h5>{t('यशस्वीरित्या जोडले गेले')}</h5>)
                dispatch(updateState({
                    newusar:""
                }))
                navigate('/')
            })
        }


    }


    const localInput = (e) => {
        setLocal(true)
        if (local) {
            return setLocal1('Local')
        }

        console.log("local", local)
        console.log("local1", local1)
    }



    const submit1 = () => {

        setSinchan("")
        setSinchan([`${sinchantype1} ${vihir1} ${borwel1} ${kalva1} ${tale1}`])


    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };




    useEffect(() => {
        if (sinchantype === true) {
            setSinchanType1(`${t('सिंचन प्रकार,')}`)
        }

        if (vihir === true) {
            setVihir1(`${t('विहीर,')}`)

        }

        if (borwel === true) {
            setBorwel1(`${t('बोरवेल,')}`)
        }

        if (kalva === true) {
            setKalva1(`${t('कालवा,')}`)
        }

        if (tale === true) {
            setTale1(`${t('तळे')}`)
        }
        if (sinchantype === false) {
            setSinchanType1("")
        }

        if (vihir === false) {
            setVihir1("")
        }

        if (borwel === false) {
            setBorwel1("")
        }

        if (kalva === false) {
            setKalva1("")
        }

        if (tale === false) {
            setTale1("")
        }


    }, [sinchantype, vihir, borwel, kalva, tale, sinchantype1, vihir1, borwel1, kalva1, tale1])

    console.log("sinchan", sinchan);

    useEffect(() => {
        cropVeriety()
    }, [borwel, sinchantype1, sinchantype])

    const Styles = {
        sidebar3: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        },
        sidebar1: {
            display: "none"
        },
        sidebar4: {
            color: "red",
            marginLeft: "5px"
        }
    }

    var today = new Date().toISOString().split('T')[0];


    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])




    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="Dashboard-form">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body" >
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                            <h5 className="card-title">{t('नवीन प्लॉट जोडा')}</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('व्हरायटी')} </label>
                                                    <select className="form-select minimal" name="" id="" style={{ cursor: "pointer" }} value={variety} onChange={(e) => setVariety(e.target.value)}>
                                                        <option style={{ color: "lightgray" }}>{t('व्हरायटी')}</option>
                                                        {cropVerietyList.map((item) => {
                                                            return (
                                                                <option value={item.V_ID} >{item.VL_NAME}</option>
                                                            )
                                                        })}
                                                        {/* <option></option>
                                                        <option></option> */}
                                                    </select>
                                                    <div style={variety1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया व्हरायटी निवडा')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('लागवडीची तारीख')} </label>
                                                    <input type={type} onFocus={() => setType('date')} onBlur={() => setType('date')} style={{height:"52px"}} max={today} className="form-control" id="inputNanme4" placeholder={`${t('लागवडीची तारीख')}`} value={date} onChange={(e) => setDate(e.target.value)} />
                                                    <div style={lagvad ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया लागवडीची तारीख निवडा')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('जमिनीचा प्रकार')} </label>
                                                    <select className="form-select minimal" name="" id="" style={{ cursor: "pointer" }} value={zameen} onChange={(e) => setZameen(e.target.value)}>
                                                        <option>{t('जमिनीचा प्रकार')}</option>
                                                        {soil.map((i) => {
                                                            return (
                                                                <option value={i.ST_ID}>{i.ST_NAME}</option>
                                                                // <option value='मुरमत'>{t('मुरमत')}</option>
                                                                // <option value='लाल'>{t('लाल')}</option>
                                                            )
                                                        })}

                                                        {/* <option value='वालुकामय चिकणमाती'>{t('वालुकामय चिकणमाती')}</option>
                                                        <option value='चिकणमाती'>{t('चिकणमाती')}</option> */}
                                                    </select>
                                                    <div style={zameen1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया जमिनीचा प्रकार निवडा')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('सिंचन प्रकार  निवडा ( एकाधिक निवड )')}</label>
                                                    <input type='text' className="form-select minimal check" style={{ cursor: "pointer" }} value={t(sinchan)} onChange={(e) => setSinchan(e.target.value)} data-bs-toggle="modal" data-bs-target="#exampleModal1" />                                                                                                       {/* <select className="form-select minimal" data-bs-toggle="modal" data-bs-target="#exampleModal" name="" id="" value={sinchan} onChange={(e) => setSinchan(e.target.value)}></select> */}
                                                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-sm modal-dialog-centered" style={{ width: "94%" }}>
                                                            <div class="modal-content" style={{ width: "80%", marginLeft: "37px" }}>
                                                                <div class="modal-header">
                                                                    <h1 class="modal-title fs-5" id="exampleModalLabel" style={{ color: "green" }}>{t('सिंचन स्रोत निवडा')}</h1>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                                        <label htmlFor='सिंचन प्रकार' style={{ width: "43%", fontSize: "1.1em", cursor: "pointer" }} value='सिंचन प्रकार' >{t('सिंचन प्रकार')}</label>
                                                                        <Checkbox {...label} value={sinchantype} onChange={(e) => setSinchanType(!sinchantype)} id='सिंचन प्रकार' color="success" style={{ cursor: "pointer", marginLeft: "30px" }}/>
                                                                        {/* <input type="checkbox" value={sinchantype} onChange={(e) => setSinchanType(!sinchantype)} id='सिंचन प्रकार' style={{ cursor: "pointer", marginLeft: "30px" }} /> */}
                                                                    </div>
                                                                    <hr />
                                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                                        <label htmlFor='विहीर' style={{ width: "43%", fontSize: "1.1rem", cursor: "pointer" }} value='सिंचन प्रकार'>{t('विहीर')}</label>
                                                                        <Checkbox {...label} value={vihir} onChange={(e) => setVihir(!vihir)} id='विहीर' color="success" style={{ cursor: "pointer", marginLeft: "30px" }}/>
                                                                        {/* <input type="checkbox"  id='विहीर' style={{ cursor: "pointer", marginLeft: "30px" }} /> */}
                                                                    </div>
                                                                    <hr />
                                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                                        <label htmlFor='बोरवेल' style={{ width: "43%", fontSize: "1.1rem", cursor: "pointer" }} value='सिंचन प्रकार'>{t('बोरवेल')}</label>
                                                                        <Checkbox {...label} value={borwel} onChange={(e) => setBorwel(!borwel)} id='बोरवेल' color="success" style={{ cursor: "pointer", marginLeft: "30px" }}/>
                                                                        {/* <input type="checkbox" value={borwel} onChange={(e) => setBorwel(!borwel)} id='बोरवेल myCheckbox' style={{ cursor: "pointer", marginLeft: "30px" }} /> */}
                                                                    </div>
                                                                    <hr />
                                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                                        <label htmlFor='कालवा' style={{ width: "43%", fontSize: "1.1rem", cursor: "pointer" }} value='सिंचन प्रकार'>{t('कालवा')}</label>
                                                                        <Checkbox {...label} value={kalva} onChange={(e) => setKalva(!kalva)} id='कालवा' color="success" style={{ cursor: "pointer", marginLeft: "30px" }}/>
                                                                        {/* <input type="checkbox" value={kalva} onChange={(e) => setKalva(!kalva)} id='कालवा myCheckbox' style={{ cursor: "pointer", marginLeft: "30px" }} /> */}
                                                                    </div>
                                                                    <hr />
                                                                    {/* <div className='d-flex' style={{ gap: "10px" }}>
                                                                        <label htmlFor='तळे' style={{ width: "43%", fontSize: "1.1rem", cursor: "pointer" }} value='सिंचन प्रकार'>{t('तळे')}</label><input type="checkbox" value={tale} onChange={(e) => setTale(!tale)} id='तळे' style={{ cursor: "pointer", marginLeft: "30px" }} />
                                                                    </div> */}
                                                                    {/* <option value='विहीर'>विहीर</option><input type="checkbox" />
                                                                    <option value='बोरवेल'>बोरवेल</option><input type="checkbox" />
                                                                    <option value='कालवा'>कालवा</option><input type="checkbox" />
                                                                    <option value='तळे'>तळे</option><input type="checkbox" /> */}
                                                                </div>
                                                                <div class=" text-center" style={{ marginBottom: "30px", marginTop: "10px" }}>
                                                                    <button style={{ backgroundColor: "#599759", border: "none" }} data-bs-dismiss="modal" type="button" class="btn btn-primary" onClick={() => submit1()}>{t('समाविष्ट करा')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={sinchan1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया सिंचन प्रकार निवडा')}</div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                            <div >
                                                                <input type="text" value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                            <img className='close-img' src={close} alt="" />
                                                        </div>
                                                        <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                            <div>
                                                                <input type="text" placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                            </div>
                                                        </div>
                                                        <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                    </div>
                                                </div>

                                            </div> */}
                                            {location.state == "1" && (
                                                <>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div >
                                                                        <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                    <img className='close-img' src={close} alt="" />
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div>
                                                                        <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                            </div>
                                                        </div>

                                                    </div>





                                                    <div className="col-md-6 col-sm-6 col-lg-6" style={{ marginTop: "px" }}>
                                                        <div className="form-group ">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('उद्देश')}  </label>
                                                            <div className="form-inline radio-btn" style={{ gap: "5px" }}>
                                                                <input className="form-check-input local" type="radio" name="lang" value={local} defaultChecked onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setLocal('Local')
                                                                }} id='local' style={{ cursor: "pointer", position: "relative", left: "7px", marginLeft: "5px", border: "1px solid gray" }} /> <label htmlFor='local' className='local2' style={{ cursor: "pointer" }}>{t('लोकल')} </label>
                                                                <input className="form-check-input local" type="radio" name="lang" value={export1} onClick={(e) => {
                                                                    setLocal("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setExport('Export')
                                                                }} id='export' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label htmlFor='export' className='local2' style={{ cursor: "pointer" }}> {t('एक्स्पोर्ट')}</label>
                                                                <input className="form-check-input local" type="radio" name="lang" value={bedana} onClick={(e) => {
                                                                    setExport("")
                                                                    setLocal("")
                                                                    setLocalExport("")
                                                                    setBedana('Bedana')
                                                                }} id='local-export' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label className='local2' htmlFor='local-export' style={{ cursor: "pointer" }}>{t('बेदाना')}</label>
                                                                <input className="form-check-input local" type="radio" name="lang" value={localexport} onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocal("")
                                                                    setLocalExport('Local-Export')
                                                                }} id='bedana' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label className='local2' htmlFor='bedana' style={{ cursor: "pointer" }}>{t('लोकल-एक्सपोर्ट')}</label>
                                                            </div>
                                                            <div style={local1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया उद्देश्य निवडा (आयात/निर्यात)')}</div>
                                                        </div>
                                                    </div>

                                                </>
                                            )}
                                            {location.state == "4" && (
                                                <>

                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div >
                                                                        <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                    <img className='close-img' src={close} alt="" />
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div>
                                                                        <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                            </div>
                                                        </div>

                                                    </div>





                                                    <div className="col-md-6 col-sm-6 col-lg-6" style={{ marginTop: "px" }}>
                                                        <div className="form-group ">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('उद्देश')}  </label>
                                                            <div className="form-inline radio-btn" style={{ gap: "5px" }}>
                                                                <input className="form-check-input local" type="radio" name="lang" value={local} defaultChecked onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setLocal('Local')
                                                                }} id='local' style={{ cursor: "pointer", position: "relative", left: "7px", marginLeft: "5px", border: "1px solid gray" }} /> <label htmlFor='local' className='local2' style={{ cursor: "pointer" }}>{t('लोकल')} </label>

                                                                <input className="form-check-input local" type="radio" name="lang" value={export1} onClick={(e) => {
                                                                    setLocal("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setExport('Export')
                                                                }} id='export' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label htmlFor='export' className='local2' style={{ cursor: "pointer" }}> {t('एक्स्पोर्ट')}</label>

                                                                <input className="form-check-input local" type="radio" name="lang" value={localexport} onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocal("")
                                                                    setLocalExport('Local-Export')
                                                                }} id='bedana' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label className='local2' htmlFor='bedana' style={{ cursor: "pointer" }}>{t('लोकल-एक्सपोर्ट')}</label>
                                                            </div>
                                                            <div style={local1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया उद्देश्य निवडा (आयात/निर्यात)')}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {location.state == "5" && (
                                                <>

                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div >
                                                                        <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                    <img className='close-img' src={close} alt="" />
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div>
                                                                        <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-lg-6" style={{ marginTop: "px" }}>
                                                        <div className="form-group ">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('उद्देश')}  </label>
                                                            <div className="form-inline radio-btn" style={{ gap: "5px" }}>
                                                                <input className="form-check-input local" type="radio" name="lang" value={local} defaultChecked onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setLocal('Local')
                                                                }} id='local' style={{ cursor: "pointer", position: "relative", left: "7px", marginLeft: "5px", border: "1px solid gray" }} /> <label htmlFor='local' className='local2' style={{ cursor: "pointer" }}>{t('लोकल')} </label>
                                                                <input className="form-check-input local" type="radio" name="lang" value={export1} onClick={(e) => {
                                                                    setLocal("")
                                                                    setBedana("")
                                                                    setLocalExport("")
                                                                    setExport('Export')
                                                                }} id='export' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label htmlFor='export' className='local2' style={{ cursor: "pointer" }}> {t('एक्स्पोर्ट')}</label>
                                                                <input className="form-check-input local" type="radio" name="lang" value={localexport} onClick={(e) => {
                                                                    setExport("")
                                                                    setBedana("")
                                                                    setLocal("")
                                                                    setLocalExport('Local-Export')
                                                                }} id='bedana' style={{ cursor: "pointer", position: "relative", marginLeft: "5px", left: "7px", border: "1px solid gray" }} /> <label className='local2' htmlFor='bedana' style={{ cursor: "pointer" }}>{t('लोकल-एक्सपोर्ट')}</label>
                                                            </div>
                                                            <div style={local1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया उद्देश्य निवडा (आयात/निर्यात)')}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {location.state == "7" && (
                                                <>
                                                    <div className="col-md-6 col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div >
                                                                        <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                    <img className='close-img' src={close} alt="" />
                                                                </div>
                                                                <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                    <div>
                                                                        <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                    </div>
                                                                </div>
                                                                <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                            </div>
                                                        </div>

                                                    </div>





                                                </>
                                            )}
                                            {location.state == "11" && (
                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div >
                                                                    <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                <img className='close-img' src={close} alt="" />
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div>
                                                                    <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            {location.state == "6" && (
                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div >
                                                                    <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                <img className='close-img' src={close} alt="" />
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div>
                                                                    <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            {location.state == "3" && (
                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div >
                                                                    <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                <img className='close-img' src={close} alt="" />
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div>
                                                                    <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                        </div>
                                                    </div>

                                                </div>




                                            )}
                                            {location.state == "2" && (
                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div >
                                                                    <input type="text" style={{height:"52px"}} value={plot3} placeholder={`${t('दोन झाडातील अंतर')}`} onChange={(e) => plotNumber3(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5  close-img ">
                                                                <img className='close-img' src={close} alt="" />
                                                            </div>
                                                            <div className="col-md-4 col-sm-5 col-lg-5 col-5">
                                                                <div>
                                                                    <input type="text" style={{height:"52px"}} placeholder={`${t('दोन ओळीतील अंतर')}`} value={plot2} onChange={(e) => plotNumber2(e)} className="form-control" id="inputNanme4" />
                                                                </div>
                                                            </div>
                                                            <div style={antar ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया पीक अंतर प्रविष्ट करा')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                            <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('प्लॉट चे  क्षेत्रफळ (एकर मध्ये )')}  </label>
                                                    <input type="text" value={plot} onChange={(e) => plotNumber(e)} className="form-control" id="inputNanme4" style={{height:"52px"}} placeholder={`${t('प्लॉट चे  क्षेत्रफळ')}`} />
                                                    <div style={plot1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया प्लॉट चे क्षेत्रफळ टाका')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('मागील वर्षी आलेल्या समस्या')}  </label>
                                                    <textarea type="text" value={problem} onChange={(e) => setProblem(e.target.value)} className="form-control" id="inputNanme4" style={{resize:"none"}} placeholder={`${t('मागील वर्षी आलेल्या समस्या')}`}></textarea>
                                                    <div style={problem1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया मागील वर्षी आलेल्या समस्या टाका')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                                {/* navigate('/featured_dashboard') */}
                                                <button onClick={handleSubmit(submit)} disabled={loading} className="btn btn-success text-center" style={{marginBottom:"30px"}}>
                                                    {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "45px" }}>
                                                        <RotatingLines
                                                            strokeColor="green"
                                                            strokeWidth="4"
                                                            animationDuration="0.75"
                                                            width="21"
                                                            visible={true}
                                                        /></span>}
                                                    {t('प्रस्तुत करा')}</button>
                                                {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}

export default Add_New_Plot