import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"


const initialState = {
    schedule:[],
    loading:false,
    error:null
}


export const fetchSchedule = createAsyncThunk('fetchschedule', async () => {
    const common = useSelector(state => state.commonReducer)

    const response = await axios.post(`${Base_Url}/Schedule`,
    {
        "START": "1",
         "END": "100",
         "WORD": "",
         "EXTRA1": "",
         "EXTRA2": "1",
         "EXTRA3": "",
         "LANG_ID": `${common.language_id}`,
         "USER_ID": `${common.usarid}`,
         "PLOT_ID": "1",
         "SCHEDULE_TYPE": "1"
       }
    )

    return response.data.DATA
})


const scheduleSlice = createSlice({
    name:'schedule',
    initialState,
    extraReducers:(builder) =>{
        builder.addCase(fetchSchedule.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchSchedule.fulfilled, (state, action) => {
            state.loading = false;
            state.schedule = action.payload;
        });
        builder.addCase(fetchSchedule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export default scheduleSlice.reducer