import React, { useContext, useEffect, useState } from 'react'
import plant from '../images/plant.png'
import subscription from '../images/subscription.png'
import for_sale from '../images/for-sale.png'
import dna_test from '../images/dna-test.png'
import timetable from '../images/timetable.png'
import faq from '../images/faq.png'
import question from '../images/question.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import Pencil from '../images/pencil.png'


const Plot_Info = () => {
    const navigate = useNavigate()
    const common = useSelector(state => state.commonReducer)
    const location = useLocation();
    console.log("plot_id", common.Plot_Info);
    const [plot_info, setPlot_Info] = useState(location.state)

    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 0
        })
    }, [location.pathname])

    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(location.state.CROP_NAME)
    }, [])


    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])


    const plotUpdate = () => {
        navigate('/plot_info_update', { state: plot_info })
    }


    return (
        <div style={{ marginBottom: "100px" }}>
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section id="farm-dashboard" style={{ marginBottom: "0" }}>
                    <div class="row">
                        <div class="col-xxl-12 col-md-12" style={{ marginTop: "10px" }}>
                            <div class="card info-card sales-card">
                                <div class="card-body">
                                    <div class="align-items-center">
                                        <div className="row">
                                            <div class="col-lg-2 col-md-2 col-sm-4 col-3">
                                                <div class="card-icon d-flex align-items-center justify-content-center">
                                                    <img src={common.PLOT_IMAGE} style={{ borderRadius: "10px", width:"56px" }} width="69px" class="img-fluid" />
                                                </div>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-7 col-7" style={{marginTop:"10px"}}>
                                                <div className="row" >
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12">
                                                        <div className="product-name-blk" style={{ fontSize: "14px", marginBottom: "5px", marginLeft:"0" }}> <strong>{t('पिकाचे नाव')}: </strong>{common.PLOT_CROP_NAME.slice(0, 20)}</div>
                                                    </div>
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12">
                                                        <div className="date-blk " style={{ fontSize: "14px", marginLeft:"0" }}><strong> {t('नोंदणी तारीख')} : </strong> {common.PLOT_REG_DATE} </div>
                                                    </div>

                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12 ">
                                                        <div className="land-area-blk" style={{ fontSize: "14px", marginLeft:"0" }}> <strong>{t('एकर')}  : </strong>{common.PLOT_PLOT_AREA}</div>
                                                    </div>

                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12">
                                                        <div className="land-process-blk" style={{ fontSize: "14px", marginLeft:"0" }}><strong>{t('स्थिती')}  : </strong> {common.PLOT_STATUS} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-1 col-md-1 col-sm-1 col-2' style={{display:"flex", alignItems:"center"}}>
                                                <img src={Pencil} alt="" style={{ cursor: "pointer" }} onClick={() => plotUpdate()} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="section dashboard" id="plot-info">
                    <div className="row" >
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                            <a onClick={() => navigate('/schedule', { state: location.state })}>
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="icon-circle"><img src={timetable} className="img-fluid" /></div>
                                        <div className="card-title">{t('वेळापत्रक')}</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                            <a onClick={() => navigate('/question_answer', { state: location.state })}>
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="icon-circle"><img src={faq} className="img-fluid" /></div>
                                        <div className="card-title">{t('प्रश्न विचारा')}</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                            <a onClick={() => navigate('/faq', { state: location.state })}>
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="icon-circle"><img src={question} className="img-fluid" /></div>
                                        <div className="card-title">{t('प्रश्न संच')} </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                            <a onClick={() => navigate('/disease_control', { state: location.state })}>
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="icon-circle"><img src={faq} className="img-fluid" /></div>
                                        <div className="card-title">{t('रोग नियंत्रण')} </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default Plot_Info