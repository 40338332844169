import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import not_found from '../images/not-found.png'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'


const Faq = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [faq, setFaq] = useState([])

    const common = useSelector(state => state.commonReducer)

    const location = useLocation()
    console.log("location",location.state);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const navigate = useNavigate()

    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,

            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "Faq_List",
                "ID1": `${location.state.CROP_ID}`,
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }).then((res) => {
                console.log(res.data.DATA);
                setFaq(res.data.DATA)
            })
    }, [])

    const FaqDetails = (product) => {
        navigate('/faq_details', { state: product })
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("प्रश्नसंच"))
    }, [])


    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="faq">

                    <div className="row">
                        {faq.length === 0 && (
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className='text-center'>
                                    <img src={not_found} alt="" style={{ width: "30%" }} />
                                </div>
                            </div>
                        )}
                        {faq.map((i, index) => {
                            return (
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card info-card sales-card">
                                        <div className="card-body" >
                                            <div className="accordion" id="accordionExample">

                                                <div className="accordion-item" key={index}>
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + i.FAQ_ID} aria-expanded="true" aria-controls="collapseOne">
                                                            {i.FAQ_NAME}
                                                        </button>
                                                    </h2>
                                                    <div id={'collapse' + i.FAQ_ID} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body" style={{ cursor: "pointer" }} onClick={() => FaqDetails(i)}>
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-4">
                                                                    <div className="faq-prod-img">
                                                                        <img src={i.FAQ_IMAGE} className="img-fluid" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8 col-sm-8">
                                                                    <strong>उत्तर :  </strong>{parse(i.FAQ_DESCRIPTION)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}                      

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Faq