import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"



const initialState ={
    banner:[],
    loading:false,
    error:null
}

export const fetchBanner = createAsyncThunk('fetchbanner', async() => {
    const response = await axios.post(`${Base_Url}/Get_Data`,
    {
        "START": "1",
        "END": "100",
        "WORD": "",
        "GET_DATA": "Banner_List",
        "ID1": "1",
        "ID2": "",
        "ID3": "",
        "STATUS": "",
        "START_DATE": "",
        "END_DATE": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": "1"
      }
    )

    return response.data.DATA
})


const bannerSlice = createSlice({
    name:"banner",
    initialState,
    extraReducers:(builder) =>{
        builder.addCase(fetchBanner.pending, (state) => {
            state.loading = true;
            state.error = null
        });
        builder.addCase(fetchBanner.fulfilled, (state, action) => {
            state.loading = false;
            state.banner = action.payload
        });
        builder.addCase(fetchBanner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})

export default bannerSlice.reducer