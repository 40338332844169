import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../useContext/AuthContext';
import close from '../images/close.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { Base_Url } from '../Base_Url/Base_Url';
import { fabClasses } from '@mui/material';
import axios from 'axios';
import moment from 'moment/moment';
import { animateScroll } from 'react-scroll';
import { updateState } from '../redux/Persist';
import { toast } from 'react-toastify';
const Plot_Info_Update = () => {
    const location = useLocation()
    const common = useSelector(state => state.commonReducer)

    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('text')
    const [zameen, setZameen] = useState("")
    const [plot3, setPlot3] = useState("")
    const [plot2, setPlot2] = useState("")
    const [antar, setAntar] = useState(false)
    const [local, setLocal] = useState("Local")
    const [plot1, setPlot1] = useState(false)
    const [lagvad, setLagvad] = useState(false)
    const [problem1, setProblem1] = useState(false)
    const [plotArea, setPlotArea] = useState(common.PLOT_PLOT_AREA)
    const [problem, setProblem] = useState(common.PLOT_PREVIOUS_YEAR_PROBLEM)
    const newDate = common.PLOT_SOWING_DATE
    const [date, setDate] = useState(newDate)
    console.log("common.PLOT_CROPCATEGORY", location.state);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 0
        })
    }, [location.pathname])

    const Style = {
        usarname: {
            display: "none"
        },
        usarname2: {
            marginLeft: "5px",
            marginTop: "5px",
            color: "red"
        },
        usarname3: {
            color: "red"
        }
    }

    useEffect(() => {
        setColorChange(false)
        setnewDemo(t("अपडेट प्लॉट"))
    }, [])

    var today = new Date().toISOString().split('T')[0];


    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])

    const Styles = {
        sidebar3: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        },
        sidebar1: {
            display: "none"
        },
        sidebar4: {
            color: "red",
            marginLeft: "5px"
        }
    }


    const submit = () => {
        console.log("date1111111111", date);
        const Date = moment(date).format("MM/DD/YYYY");
        console.log("res", Date);
        if (plotArea === "") {
            return setPlot1(true)
        } else if (problem === "") {
            return setProblem1(true)
        } else {
            setLoading(true)
            axios.post(`${Base_Url}/PlotMaster`,
                {
                    "USER_ID": `${common.usarid}`,
                    "PLOT_ID": `${location.state.PLOT_ID}`,
                    "CROP_ID": `${location.state.CROP_ID}`,
                    "CROPVARIETY_ID": `${common.PLOT_CROPCATEGORY}`,
                    "CHATANI_ID": "1",
                    "ST_ID": "",
                    "WATER_SOURCE": `${location.state.WATER_SOURCE}`,
                    "PLOT_SRUCTURE": "y",
                    "METHOD_OF_WATER": "Drip",
                    "CROP_DISTANCE": `${common.PLOT_CROP_DISTANCE}`,
                    "EXPORT_LOCAL": `${common.PLOT_EXPORT_LOCAL}`,
                    "PLOT_AREA": `${plotArea}`,
                    "LATITUDE": "10.00",
                    "LONGITUDE": "10.00",
                    "PREVIOUS_YEAR_PROBLEM": `${problem}`,
                    "SOWING_DATE": `${Date}`,
                    "TASK": "EDIT",
                    "EXTRA1": "",
                    "EXTRA2": "",
                    "EXTRA3": "",
                    "LANG_ID": `${common.language_id}`
                }).then((res) => {
                    console.log("res", res.data);
                    dispatch(updateState({                      
                        PLOT_PLOT_AREA: plotArea,                        
                        PLOT_PREVIOUS_YEAR_PROBLEM: problem,
                        PLOT_SOWING_DATE: Date,
                    }))
                    navigate(-1)
                    toast.success(t('यशस्वीरित्या जोडले गेले'))
                    setLoading(false)
                })
        }
    }

    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="Dashboard-form">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body" >
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                            <h5 className="card-title">{t('अपडेट प्लॉट')}</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('व्हरायटी')} </label>
                                                    <input type="text" disabled className="form-select minimal" value={common.PLOT_VARIETY_NAME} name="" id="" style={{ cursor: "pointer", backgroundImage: "none" }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('लागवडीची तारीख')} </label>
                                                    <input style={{ padding: "12px" }} type={type} onFocus={() => setType('date')} onBlur={() => setType('date')} max={today} className="form-control" id="inputNanme4" value={date} onChange={(e) => setDate(e.target.value)} />
                                                    <div style={lagvad ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया लागवडीची तारीख निवडा')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-lg-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('जमिनीचा प्रकार')} </label>
                                                    <input type="text" className="form-select minimal" name="" id="" style={{ cursor: "pointer", backgroundImage: "none" }} disabled value={common.PLOT_ST_NAME} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('सिंचन स्रोत')}</label>
                                                    <input type='text' className="form-select minimal check" style={{ cursor: "pointer", backgroundImage: "none" }} disabled value={common.PLOT_WATER_SOURCE} />

                                                </div>
                                            </div>
                                            {common.PLOT_CROPCATEGORY === 3 && <div className="col-md-6 col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                    <div >
                                                        <input style={{ padding: "12px" }} type="text" placeholder={`${t('दोन झाडातील अंतर')}`} disabled value={common.PLOT_CROP_DISTANCE} className="form-control" id="inputNanme4" />
                                                    </div>
                                                </div>
                                            </div>}
                                            {common.PLOT_CROP_DISTANCE === 2 && <div className="col-md-6 col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                    <div >
                                                        <input style={{ padding: "12px" }} type="text" placeholder={`${t('दोन झाडातील अंतर')}`} disabled value={common.PLOT_CROP_DISTANCE} className="form-control" id="inputNanme4" />
                                                    </div>
                                                </div>
                                            </div>}
                                            {common.PLOT_CROPCATEGORY === 6 && <div className="col-md-6 col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('दोन झाडातील अंतर')}</label>
                                                    <div >
                                                        <input style={{ padding: "12px" }} type="text" placeholder={`${t('दोन झाडातील अंतर')}`} disabled value={common.PLOT_CROP_DISTANCE} className="form-control" id="inputNanme4" />
                                                    </div>
                                                </div>
                                            </div>}
                                            {common.PLOT_CROPCATEGORY === 6 && <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('उद्देश')}</label>
                                                    <input type='text' className="form-select minimal check" style={{ cursor: "pointer", backgroundImage: "none" }} disabled value={common.PLOT_EXPORT_LOCAL} />
                                                </div>
                                            </div>}
                                            <div className="col-md-6 col-sm-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('प्लॉट चे  क्षेत्रफळ (एकर मध्ये )')}  </label>
                                                    <input style={{ padding: "12px" }} type="text" className="form-control" id="inputNanme4" placeholder={`${t('प्लॉट चे  क्षेत्रफळ')}`} value={plotArea} onChange={(e) => setPlotArea(e.target.value)} />
                                                    <div style={plot1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया प्लॉट चे क्षेत्रफळ टाका')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="inputNanme4" className="form-label">{t('मागील वर्षी आलेल्या समस्या')}  </label>
                                                    <textarea type="text" style={{ resize: "none", padding: "12px" }} className="form-control" id="inputNanme4" placeholder={`${t('मागील वर्षी आलेल्या समस्या')}`} value={problem} onChange={(e) => setProblem(e.target.value)}></textarea>
                                                    <div style={problem1 ? Styles.sidebar4 : Styles.sidebar1} >{t('कृपया मागील वर्षी आलेल्या समस्या टाका')}</div>
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                                    {/* navigate('/featured_dashboard') */}
                                                    <button disabled={loading} className="btn btn-success text-center" onClick={submit} style={{ marginBottom: "30px" }}>
                                                        {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "45px" }}>
                                                            <RotatingLines
                                                                strokeColor="green"
                                                                strokeWidth="4"
                                                                animationDuration="0.75"
                                                                width="21"
                                                                visible={true}
                                                            /></span>}
                                                        {t('समाविष्ट करा')}</button>
                                                    {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* </div > */}

        </div >
    )
}

export default Plot_Info_Update