import React from 'react'
import farmer from '../images/farmer.png'
import lab from '../images/lab.png'
import nursary from '../images/nursary.png'
import book from '../images/book.png'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useContext } from 'react'
import { useEffect } from 'react'

const Farmer_Scouting_Report = () => {

    const { sidebar, setLeftArrow } = useContext(AuthContext)

    const navigate = useNavigate()

    useEffect(() => {
        setLeftArrow(false)
    },[])

    return (
        <div className="main">
            <main id="main"  className={sidebar ? 'sidebar1' : 'sidebar2'}>

                <section className="section" id="feature_dashboard">

                    <div className="row" id="product-tab">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 co-sm-3 col-3" >
                                            <a >
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={farmer} className="img-fluid" width="48px" />
                                                    </div>
                                                    पीक समस्या पाठवा
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                                            <a >
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={lab} className="img-fluid" width="48px" />
                                                    </div>
                                                    प्रलंबित विनंती
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                                            <a >
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={nursary} className="img-fluid" width="48px" />
                                                    </div>
                                                    प्राप्त उपचार
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                                            <a >
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={book} className="img-fluid" width="48px" />
                                                    </div>
                                                    उपचार झाले
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="product-blk">

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="xcard mb-3">
                                <div className="row g-0">
                                    {/* <!--  <div className="col-md-4">
            <img src="images/rice-img.jpg" className="img-fluid rounded-start" alt="...">
          </div> --> */}
                                    <div className="col-md-12">
                                        <a onClick={() => navigate('/lab_inquery')}>
                                            <div className="card-body">

                                                <div className="inner-dashboard-blk">

                                                    <div className="row">

                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                                            <div className="product-name-blk text-center"><h3> तुमच्या पिकाच्या शेतात काही समस्या आहेत</h3>
                                                                <h6>त्या कशा सोडवायच्या हे तुम्हाला माहीत नाही?</h6>

                                                                <h6 className="company-title">कृषिदूत यांचे अस्सल, विश्वासार्ह आणि विनामूल्य सल्ला वापरून पहा.</h6>

                                                            </div>
                                                            <div className="farmar-reports-btn text-center mr-t2">
                                                                <a  className="btn btn-success text-center">पीक समस्या पाठवा</a>
                                                                {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="farmer-report-follow-setps">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="farm-report-description">
                                        <ul>
                                            <h4>क्रॉप केअर कशे कार्य करते?</h4>
                                            <li> 1. पीक समस्या पाठवा बटण वर क्लिक करा.   </li>
                                            <li>2. पीक आणि प्लॉट ची माहितीसह फॉर्म भरा.</li>
                                            <li>3. प्रभावित झाडांची काही चित्रे क्लिक करा आणि आम्हाला पाठवा.</li>
                                            <li>4. आमची तज्ञ टीम तक्रार केलेल्या समस्येचे विश्लेषण करेल आणि तुमच्या शेताला भेट देईल आणि समस्येचे अचूक निराकरण देईल. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </main>
        </div>
    )
}

export default Farmer_Scouting_Report