import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchDesease } from '../redux/Desease'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser';
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useState } from 'react'
import not_found from '../images/not-found.png'
import vector_img from '../images/vector_img.jpg'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'



const Desease_control_2 = (props) => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [Desease, setDesease] = useState([])

    const location = useLocation()
    console.log("location",location.state);

    const common = useSelector(state => state.commonReducer)

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    

    // const { Desease, loading } = useSelector((state) => state.Desease)
    // console.log("Desease", Desease);

    const navigate = useNavigate()

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("रोग नियंत्रण"))
    }, [])


    const dispatch = useDispatch()

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "DiseaseControl_List",
                "ID1": `${props.value.CROP_ID}`,
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log("res",res.data.DATA);
            setDesease(res.data.DATA)

        })
    }, [])

    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }
    return (
        <>
                    <div className="row">
                        {Desease.length === 0 && (
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className='text-center'>
                                    <img src={not_found} alt="" style={{ width: "30%" }} />
                                </div>
                            </div>
                        )}
                        {Desease.map((i) => {
                            return (
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="card info-card sales-card">
                                        <div className="card-body" >
                                            <div className="row" style={{ cursor: "pointer" }} onClick={() => navigate('/disease_control_list', {state:i})}>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="question-answer-list-prod-img">
                                                        <img src={i.PRE_IMAGE === "" ? vector_img : i.PRE_IMAGE} className="img-fluid" alt="" style={{height:"187px"}} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="question-answer-list-outer-blk" style={{ display: "flex" }}>
                                                        <div style={{ width: "690px" }}>
                                                            <h5 className='tittle'><strong>{i.TITLE}</strong></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>

                            )
                        })}


                    </div>
                    </>
    )
}

export default Desease_control_2