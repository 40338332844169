import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import logo from '../images/logo.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'

const Notifications = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [notification, setNotifications] = useState([])
    const [notification_status, setNotification_Status] = useState(false)

    const common = useSelector((state) => state.commonReducer)

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const navigate = useNavigate()

    const Styles = {
        container1: {
            cursor: "pointer",
            background: "rgb(8 129 8 / 18%)",
            paddingTop:"14px",
            paddingBottom:"14px",
            borderRadius:"5px"
        },
        container2: {
            cursor: "pointer",
            background: "rgb(255 0 0 / 14%)",
            paddingTop:"14px",
            paddingBottom:"14px",
            borderRadius:"5px"
        }
    }

    const deleteNotification = (i) => {
        axios.post(`${Base_Url}/Notification`,
        {"NOTI_ID":`${i.NOTI_ID}`,"USER_ID":`${common.usarid}`,"TASK":"Delete","EXTRA1":"","EXTRA2":""}
        ).then((res) => {
            console.log(res.data);
        })
    }

    const showNotifications = (i) => {
        axios.post(`${Base_Url}/Notification`,
        {"NOTI_ID":`${i.NOTI_ID}`,"USER_ID":`${common.usarid}`,"TASK":"View","EXTRA1":"","EXTRA2":""}
        )
        navigate('/notifications_detail', { state: i })
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("नोटिफिकेशन"))
    },[])


    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": 0, "END": 10000, "WORD": "", "GET_DATA": "Get_Notification", "ID1": `${common.usarid}`, "ID2": "", "ID3": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}`, "STATUS": "" }
        ).then((res) => {
            console.log(res.data.DATA);
            const data = res.data.DATA
            setNotifications(res.data.DATA)
            if (data.IS_VIEW === "No") {
                return setNotification_Status(true)
            }
        })
    }, [notification])

    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="question-answer">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body" >
                                    {notification.map((i) => {
                                        return (
                                            <>
                                                <div className="row" style={i.IS_VIEW === "Yes" ? Styles.container1 : Styles.container2}>
                                                    <div className="col-lg-3 col-md-3 col-sm-3" onClick={() => showNotifications(i)}>
                                                        <div className="question-answer-list-prod-img">
                                                            <img style={{ width: "52%" }} src={i.NOTI_IMAGE === "" ? logo : i.NOTI_IMAGE} className="img-fluid" alt="" />
                                                            <p style={{ marginTop: "9px" }}>{i.REG_DATE}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8" onClick={() => showNotifications(i)}>
                                                        <div className="question-answer-list-outer-blk" style={{ display: "flex" }}>
                                                            <div style={{ width: "690px" }} >
                                                                <h5><strong>{i.NOTI_TITLE}</strong></h5>
                                                            </div>
                                                        </div>
                                                        <p className='question' style={{ marginTop: "0" }} ><strong>{i.DESCRIPTION}</strong></p>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-1">
                                                        <h5 style={{ color: "#cd2222" }} onClick={() => deleteNotification(i)}><i class="bi bi-trash3"></i></h5>
                                                    </div>
                                                </div>
                                                <hr style={{margin:"revert"}} />
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Notifications