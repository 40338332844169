import React from 'react'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import produc_gallery2 from '../images/produc-gallery2.jpg'
import { AuthContext } from '../useContext/AuthContext'
import parse from 'html-react-parser'
import { useEffect } from 'react'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'


const FaqDetails = () => {

    const{sidebar, setLeftArrow, setnewDemo, setColorChange} = useContext(AuthContext)

    const location = useLocation()
    console.log("location.state",location.state)

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])
 
    const Styles = {
        sidebar1:{
            marginLeft:"300px"
        },
        sidebar2:{
            marginLeft:""
        }
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("प्रश्नसंच तपशील"))
    },[])

  return (
    <div>
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div 
                                            className="text-center">
                                                <img src={location.state.FAQ_IMAGE} className="img-fluid img-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="product-name-blk"><h4>{location.state.FAQ_NAME}</h4></div>
                                            {/* <div className="product-price">300/ 500ml</div> */}
                                        </div>
                                        <div className="prod-descrip ">
                                            <h5></h5>
                                            <p>{parse(location.state.FAQ_DESCRIPTION)}</p>
                                        </div>
                                        {/* <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                                            <div className="mob-view-btn">
                                                <a href="feature_dashboard.html" className="btn btn-success text-center">Submit</a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
  )
}

export default FaqDetails