import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import mob_soiltestinglab from '../images/mob-soiltestinglab.jpg'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useForm } from 'react-hook-form'
import { RotatingLines } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import i18next from 'i18next'
import { animateScroll } from 'react-scroll'


const user_id = localStorage.getItem('user_id')
const Lab_Inquery = () => {
  const [fname, setFname] = useState("")
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [pincode, setPincode] = useState("")
  const [remark, setRemark] = useState("")
  const [loading, setLoading] = useState(false)
  const [fullname, setFullname] = useState(false)
  const [mobile1, setMobile1] = useState(false)
  const [mobile2, setMobile2] = useState(false)
  const [address1, setAddress1] = useState(false)
  const [pincode1, setPincode1] = useState(false)
  const [pincode2, setPincode2] = useState(false)
  const [remark1, setRemark1] = useState(false)

  const common = useSelector(state => state.commonReducer)


  const navigate = useNavigate()

  const location = useLocation()
  console.log("location.state", location.state);
  const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)

  const { handleSubmit } = useForm()

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    })
  }, [location.pathname])

  const Style = {
    usarname: {
      display: "none"
    },
    usarname2: {
      marginLeft: "5px",
      marginTop: "5px",
      color: "red"
    },
    usarname3: {
      color: "red"
    }
  }
  const mobileNumber = (e) => {
    const value = e.target.value;
    if (!Number(value)) {
      return setMobile("")
    } else if (value.length === 11) {
      return;
    }

    setMobile(value)
  }

  const pincodeNumber = (e) => {
    const value = e.target.value;
    if (!Number(value)) {
      return setPincode("")
    } else if (value.length === 7) {
      return;
    }

    setPincode(value)
  }

  const submit = () => {
    // navigate('/featured_dashboard')
    const pattern = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/);



    if (!fname) {
      return setFullname(true)
    } else if (!mobile) {
      return setMobile1(true)
    } else if (mobile.length !== 10) {
      return setMobile2(true)
    } else if (!pattern.test(mobile)) {
      return setMobile2(true)
    } else if (!address) {
      return setAddress1(true)
    } else if (!pincode) {
      return setPincode1(true)
    } else if (pincode.length !== 6) {
      return setPincode2(true)
    } else if (!remark) {
      return setRemark1(true)
    } else {
      return setLoading(true),
        axios.post(`${Base_Url}/LabEnquiry/1`,
          {
            "USER_ID": `${common.usarid}`,
            "LCAT_ID": `${location.state.LCAT_ID}`,
            "REMARK": `${remark}`,
            "EXTRA1": "",
            "EXTRA2": "",
            "EXTRA3": "",
            "TASK": "ADD",
            "FULL_NAME": `${fname}`,
            "MOBILE_NO": `${mobile}`,
            "ADDRESS": `${address}`,
            "PINCODE": `${pincode}`
          }
        ).then((res) => {
          console.log(res.data.DATA);
          setLoading(false)
          navigate('/lab_enquiry_list')
        })
    }

  }


  useEffect(() => {
    setLeftArrow(false)
    setColorChange(false)
    setnewDemo(t("लॅब चौकशी"))
  }, [])

  const { t } = useTranslation();
  useEffect(() => {
    const lang = common.language
    i18next.changeLanguage(lang)
  }, [])



  return (
    <div className="main">
      <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>

        <section className="section dashboard" id="Dashboard-form">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-body" >
                  <div className="row">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('टिप्पणी')}</label>
                          <input type="text" disabled style={{ cursor: "not-allowed" }} className="form-control" id="inputNanme4" placeholder={location.state.LCAT_NAME} />
                          {/* <h5>soil Testing</h5> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('रक्क्म')} (&#x20B9;) </label>
                          <input type="text" disabled style={{ cursor: "not-allowed" }} className="form-control " id="inputNanme4" placeholder={location.state.AMOUNT.toFixed(1)} />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-lg-12 d-sm-block ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('नमुना कसा गोळा  करावा ?')} </label>
                          <div className="soil-testing-img text-center">
                            <i class="bi bi-play-circle text-center-1"></i>
                            <a href={`https://www.youtube.com/watch?v=${location.state.VIDEO_URL}`} target="_blank">
                              <img src={mob_soiltestinglab} className="img-fluid" alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('पूर्ण नाव')}  </label>
                          <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value)} id="inputNanme4" placeholder={`${t('पूर्ण नाव')}`} />
                          <div style={fullname ? Style.usarname2 : Style.usarname}>{t('कृपया वैध नाव प्रविष्ट करा')} </div>
                        </div>
                      </div>


                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('मोबाईल नंबर')}   </label>
                          <input type="text" className="form-control" value={mobile} onChange={(e) => mobileNumber(e)} id="inputNanme4" placeholder={`${t('मोबाईल नंबर')}`} />
                          <div style={mobile1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा मोबाइल नंबर टाका')}</div>
                          <div style={mobile2 ? Style.usarname2 : Style.usarname}>{t('कृपया वैध मोबाइल नंबर टाका')}</div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('पत्ता')}  </label>
                          <input type="text" className="form-control d-none d-lg-block " value={address} onChange={(e) => setAddress(e.target.value)} id="inputNanme4" placeholder={`${t('पत्ता')}`} />
                          <textarea type="text" className="form-control d-lg-none d-sm-block" value={address} onChange={(e) => setAddress(e.target.value)} id="inputNanme4" placeholder={`${t('पत्ता')}`}></textarea>
                          <div style={address1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा पत्ता टाका')} </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('पिन कोड')}   </label>
                          <input type="text" className="form-control" id="inputNanme4" value={pincode} onChange={(e) => pincodeNumber(e)} placeholder={`${t('पिन कोड')}`} />
                          <div style={pincode1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमचा पिन कोड टाका')}</div>
                          <div style={pincode2 ? Style.usarname2 : Style.usarname}>{t('कृपया वैध पिन कोड टाका')}</div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 ">
                        <div className="form-group">
                          <label for="inputNanme4" className="form-label">{t('टिपणी')}  </label>
                          <input type="text" className="form-control d-none d-lg-block " value={remark} onChange={(e) => setRemark(e.target.value)} id="inputNanme4" placeholder={`${t('टिपणी')}`} />
                          <textarea type="text" className="form-control d-lg-none d-sm-block" id="inputNanme4" value={remark} onChange={(e) => setRemark(e.target.value)} placeholder={`${t('टिपणी')}`}></textarea>
                          <div style={remark1 ? Style.usarname2 : Style.usarname}>{t('कृपया तुमची टिपणी टाका')}</div>
                        </div>
                      </div>

                      {/* <div className="col-md-12 col-sm-12 col-lg-12 d-none d-sm-none d-lg-block">
                        <div className="form-group namuna-">
                          <label for="inputNanme4" className="form-label">नमुना कसा गोळा  करावा ? </label>
                          <div className="soil-testing-img">
                            <img src="images/soiltestinglab.jpg" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                        <button onClick={submit} disabled={loading} className="btn btn-success text-center" style={{ marginBottom: "30px" }}>
                          {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "42px" }}>
                            <RotatingLines
                              strokeColor="green"
                              strokeWidth="4"
                              animationDuration="0.75"
                              width="21"
                              visible={true}
                            /></span>}
                          {t('प्रस्तुत करणे')}</button>
                        {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

      </main>
    </div>
  )
}

export default Lab_Inquery