import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CardSlider.css'; // Custom CSS for styling
import logo from '../images/logo.png'
import { AuthContext } from '../useContext/AuthContext';
import axios from 'axios';
import { Base_Url } from '../Base_Url/Base_Url';
import { useSelector } from 'react-redux';


const CardSlider = ({ cards}) => {
    const initialSlide = 0
    const { sidebar, setLeftArrow, setColorChange, setnewDemo, product, setProduct } = useContext(AuthContext)
    const common = useSelector(state => state.commonReducer)

    const settings = {
        dots: true, // Show navigation dots
        infinite: true, // Allow infinite scrolling
        speed: 500, // Transition speed in milliseconds
        slidesToShow: Math.min(cards.length, 3), // Number of cards to show at once
        slidesToScroll: 3, // Number of cards to scroll
        initialSlide
       
    };

    const subProduct = (product) => {
        console.log("product", product);
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": `${product.PCAT_ID}`, "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log("res", res.data.DATA);
            setProduct(res.data.DATA)
        })
    }

    return (
        <Slider {...settings}>
            {cards.map((i, index) => (
                <div className="row">
                    <div className="col-lg-3 col-md-3 co-sm-3 col-3" style={{ marginTop: "20px" }}>
                        <div key={index} className="card1   ">
                            <a style={{ cursor: "pointer" }} onClick={() => subProduct(i)}>
                                <div className="product-ouetr-blk">
                                    <div className="farm-prodct-icon-circle">
                                        <img src={i.PCAT_IMAGE === ("" || null) ? logo : i.PCAT_IMAGE} className="img-fluid" width="60px" style={{ borderRadius: "50%", width: "97px", height: "80px", position: "relative", top: "-15px" }} />
                                    </div>
                                    <p>{i.PCAT_NAME.split(" ")[0]}</p>
                                </div>
                            </a>
                            {/* Render card content here */}
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default CardSlider;
