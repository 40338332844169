import React, { useContext, useEffect, useLayoutEffect, useState, useTransition } from 'react'
import lab from '../images/report1.png'
import nursary from '../images/brand-identity2.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBanner } from '../redux/Banner'
import ReactCardSlider from 'react-card-slider-component';
import { fetchPlotlist } from '../redux/PlotList'
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import plant from '../images/plant.png'
import logo from '../images/logo.png'
import CloseIcon from '@mui/icons-material/Close';
import not_found from '../images/not-found.png'
import { updateState } from '../redux/Persist'
import { animateScroll } from 'react-scroll'
import broken_cloud from '../images/fourteen_weather.webp'
import overcast_clouds from '../images/third_weather.webp'
import light_rain from '../images/sixth_weather.webp'
import autoimmune_disease from '../images/autoimmune-disease.png'
import clear_sky from '../images/first_weather.webp'
import scattered_clouds from '../images/eighth_weather.webp'
import few_clouds from '../images/twelve_weather.webp'
import thermometer from '../images/thermometer_1.png'
import windimg from '../images/wind.png'
import humidityimg from '../images/humidity.png'
import pressureimg from '../images/pressure.png'
import { Box, Button, Modal } from '@mui/material'
import Parent from './Slider'
import Parent1 from './Slider1'
import { RotatingLines } from 'react-loader-spinner'
import { usePWAInstall } from 'react-use-pwa-install'


const user_id = localStorage.getItem('user_id')

const Dashboard = () => {  
    const navigate = useNavigate()
    const [videoList, setVideoList] = useState([])
    const [product, setProduct] = useState([])
    const [productGallery, setProductGallery] = useState([])
    const [Plot, setPlot] = useState([])
    const [videosection, setVideoSection] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true)

    const common = useSelector(state => state.commonReducer)

    const { sidebar, setSidebar, setLeftArrow, setMainNav, setColorChange } = useContext(AuthContext)

    const { banner } = useSelector((state) => state.banner)
    // const { Plot } = useSelector((state) => state.plotlist)
    // console.log("Plot", Plot)
    const [wind, setWind] = useState([])
    const [temp, setTemp] = useState([])
    const [humidity, setHumidity] = useState([])
    const [pressure, setPressure] = useState([])
    const [mtemp, setMtemp] = useState([])
    const { t } = useTranslation();

    const [new1, setNew] = useState([])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        textAlign: "center",
        boxShadow: 24,
        width: "332px",
        borderRadius: "8px",
        border: "none",
        pt: 2,
        px: 4,
        paddingBottom: "16px"
    };
    const location = useLocation()

    const productDetails = (i) => {
        navigate('/product_details', { state: i })
    }

      useLayoutEffect(() => {
        setLeftArrow(true)
        setColorChange(true)
    },[])


    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "10000",
                "WORD": "",
                "GET_DATA": "Video_List",
                "ID1": "",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log("res", res.data);
            setVideoList(res.data.DATA);

        })
    }, [videoList])

    useEffect(() => {
        animateScroll.scrollToTop(
            {
                duration: 0
            }
        )
    }, [location.pathname])

    const slides =
        productGallery.map((i) => {
            return (

                { image: `${i.PRODUCT_IMAGE === null ? logo : i.PRODUCT_IMAGE}`, title: `${i.PRODUCT_NAME.slice(0, 7)}`, description: `${i.PRODUCT_DESCRIPTION.slice(3, 20)}...`, clickEvent: () => productDetails(i) }

            )
        })

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, [3000])
    }, [])

  

    useEffect(() => {        
        setMainNav(true)
        setColorChange(true)
    }, [])



    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data.DATA);
            setProductGallery(res.data.DATA)
        })
    }, [])

    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [i18next.changeLanguage])

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Product_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data.DATA);
            setProduct(res.data.DATA)

        })
    }, [])
    useEffect(() => {
        setLoading(true)
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("position.coords.latitude", position.coords.latitude);
            console.log("position.coords.longitude", position.coords.longitude);
            axios.get(`https://api.openweathermap.org/data/2.5/forecast?lat=${20.008765261338628}&lon=${73.76380829721516}&appid=f63af5e4778e517ec5e7599d6172eae5`)
                .then((res) => {
                    const data = res.data.list[0]
                    console.log("data", data);
                    setWind(data.wind.speed);
                    setTemp(data.main.temp_min);
                    setHumidity(data.main.humidity);
                    setPressure(data.main.pressure);
                    setMtemp(data.main.temp_max);
                    setNew(data.weather[0])
                    setLoading(false)

                })
        })
    }, [])

    

    useEffect(() => {
        axios.post(`${Base_Url}/PlotMaster/1`,
            {
                "START": "1",
                "END": "10000",
                "WORD": "ADD",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "USER_ID": `${common.usarid}`,
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log("data", res.data);
            setPlot(res.data.DATA)
        })
    }, [])

    const dispatch = useDispatch()
    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        dispatch(updateState({
            addPlot: `${Plot.length}`
        }))


    }, [])


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const Plot_Info = (i) => {        
        navigate('/plot_info', { state: i })
        dispatch(updateState({
            PLOT_ID: `${i.PLOT_ID}`,
            PLOT_IMAGE:i.CROP_IMAGE,
            PLOT_CROP_NAME:i.CROP_NAME,
            PLOT_REG_DATE:i.REG_DATE,
            PLOT_PLOT_AREA:i.PLOT_AREA,
            PLOT_STATUS:i.STATUS,
            PLOT_VARIETY_NAME:i.VARIETY_NAME,
            PLOT_CROPCATEGORY:i.CROPCATEGORY,
            PLOT_WATER_SOURCE:i.WATER_SOURCE,
            PLOT_CROP_DISTANCE:i.CROP_DISTANCE,
            PLOT_EXPORT_LOCAL:i.EXPORT_LOCAL,
            PLOT_PLOT_AREA:i.PLOT_AREA,
            PLOT_PREVIOUS_YEAR_PROBLEM:i.PREVIOUS_YEAR_PROBLEM,
            PLOT_SOWING_DATE:i.SOWING_DATE,
            PLOT_ST_NAME : i.ST_NAME
        }))
    }

    useEffect(() => {
        dispatch(fetchBanner())
        dispatch(fetchPlotlist())
        if (window.innerWidth > 768) {
            setSidebar(true)
            setVideoSection(true)
        }
    }, [window.innerWidth])
    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <div style={{ marginRight: "12px" }}>
                    <section className="section" id="feature_dashboard">
                        <div className="row" style={{ padding: "10px" }}>
                            <div className="col-lg-6 col-md-6" style={{ borderRadius: "10px", height: "200px" }}>
                                <div className="card" style={{ background: "#80808014" }}>
                                    <div className="card-body pd-0 dashboard-card-body">
                                        <Carousel>
                                            {banner.map((i) => {
                                                return (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100 h-100"
                                                            src={i.BANNER_IMAGE}
                                                            alt="First slide"
                                                        />
                                                    </Carousel.Item>
                                                )
                                            })}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                            {loading ?
                                (
                                    <>
                                        <div class="col-lg-6 col-md-6" id="pd-20">
                                            <div id="clouds" class="row weather-block service-list" style={{ height: "200px", cursor: "pointer", display:"flex", alignItems:"center" }} >
                                                <div style={{textAlign:"center"}}>
                                                    <RotatingLines
                                                        strokeColor="white"
                                                        strokeWidth="3"
                                                        animationDuration="0.75"
                                                        width="10%"
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                ) : (
                                    <div class="col-lg-6 col-md-6" id="pd-20">
                                        <div id="clouds" class="row weather-block service-list" style={{ height: "200px", cursor: "pointer" }} onClick={() => navigate('/weather')}>

                                            <div class="col-12 " style={{ padding: "5px 0px 0px 0px" }}>
                                                <div class="row" style={{ height: "15px", paddingRight: "5px", paddingLeft: "5px", borderRadius: "10px", marginBottom: "10px" }}>
                                                    <div class="col-6 p-0" style={{ textAline: "left", margin: "auto" }}>
                                                        <h6 style={{ marginLeft: "34px", marginTop: "76px" }}><img style={{ height: "19px", marginLeft: "-21px", marginTop: "15px", paddingRight: "4px" }} src={thermometer} />{t('किमान')}</h6>
                                                        <h6 style={{ marginLeft: "35px", fontWeight: "bold", marginTop: "-18px" }}>{(temp - 273.15).toFixed(2)} &#176;C</h6>
                                                    </div>
                                                    <div class="col-6 p-0" style={{ textAlign: "right", paddingRight: "10px!important", margin: "auto" }}>
                                                        <h6 className='paha'>{t('सर्व पहा')}   </h6>
                                                    </div>
                                                </div>
                                                <div class="row" style={{ paddingRight: "10px" }}>
                                                    <div class="col-4 p-0" style={{ textAlign: "left" }}>
                                                        {/* <img style={{ position: "absolute", top: "-12px", marginLeft: "67px", marginTop: "67px" }} class="weather-icon" src="./images/sun.webp"/> */}


                                                        {/* <img style={{ marginLeft: "33px", marginTop: "5px", height: "64px", width: "77px"}} className='img-fluid img-weather' src={few_clouds} alt="" /> */}

                                                        {new1.description === "broken clouds" && <img className='img-fluid img-weather' src={broken_cloud} alt="" />}
                                                        {new1.description === "overcast clouds" && <img className='img-fluid img-weather' src={overcast_clouds} alt="" />}
                                                        {new1.description === "light rain" && <img className='img-fluid img-weather' src={light_rain} alt="" />}
                                                        {new1.description === "clear sky" && <img className='img-fluid img-weather' src={clear_sky} alt="" />}
                                                        {new1.description === "scattered clouds" && <img className='img-fluid img-weather' src={scattered_clouds} alt="" />}
                                                        {new1.description === "few clouds" && <img className='img-fluid img-weather' src={few_clouds} alt="" />}

                                                    </div>
                                                    <div class="col-3 p-0 text-center">
                                                        <h4 style={{ color: "rgb(216 221 229)", fontWeight: "normal", position: "absolute", left: "32%" }}>{(mtemp - 273.15).toFixed(2)} &#176; c</h4>

                                                    </div>
                                                    <div class="col-5 p-0" style={{ textAlign: "left", paddingRight: "10px!important" }}>
                                                        <h6 ><img style={{ height: "19px", marginLeft: "-9px", marginTop: "20px", paddingRight: "5px" }} src={humidityimg} /> {t('आर्द्रता')}   </h6>
                                                        <h6 style={{ marginTop: "29px", fontWeight: "bold", marginLeft: "21px" }}>{humidity}%</h6>
                                                    </div>
                                                    <div class="col-5 p-0" style={{ textAlign: "left", paddingRight: "10px!important" }}>
                                                        <h6 style={{ marginTop: "49px", marginLeft: "18px" }}><img style={{ height: "19px", marginLeft: "-25px", paddingRight: "10px", marginTop: "14px" }} src={pressureimg} />{t('दबाव')}    </h6>
                                                        <h6 style={{ marginTop: "74px", fontWeight: "bold", marginLeft: "21px" }}>{pressure} hpa</h6>
                                                    </div>
                                                </div>
                                                <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                    <div class="col-6 p-0">
                                                        <h6 style={{ position: "relative", marginTop: "30px", marginLeft: "30px" }}><img style={{ height: "19px", marginLeft: "-28px", marginTop: "17px", paddingRight: "7px" }} src={windimg} />{t('वाऱ्याचा वेग')}  </h6>
                                                        <h6 style={{ marginLeft: "31px", fontWeight: "bold", marginTop: "-18px" }} >{wind} mph </h6>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )

                            }


                        </div>
                    </section>

                    <div className="row" id="product-tab">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 co-sm-4 col-4">
                                            <a onClick={() => navigate('/product_gallery')} style={{ cursor: "pointer" }}>
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={nursary} className="img-fluid" width="48px" />
                                                    </div>
                                                    {t('उत्पादन गॅलरी')}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-4 col-md-4 co-sm-4 col-4">
                                            <a onClick={() => navigate('/lab')} style={{ cursor: "pointer" }}>
                                                <div className="product-ouetr-blk" >
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={lab} className="img-fluid" width="48px" />
                                                    </div>
                                                    {t('प्रयोगशाळा')}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-4 col-md-4 co-sm-4 col-4" >
                                            <a onClick={() => navigate('/crop')} style={{ cursor: "pointer" }}>
                                                <div className="product-ouetr-blk">
                                                    <div className="farm-prodct-icon-circle">
                                                        <img src={plant} className="img-fluid" width="48px" />
                                                    </div>
                                                    {t('पिके')}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="product-blk">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="plot-add-button-blk">
                                <a onClick={() => navigate('/select_plot')} style={{ cursor: "pointer" }} className="button-style">{t('प्लॉट  नोंदणी करा')}</a>
                            </div>
                            <div><h4 style={{ marginBottom: "19px", marginLeft: "5px" }}   ><b>{t('प्लॉट यादी')}</b></h4> </div>
                        </div>
                        {Plot.length === 0 && (
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className='text-center'>
                                    <img src={not_found} alt="" style={{ width: "30%" }} />
                                </div>
                            </div>
                        )}
                        {Plot.map((i) => {
                            return (
                                <div className="col-lg-6 col-md-6 col-sm-6" onClick={() => Plot_Info(i)} style={{ cursor: "pointer" }}>
                                    <div className="card mb-3">
                                        <div className="row g-0">
                                            {/* <!--  <div className="col-md-4">
                                    <img src="images/rice-img.jpg" className="img-fluid rounded-start" alt="...">
                                </div> --> */}

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-lg-3 col-sm-3 col-3" style={{ display: "flex", alignItems: "center" }}>
                                                        <img className='w-100'
                                                            style={{ height: "80px", borderRadius: "10px" }}
                                                            // style={{borderRadius:"10px"}} width="48px" 
                                                            src={i.CROP_IMAGE} />
                                                    </div>
                                                    <div className="col-md-9 col-lg-9 col-sm-9 col-9" style={{ paddingLeft: "25px" }}>
                                                        <div className="inner-dashboard-blk" style={{ paddingLeft: "10px" }}>

                                                            <div className="row" >
                                                                {common.language_id === "1" && (
                                                                    <>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12" style={{ marginLeft: "-22px" }}>
                                                                            <div className="product-name-blk" > <strong>{t('पिकाचे नाव')}: </strong>{i.CROP_NAME}</div>
                                                                        </div>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                                                            <div className="date-blk "><strong> {t('लागवडीची तारीख')} : </strong> {i.REG_DATE} </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {common.language_id === "2" && (
                                                                    <>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12" style={{ marginLeft: "-22px" }}>
                                                                            <div className="product-name-blk" > <strong>{t('पिकाचे नाव')}: </strong>{i.CROP_NAME}</div>
                                                                        </div>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                                                            <div className="date-blk "><strong> {t('लागवडीची तारीख')} : </strong> {i.REG_DATE} </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {common.language_id === "3" && (
                                                                    <>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12" style={{ marginLeft: "-22px" }}>
                                                                            <div className="product-name-blk" > <strong>{t('पिकाचे नाव')}: </strong>{i.CROP_NAME}</div>
                                                                        </div>
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                                                            <div className="date-blk "><strong> {t('लागवडीची तारीख')} : </strong> {i.REG_DATE} </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 " style={{ marginLeft: "-22px" }} >
                                                                    <div className="land-area-blk"> <strong>{t('प्लॉट चे क्षेत्रफळ(एकर मध्ये)')} : </strong>{i.PLOT_AREA}</div>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                                                    <div className="land-process-blk"><strong>{t('प्लॉट स्थिती')}  : </strong> {i.STATUS} </div>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right" style={{ marginLeft: "-22px" }}>
                                                                    <div className="read-btn"> {t('अधिक बघा>>')}  </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    {slides.length > 0 && (
                        <div class="row" id="product-tab">
                            <div>
                                <h4><b>{t('उत्पादन')}</b></h4>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <Parent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {videosection ? (
                        <>
                            {videoList.length > 0 && (
                                <div>
                                    <h4 style={{ marginBottom: "18px", marginLeft: "46px" }}><b>{t('व्हिडिओ')}</b></h4>
                                </div>)}
                            <section id="news-knowledge">
                                <div className='card-body'>
                                    <div className="row" id="product-blk">
                                        {videoList.slice(0, 2).map((i) => {
                                            return (
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title"style={{height:"100px", textAlign:"center"}}>{i.Title}</h5>
                                                            <div id="carouselagriculture" className="carousel slide" data-bs-ride="carousel">
                                                                <i class="bi bi-play-circle position"></i>
                                                                <div className="carousel-inner" style={{ marginBottom: "10px" }}>
                                                                    <a href={`https://www.youtube.com/watch?v=${i.Video_URL}`} target="_blank" className="carousel-item active">
                                                                        <img src={i.VIDEO_IMG === null || "" ? logo : i.VIDEO_IMG} className="d-block w-100" style={{ height: "185px" }} alt="..." />
                                                                    </a>
                                                                </div>
                                                                <h5>{i.REG_DATE.slice(0, 10)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {videoList.length > 2 && <button className='video-btn' onClick={() => navigate('/video_list')}>अधिक बघा...</button>}
                            </section>
                        </>
                    ) : (
                        <>
                            {videoList.length > 0 && (
                                <div class="row" style={{ marginBottom: "50px" }} id="product-tab">
                                    <div>
                                        <h4><b>{t('व्हिडिओ')}</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12" style={{ marginBottom: "50px" }}>
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <Parent1 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
                    {Plot.length === 0 &&
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <Box sx={{ ...style }}>
                                <div style={{ display: "flex" }}>
                                    <h2 id="child-modal-title" style={{ fontSize: "1.1rem", width: "100%" }}>{t('कृपया आपल्या प्लॉटची नोंदणी करा')} </h2>
                                    <CloseIcon style={{ marginLeft: "10px", cursor: "pointer" }} onClick={handleClose} />
                                </div>
                                <Button variant='contained' style={{ background: "#008000c2" }} onClick={() => navigate('/select_plot')} >{t('नवीन प्लॉट जोडा')}</Button>
                            </Box>
                        </Modal>
                    }

                </div>


            </main>

        </div>
    )
}

export default Dashboard