import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import OTPInput, { ResendOTP } from "otp-input-react";
import { AuthContext } from '../useContext/AuthContext'
import axios from 'axios';
import { Base_Url } from '../Base_Url/Base_Url';
import { useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from '../redux/Persist';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { animateScroll } from 'react-scroll';

const Lang = localStorage.getItem("language")

const LoginOtp = () => {
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate()
    const [resendotp, setResendOtp] = useState("")
    const [loading, setLoading] = useState(false)
    const { handleSubmit } = useForm()
    const location = useLocation()
    const [mobile1, setMobile1] = useState(false)
    const common = useSelector(state => state.commonReducer)
    const dispatch = useDispatch()

    console.log("location", location.state.fname);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])


    const { setNav, setLeftArrow, setMainNav } = useContext(AuthContext)

    useEffect(() => {
        setLeftArrow(false)
    },[])
    
   

    const Style = {
        usarname: {
            display: "none"
        },
        usarname2: {
            // marginBottom: "14px",
            marginTop: "5px",
            marginLeft: "16px",
            color: "red"
        },
        usarname3: {
            color: "red"
        }
    }

    const resend = () => {
        axios.post(`${Base_Url}/OTP_Mobile`,
            {
                "MOBILE_NO": `${location.state.mobile}`
            }).then((res) => {
                console.log(res.data.DATA)
                const resendOtp = res.data.DATA
                setResendOtp(resendOtp)
                console.log("resendotp", resendotp)
                //   console.log("resendotp",resendotp.User_Otp)


            })
    }

    const loginOtp = () => {
        const otp = Number(OTP)
        // console.log("resendotp.User_Otp", resendotp);
        if (!OTP) {
            return setMobile1(true)
        }
        console.log("OTP", typeof (Number(OTP)))
        // console.log("location.state.otp[0].User_Otp",(location.state.otp[0].User_Otp))
        // console.log("resendotp[0].User_Otp",typeof(resendotp[0].User_Otp))

        if (otp === location.state.otp[0].Master_Otp || otp === location.state.otp[0].User_Otp) {
            return setLoading(true),
                axios.post(`${Base_Url}/UserRegistration`,
                    {

                        "USER_ID": "",
                        "FULL_NAME": `${location.state.fname}`,
                        "MOBILE_NO": `${location.state.mobile}`,
                        "PINCODE": "",
                        "EMAIL": `${location.state.email}`,
                        "DATE_OF_BIRTH": "",
                        "ADHARCARD_NUMBER": "",
                        "ADDRESS": "",
                        "TOKEN": "",
                        "STATE_ID": "",
                        "CITY_ID": "",
                        "TALUKA_ID": "",
                        "VILLAGE_NAME": "",
                        "PROFILE_PHOTO": "",
                        "TASK": "ADD",
                        "EXTRA1": "",
                        "EXTRA2": "",
                        "EXTRA3": "",
                        "LANG_ID": `${common.language_id}`

                    }).then((res) => {
                        console.log("res",res.data)
                        dispatch(updateState({
                            usarname: `${res.data.DATA1[0].FULL_NAME}`,
                            usarid: `${res.data.DATA1[0].USER_ID}`,
                            mobile: `${res.data.DATA1[0].MOBILE_NO}`,
                            email: `${res.data.DATA1[0].EMAIL === null ? "" : res.data.DATA1[0].EMAIL}`,
                            Profile:`${res.data.DATA1[0].PROFILE_PHOTO}`,
                        }))
                        // localStorage.setItem("usarname", res.data.DATA1[0].FULL_NAME)
                        // localStorage.setItem("usarid", res.data.DATA1[0].USER_ID)
                        // localStorage.setItem("mobile", res.data.DATA1[0].MOBILE_NO)
                        // localStorage.setItem("email", res.data.DATA1[0].EMAIL)
                        if (location.state.fname) {
                             navigate('/address')
                        }
                        else{
                            setLoading(false)
                            navigate('/')
                        }
                        setNav(true)                        
                    })
        } else if (resendotp) {
            if (otp === resendotp[0].User_Otp) {
                return setLoading(true),
                    axios.post(`${Base_Url}/UserRegistration`,
                        {

                            "USER_ID": "",
                            "FULL_NAME": `${location.state.fname}`,
                            "MOBILE_NO": `${location.state.mobile}`,
                            "PINCODE": "",
                            "EMAIL": `${location.state.email}`,
                            "DATE_OF_BIRTH": "",
                            "ADHARCARD_NUMBER": "",
                            "ADDRESS": "",
                            "TOKEN": "",
                            "STATE_ID": "",
                            "CITY_ID": "",
                            "TALUKA_ID": "",
                            "VILLAGE_NAME": `${location.state.village}`,
                            "PROFILE_PHOTO": "",
                            "TASK": "ADD",
                            "EXTRA1": "",
                            "EXTRA2": "",
                            "EXTRA3": "",
                            "LANG_ID": `${common.language_id}`

                        }).then((res) => {
                            console.log("res",res.data)
                            dispatch(updateState({
                                usarname: `${res.data.DATA1[0].FULL_NAME}`,
                                usarid: `${res.data.DATA1[0].USER_ID}`,
                                mobile: `${res.data.DATA1[0].MOBILE_NO}`,
                                village:`${res.data.DATA1[0].VILLAGE_NAME}`,
                                Profile:`${res.data.DATA1[0].PROFILE_PHOTO}`,
                                // email: `${res.data.DATA1[0].EMAIL === null ? "" : res.data.DATA1[0].EMAIL}`
                            }))
                            // localStorage.setItem("usarname", res.data.DATA1[0].FULL_NAME)
                            // localStorage.setItem("usarid", res.data.DATA1[0].USER_ID)
                            // localStorage.setItem("mobile", res.data.DATA1[0].MOBILE_NO)
                            if (location.state.fname) {
                                return  navigate('/address')
                            }else{
                            setLoading(false)
                            navigate('/')
                        }

                            setNav(true)
                            // window.location.reload()
                        })
            }
        }

        else {
            return setMobile1(true)
        }
    }


    const data = localStorage.getItem("usarname")
    useEffect(() => {
        setNav(false)
        setMainNav(false)
    }, [data])



    const { t } = useTranslation(); 
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])




    return (
        <div>
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container-fluid">
                    <div className="row justify-content-center signotp">

                        <div className="col-lg-4 col-md-4">
                            <div className="login-card">
                                <form method="post" className="theme-form otp-form">
                                    <h4>
                                        {t('ओटीपी पडताळणी')}
                                    </h4><br />
                                    <input type="hidden" name="csrfmiddlewaretoken" value="" />
                                    <div className="mb-4">

                                        <label>{t('ओटीपी पडताळणी')}</label><br />



                                        <span>{t('वर OTP पाठवला आहे')} xxxxxx{location.state.mobile.slice(7, 10)}</span><br /><br />

                                        <div className="row signotp">
                                            <div className="col">
                                                <OTPInput className="form-control text-center opt-text new1" style={{ display: "flex", paddingLeft:"26px" }} value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                                            </div>

                                            <div style={mobile1 ? Style.usarname2 : Style.usarname}>{t('कृपया वैध ओटीपी प्रविष्ट करा')}</div>

                                        </div>
                                        {/* <!-- <i id="otp_icon" className="fa fa-exclamation-triangle form-error-icon" aria-hidden="true"></i><span id="otp_error" className="error-msg"></span> --> */}
                                    </div>


                                    <div className="mb-3" >
                                        <span className="reset-password-link" style={{ display: "flex", fontSize: "0.8rem" }}>{t('ओटीपी मिळाला नाही का?')}&nbsp;&nbsp;
                                            {/* <button type="button" className="btn btn-success btn-xs " id="resend_btn">Resend</button> */}
                                           <ResendOTP maxTime={60} className="resend " onResendClick={() => resend()} />

                                            &nbsp;</span>
                                    </div><br />

                                    <script>

                                    </script>
                                    <div className="mb-3 text-center">
                                        {/* <!-- <button className="btn verify_btn " id="verify_btn" type="submit">Verify </button> --> */}
                                        <button className="btn verify_btn" style={{marginRight:"10px"}} disabled={loading} onClick={handleSubmit(loginOtp)}>
                                            {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "97px" }}>
                                                <RotatingLines
                                                    strokeColor="green"
                                                    strokeWidth="4"
                                                    animationDuration="0.75"
                                                    width="21"
                                                    visible={true}
                                                /></span>}
                                            {t('ओटीपी पडताळणी करा')} </button>
                                    </div>
                                    <br /><br /><br /><br />
                                </form>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    )
}

export default LoginOtp