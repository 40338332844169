import i18next from 'i18next'
import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import logo from '../images/logo.png'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'

const Contact_Us = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const common = useSelector(state => state.commonReducer)

    const location = useLocation()


    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("आमच्याशी संपर्क करा"))
    }, [])

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const { t } = useTranslation();
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])

    return (
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-5 col-sm-6">
                            <div className="d-flex justify-content-center">
                                <div className="product-name-blk"><h4 style={{ marginBottom: "30px", fontWeight: "bold", color: "green" }}>{t('आमच्याशी संपर्क करा')}</h4></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="product-description">
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-4" style={{ cursor: "pointer" }}>
                                                <div className="mb-3">
                                                    <div className="inner-dashboard-blk border-style">
                                                        <div className="row">
                                                            <div className="col-xs-2 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-12 mobile-responsive">
                                                                <div className='mobile '>
                                                                    <i class="fa-solid fa-mobile-screen mobile-icon"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-10 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-12">
                                                                <div className='mobile-margin mobile-responsive-2'>
                                                                    <h5 style={{ fontSize: "1rem" }}>{t('मोबाइल नंबर')}</h5>
                                                                    <h5 style={{ fontSize: "1rem" }}><a href="tel:18008899571" className='hover'>18008899571</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4" style={{ cursor: "pointer" }}>
                                                <div className="mb-3">
                                                    <div className="inner-dashboard-blk border-style">
                                                        <div className="row">
                                                            <div className="col-xs-2 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-12 mobile-responsive">
                                                                <div className='mobile'>
                                                                    <i class="fa-solid fa-envelope mobile-icon"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-10 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-12">
                                                                <div className='mobile-margin mobile-responsive-2'>
                                                                    <h5 style={{ fontSize: "1rem" }}>{t('ई मेल')}</h5>
                                                                    <h5 style={{ fontSize: "1rem" }}><a href="mailto:info@krishidoots.com" className='hover'> info@krishidoots.com</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4" style={{ cursor: "pointer" }}>
                                                <div className="mb-3">
                                                    <div className="inner-dashboard-blk border-style">
                                                        <div className="row">
                                                            <div className="col-xs-2 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-12 mobile-responsive">
                                                                <div className='mobile'>
                                                                    <i class="fa-solid fa-globe mobile-icon"></i>
                                                                </div>
                                                                {/* <i class="fa-solid fa-mobile-screen mobile-icon"></i> */}
                                                            </div>
                                                            <div className="col-xs-10 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-12">
                                                                <div className="mobile-margin mobile-responsive-2">
                                                                    <h5 style={{ fontSize: "1rem" }}>{t('वेबसाइट')}</h5>
                                                                    <h5 style={{ fontSize: "1rem" }}><a href="https://www.krishidoots.com/" className='hover' target="_blank">www.krishidoots.com</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ cursor: "pointer" }}>
                                                <div className="mb-3">
                                                    <div className="inner-dashboard-blk border-style">
                                                        <div className="row">
                                                            <div className="col-xs-1 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-12 mobile-responsive">
                                                                <div className='mobile'>
                                                                    <i class="fa-solid fa-location-dot mobile-icon"></i>
                                                                </div>
                                                                {/* <i class="fa-solid fa-mobile-screen mobile-icon"></i> */}
                                                            </div>
                                                            <div className="col-xs-11 col-sm-12 col-md-11 col-lg-11 col-xl-11 col-12">
                                                                <div className="mobile-margin mobile-responsive-2">
                                                                    <h5 style={{ fontSize: "1rem" }}>{t('पत्ता')}</h5>
                                                                    <h5 style={{ fontSize: "1rem" }}><a href="https://goo.gl/maps/Ltyzg5wUNnMfoYER8" className='hover' target="_blank">{t(`कृषिदूत बायो-हर्बल प्राइवेट लिमिटेड पता: गैट नंबर 37, जावल्के डिंडोरी, जनोरी एयरपोर्ट रोड, 10 वें मेल के पास (ओज़र मिग), ताल - डिंडोरी, जिला - नासिक। महाराष्ट्र - 422206.`)}</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Contact_Us