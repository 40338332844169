import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import logo from '../images/logo.png'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'

const About_Us = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const common = useSelector(state => state.commonReducer)

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const { t } = useTranslation(); 
    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])


    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("आमच्याबद्दल"))
    },[])



  return (
    <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card" style={{marginBottom:"0"}}>
                                <div className="card-body"style={{paddingBottom:"0"}}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div
                                                className="text-center">
                                                <img style={{ height: "299px" }} src={logo} className="img-fluid img-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="d-flex justify-content-center">
                                            <div className="product-name-blk"><h4 style={{fontWeight:"bold", color:"green"}}>{t('आमच्याबद्दल')}</h4></div>
                                        </div>
                                        <div className="prod-descrip ">
                                            <p>{t('कृषी विकास अॅप शेतकऱ्यांना कृषी माहिती आणि हवामान सूचना प्रदान करण्यासाठी बनवलेले आहे . हे कृषीदूत बायो हर्बल प्रायव्हेट लिमिटेड द्वारे डिझाइन आणि तयार केले गेले आहे. हे अॅप शेतकऱ्यांना नवीन शेती तंत्रांचे प्रशिक्षण देण्यासाठी, जमिनीचे आरोग्य सुधारण्यासाठी आणि असे करत असताना त्यांचे उत्पादन वाढवण्यासाठी कृषी माहिती प्रदान करते. प्रतिकूल परिस्थितींना तोंड देण्यासाठी शेतकऱ्यांना अधिक सुसज्ज बनवण्यासाठी हे अॅप एक पाऊल पुढे टाकत आहे.')}</p>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </section>
            </main>
        </div>
  )
}

export default About_Us