import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"


const initialState = {
    productGallery:[],
    loading:false,
    error:null
}


export const fetchProduct = createAsyncThunk('fetchProduct', async () => {
    const common = useSelector((state) => state.commonReducers)
    const response = await axios.post(`${Base_Url}/Get_Data`,
    {"START":"0","END":"10000","WORD":"","GET_DATA":"ProductCategory_List","ID1":"","ID2":"","ID3":"","STATUS":"","START_DATE":"","END_DATE":"","EXTRA1":"","EXTRA2":"","EXTRA3":"","LANG_ID":`${common.language_id}`}
    )

    return response.data.DATA
})

const productSlice = createSlice({
    name:'product',
    initialState,
    extraReducers:(builder) =>{ 
        builder.addCase(fetchProduct.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.loading = false;
            state.productGallery = action.payload;
        });
        builder.addCase(fetchProduct.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


export default productSlice.reducer