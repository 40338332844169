import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../images/logo.png'
import { fetchBanner } from '../redux/Banner'
import { fetchPlotlist } from '../redux/PlotList'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { animateScroll } from 'react-scroll'


const Video_List = () => {
    const navigate = useNavigate()
    const [Plot, setPlot] = useState([])
    const [videoList, setVideoList] = useState([])

    const common = useSelector(state => state.commonReducer)

    const { sidebar, setSidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)

    const { banner } = useSelector((state) => state.banner)
    // const { Plot } = useSelector((state) => state.plotlist)
    // console.log("Plot", Plot)

    const { t } = useTranslation();

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "10000",
                "WORD": "",
                "GET_DATA": "Video_List",
                "ID1": "",
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data);
            setVideoList(res.data.DATA)
        })
    }, [])

    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    }, [])


    useEffect(() => {
        axios.post(`${Base_Url}/PlotMaster/1`,
            {
                "START": "1",
                "END": "10000",
                "WORD": "ADD",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "USER_ID": `${common.usarid}`,
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data);
            setPlot(res.data.DATA)
        })

    }, [])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("व्हिडिओ यादी"))
    },[])


    const dispatch = useDispatch()
    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        dispatch(fetchBanner())
        dispatch(fetchPlotlist())
        if (window.innerWidth > 768) {
            setSidebar(true)
        }
    }, [window.innerWidth])
    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <div className="row" id="product-blk2">
                </div>
                <section id="news-knowledge" style={{marginRight:"1px"}}>
                    <div className="row">
                        {videoList.map((i) => {
                            return (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{i.Title.slice(0,10)}</h5>
                                            <div id="carouselagriculture" className="carousel slide" data-bs-ride="carousel">
                                                <i class="bi bi-play-circle position"></i>
                                                <div className="carousel-inner" style={{ marginBottom: "10px" }}>
                                                    <a href={`https://www.youtube.com/watch?v=${i.Video_URL}`} target="_blank" className="carousel-item active">
                                                        <img src={i.VIDEO_IMG === "" ? logo : i.VIDEO_IMG} style={{height:"185px"}} className="d-block w-100" alt="..." />
                                                    </a>
                                                </div>
                                                <h5>{i.REG_DATE.slice(0, 10)}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Video_List