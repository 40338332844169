import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { AuthContext } from '../useContext/AuthContext'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { animateScroll } from 'react-scroll'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import social_media from '../images/social-media.png'
import whatsapp1 from '../images/whatsapp1.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import youtube from '../images/youtube.png'


const Social_Media = () => {
  const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
  const common = useSelector(state => state.commonReducer)


  const location = useLocation()


  useEffect(() => {
    setLeftArrow(false)
    setColorChange(false)
    setnewDemo(t("सोशल मीडिया"))
  }, [])

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    })
  }, [location.pathname])

  const { t } = useTranslation();
  useEffect(() => {
    const lang = common.language
    i18next.changeLanguage(lang)
  }, [])

  return (
    <div className="main" >
      <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
        <section className="section dashboard" id="disease">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex justify-content-center">
                {/* <div className="product-name-blk"><h4 style={{ marginBottom: "30px", fontWeight: "bold", color: "green" }}>{t('आमच्याशी संपर्क करा')}</h4></div> */}
                <img className='img-fluid' style={{ width: "50%" }} src={social_media} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-5 col-sm-6">
              <div className="d-flex justify-content-center">
                <div className="product-name-blk"><h4 style={{ fontWeight: "bold", color: "green" }}>{t('आमच्याशी संपर्क करा')}</h4></div>
              </div>
            </div>
            <div className="col-lg-12 col-md-5 col-sm-6">
              <div className="d-flex justify-content-center">
                <div className="product-name-blk w-100"><h5 className='text-center' style={{ marginBottom: "30px", fontSize: "1rem", fontWeight: "500" }}>{t('आपण खालील सोशल मिडियाद्वारे आमच्याशी संपर्क साधू शकता.')}</h5></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div id="product-description">
                <div className="card info-card sales-card">
                  <div className="card-body" >
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                        <div className="mb-3">
                          <div className="inner-dashboard-blk border-style">
                          <a href="https://wa.me/9112296201" target='_blank'>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12 mobile-responsive">
                               
                                  <img src={whatsapp1} className='img-fluid padding-set' style={{ position: "relative", top: "-3px" }} alt="" />
                               
                              </div>
                              <div className="col-xs-10 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                <div className=' mobile-responsive-2'>
                                  <h5>Whatsapp</h5>
                                </div>
                              </div>
                            </div>
                             </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                        <div className="mb-3">
                          <div className="inner-dashboard-blk border-style">
                          <a href="https://www.facebook.com/krishidootagri" target='_blank'>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12 mobile-responsive">
                                  <img src={facebook} className='img-fluid padding-set' style={{ position: "relative", top: "-3px" }} alt="" />
                              </div>
                              <div className="col-xs-10 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                <div className=' mobile-responsive-2'>
                                  <h5>Facebook</h5>
                                </div>
                              </div>
                            </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                        <div className="mb-3">
                          <div className="inner-dashboard-blk border-style">
                          <a href="https://www.instagram.com/krishidoot_agri/" target='_blank'>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12 mobile-responsive">
                                  <img src={instagram} className='img-fluid padding-set' style={{ position: "relative", top: "-3px" }} alt="" />
                               
                              </div>
                              <div className="col-xs-10 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                <div className=' mobile-responsive-2'>
                                  <h5>Instagram</h5>
                                </div>
                              </div>
                            </div>
                             </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-6" style={{ cursor: "pointer" }}>
                        <div className="mb-3">
                          <div className="inner-dashboard-blk border-style">
                            <a href="https://www.youtube.com/channel/UCopt4hKFUTtGwQJfu8TpoBg" target='_blank'>
                              <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12 mobile-responsive">
                                  <img src={youtube} className='img-fluid padding-set' style={{ position: "relative", top: "-3px" }} alt="" />
                                </div>
                                <div className="col-xs-10 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-12" style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                  <div className=' mobile-responsive-2'>
                                    <h5>Youtube</h5>
                                  </div>
                                </div>
                              </div>
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </div >
  )
}

export default Social_Media