import React, { Suspense } from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/routing/App';
import AuthContextProvider from './useContext/AuthContext';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react'; 
import { StyledEngineProvider } from '@mui/material/styles';
import './component/i18n';





const root = ReactDOM.createRoot(document.getElementById('root'));
// Learn more about service workers: https://cra.link/PWA

let persistStores = persistStore(store)


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStores}>
        <StyledEngineProvider injectFirst>
          <AuthContextProvider>
            <ToastContainer />
            <Suspense fallback={<div>Loading...</div>}>
              <App />
            </Suspense>
          </AuthContextProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();



