import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import produc_gallery2 from '../images/produc-gallery2.jpg'
import { AuthContext } from '../useContext/AuthContext'
import logo from '../images/logo.png'
import parse from 'html-react-parser'
import  demo from  '../images/vector_img.jpg'
import { useEffect } from 'react'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'

const Schedule_Details = () => {
    const{sidebar, setLeftArrow, setColorChange, setnewDemo} = useContext(AuthContext)

    const location = useLocation()
    console.log("location.state",location.state)

    const Styles = {
        sidebar1:{
            marginLeft:"300px"
        },
        sidebar2:{
            marginLeft:""
        }
    }

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("वेळापत्रक तपशील"))
    },[])

    return (
        <div className="main">
            <main id="main"  className={sidebar ? 'sidebar1' : 'sidebar2'}>
            <section className="section dashboard" id="disease">
                    <div className="row">
                    <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body text-center ">
                                        <div>
                                            <div className="product-name-blk "><h4>{location.state.SCHEDULE_DAY} Days Work</h4></div>
                                        </div>                                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div className="text-center">
                                            <img src={location.state.SCHEDULE_IMAGE === "" ? demo : location.state.SCHEDULE_IMAGE} className="img-fluid img-center" />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body ">
                                        <div className='text-center'>
                                            <div className="product-name-blk"><h4>{location.state.TITLE}</h4></div>
                                        </div>                          
                                        <div className="prod-descrip ">
                                            <p>{parse(location.state.DESCRIPTION)}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Schedule_Details