import i18next from 'i18next'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import logo from '../images/logo.png'
import { AuthContext } from '../useContext/AuthContext'
import { animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'

const Privacy_Policy = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const common = useSelector(state => state.commonReducer)

    const { t } = useTranslation(); 

    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        const lang = common.language
        i18next.changeLanguage(lang)
    },[])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("गोपनीयता धोरण"))
    },[])


    return (
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card" style={{ marginBottom: "0" }}>
                                <div className="card-body" style={{ paddingBottom: "0" }}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div
                                                className="text-center">
                                                <img style={{ height: "299px" }} src={logo} className="img-fluid img-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body" >
                                        <div className="d-flex justify-content-center ">
                                            <div className="product-name-blk "><h4 className='text-center' style={{fontWeight:"bold", color:"green"}}>{t('गोपनीयता धोरण')}</h4></div>
                                        </div>
                                        <div className="prod-descrip ">
                                            <h4 style={{ fontWeight: "bold", color: "#d5707e" }}>{t('आमची पायाभूत सुविधा')}</h4>
                                            <p>
                                            {t('उत्पादन प्रकल्प आधुनिक यंत्रसामग्री, प्रयोगशाळा आणि अत्याधुनिक संशोधन आणि विकास विभागासह पूर्णपणे सुसज्ज आहे. आमच्या संशोधन सुविधा (लॅब आणि आर आणि डी) DSIR (वैज्ञानिक आणि औद्योगिक संशोधन विभाग) आणि NABL मानकांद्वारे मान्यताप्राप्त आहेत. प्रगत तंत्रज्ञानाचा वापर करून आधुनिक परिस्थितीत अनेक उत्पादनांची निर्मिती प्लांटमध्ये केली जाते. आम्ही आमच्या परिसराच्या विस्तीर्ण जागेवर एक अत्याधुनिक आणि सुसज्ज पायाभूत सुविधा विकसित केल्या आहेत. उत्पादनांना चांगल्या कामगिरीच्या स्थितीत ठेवण्यासाठी, आमची तांत्रिक तज्ञांची विशेष टीम आमची उपकरणे नियमितपणे अपग्रेड आणि देखरेख करतात. अत्याधुनिक मशीन्सचा वापर करून, आम्ही वचनबद्ध कालावधीत दर्जेदार उत्पादनांसह मोठ्या ऑर्डर पूर्ण करू शकतो.')}
                                                {t('आम्ही शेतकर्‍यांना सेंद्रिय निविष्ठा देऊन आणि समुदाय आणि पर्यावरणीय आरोग्याचा प्राधान्याने विचार करून सेंद्रिय आणि आरोग्यदायी अन्नाचे उत्पादन करून "पृथ्वी माता" बद्दल कृतज्ञता व्यक्त करतो.')}
                                            </p>
                                        </div>
                                        <div className="prod-descrip ">
                                            <h4 style={{ fontWeight: "bold", color: "#d5707e", marginTop:"10px" }}>{t('आमची गुणवत्ता हमी')}</h4>
                                            <p style={{marginTop:"0"}}>
                                            {t('उत्पादन प्रकल्प आधुनिक यंत्रसामग्री, प्रयोगशाळा आणि अत्याधुनिक संशोधन आणि विकास विभागासह पूर्णपणे सुसज्ज आहे. आमच्या संशोधन सुविधा (लॅब आणि आर आणि डी) DSIR (वैज्ञानिक आणि औद्योगिक संशोधन विभाग) आणि NABL मानकांद्वारे मान्यताप्राप्त आहेत. प्रगत तंत्रज्ञानाचा वापर करून आधुनिक परिस्थितीत अनेक उत्पादनांची निर्मिती प्लांटमध्ये केली जाते. आम्ही आमच्या परिसराच्या विस्तीर्ण जागेवर एक अत्याधुनिक आणि सुसज्ज पायाभूत सुविधा विकसित केल्या आहेत. उत्पादनांना चांगल्या कामगिरीच्या स्थितीत ठेवण्यासाठी, आमची तांत्रिक तज्ञांची विशेष टीम आमची उपकरणे नियमितपणे अपग्रेड आणि देखरेख करतात. अत्याधुनिक मशीन्सचा वापर करून, आम्ही वचनबद्ध कालावधीत दर्जेदार उत्पादनांसह मोठ्या ऑर्डर पूर्ण करू शकतो.')}
                                                {t('आम्ही शेतकर्‍यांना सेंद्रिय निविष्ठा देऊन आणि समुदाय आणि पर्यावरणीय आरोग्याचा प्राधान्याने विचार करून सेंद्रिय आणि आरोग्यदायी अन्नाचे उत्पादन करून "पृथ्वी माता" बद्दल कृतज्ञता व्यक्त करतो.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Privacy_Policy