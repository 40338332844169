import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../images/logo.png'
import { AuthContext } from '../useContext/AuthContext'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'


const Notifications_Details = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const location = useLocation()

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("नोटिफिकेशन्स तपशील"))
    }, [])


    return (
        <div className="main" >
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <div
                                                className="text-center">
                                                <img style={{ height: "247px", borderRadius: "8px" }} src={location.state.NOTI_IMAGE === "" ? logo : location.state.NOTI_IMAGE} className="img-fluid img-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="product-description">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-name-blk">
                                                        <h4>{location.state.NOTI_TITLE}</h4>
                                                    </div>
                                                </div>
                                                <div className="prod-descrip ">
                                                    <p style={{ marginTop: "0" }}>{location.state.DESCRIPTION}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                                <p style={{ marginTop: "0" }}>{location.state.REG_DATE}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Notifications_Details