import React, { useContext } from 'react'
import pest from '../images/pest.png'
import disease_img from '../images/disease-img.jpg'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import parse from 'html-react-parser';
import { fetchDesease } from '../redux/Desease'
import { RotatingLines } from 'react-loader-spinner'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import vector_img from '../images/vector_img.jpg'
import { t } from 'i18next'
import { animateScroll } from 'react-scroll'

const Desease_control_list = () => {
    const { sidebar, setLeftArrow, setColorChange, setnewDemo } = useContext(AuthContext)
    const [Desease, setDesease] = useState([])

    const common = useSelector(state => state.commonReducer)

    // const { Desease, loading } = useSelector((state) => state.Desease)
    // console.log("Desease", Desease);

    const location = useLocation()
    console.log("location", location.state);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])

    const dispatch = useDispatch()

    useEffect(() => {
        axios.post(`${Base_Url}/Get_Data`,
            {
                "START": "1",
                "END": "100",
                "WORD": "",
                "GET_DATA": "DiseaseControl_List",
                "ID1": `${location.state.CROP_ID}`,
                "ID2": "",
                "ID3": "",
                "STATUS": "",
                "START_DATE": "",
                "END_DATE": "",
                "EXTRA1": "",
                "EXTRA2": "",
                "EXTRA3": "",
                "LANG_ID": `${common.language_id}`
            }
        ).then((res) => {
            console.log(res.data.DATA);
            setDesease(res.data.DATA)

        })
    }, [])

    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        setLeftArrow(false)
        setColorChange(false)
        setnewDemo(t("रोग नियंत्रण तपशील"))
    }, [])


    useEffect(() => {
        dispatch(fetchDesease())
    }, [])
    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="disease">
                    <div>
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12">

                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                                <a >
                                                    <div className="product-ouetr-blk">
                                                        {/* <div className="farm-prodct-icon-circle">
                                                                <img src={pest} className="img-fluid" width="48px" />
                                                            </div> */}
                                                        {location.state.TITLE}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
                                <div className="card info-card sales-card">
                                    <div className="card-body text-center">
                                        <h5 className='text-center'>STAGE-1</h5>
                                        <img src={location.state.PRE_IMAGE === "" ? vector_img : location.state.PRE_IMAGE} className="img-fluid carouselimage" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body text-center">
                                        <h5 className='text-center'>STAGE-2</h5>
                                        <img src={location.state.OTHER_IMAGE === "" ? vector_img : location.state.OTHER_IMAGE} className="img-fluid carouselimage" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="card info-card sales-card">
                                    <div className="card-body text-center">
                                        <h5 className='text-center'>STAGE-3</h5>
                                        <img s src={location.state.AFTER_IMAGE === "" ? vector_img : location.state.AFTER_IMAGE} className="img-fluid carouselimage" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">

                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <a >
                                                <div className="product-ouetr-blk " style={{ marginBottom: "30px" }}>
                                                    {/* <div className="farm-prodct-icon-circle">
                                                                <img src={pest} className="img-fluid" width="48px" />
                                                            </div> */}
                                                    SYMPTOMS
                                                </div>
                                                <div>
                                                    <p>{parse(location.state.SYMPTOMS)}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <a >
                                                <div className="product-ouetr-blk " style={{ marginBottom: "30px" }}>
                                                    {/* <div className="farm-prodct-icon-circle">
                                                                <img src={pest} className="img-fluid" width="48px" />
                                                            </div> */}
                                                    TRATEMENT
                                                </div>
                                                <div>
                                                    <p>{parse(location.state.TRATEMENT)}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <a >
                                                <div className="product-ouetr-blk " style={{ marginBottom: "30px" }}>
                                                    {/* <div className="farm-prodct-icon-circle">
                                                                <img src={pest} className="img-fluid" width="48px" />
                                                            </div> */}
                                                    CAUSES
                                                </div>
                                                <div>
                                                    <p>{parse(location.state.CAUSES)}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 co-sm-12 col-12">
                                            <a >
                                                <div className="product-ouetr-blk " style={{ marginBottom: "30px" }}>
                                                    {/* <div className="farm-prodct-icon-circle">
                                                                <img src={pest} className="img-fluid" width="48px" />
                                                            </div> */}
                                                    PREVENTIVE
                                                </div>
                                                <div>
                                                    <p>{parse(location.state.PREVENTIVE)}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Desease_control_list