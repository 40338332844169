import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { Base_Url } from "../Base_Url/Base_Url"


const initialState = {
    Plot:[],
    loading:false,
    error:null
}

// const usarid = localStorage.getItem('usarid')
// const common = useSelector(state => state.commonReducer)


export const fetchPlotlist = createAsyncThunk('fetchplotlist', async () => {
    const common = useSelector(state => state.commonReducer)

    const response = await axios.post(`${Base_Url}/PlotMaster/1`,
    {
        "START":"1",
        "END":"10000",
        "WORD":"ADD",
        "EXTRA1":"",
        "EXTRA2":"",
        "EXTRA3":"",
        "USER_ID":`${common.usarid}`,
        "LANG_ID":`${common.language_id}`
        }
    )

    return response.data.DATA
})


const plotListSlice = createSlice({
    name:'plotlist',
    initialState,
    extraReducers: (builder) =>{
        builder.addCase(fetchPlotlist.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchPlotlist.fulfilled, (state, action) => {
            state.loading = true;
            state.Plot = action.payload;
        });
        builder.addCase(fetchPlotlist.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export default plotListSlice.reducer