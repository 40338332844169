import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'
import { resetState, updateState } from '../redux/Persist'
import kannada1 from '../images/kannada.png'
import Hindi1 from '../images/Hindi1.jpg'
import English1 from '../images/English1.jpg'
import Marathi from '../images/Marathi1.jpg'
import { Base_Url } from '../Base_Url/Base_Url'
import { AuthContext } from '../useContext/AuthContext'
import { useTranslation } from 'react-i18next'
import whatsapp from '../images/whatsapp.png'
import i18next from 'i18next'
import axios from 'axios'
import Lab from './Lab'
import Dashboard from './Dashboard'
import About_Us from './About_Us'
import social_media from '../images/social-media-2.png'



const data = localStorage.getItem("usarname")

const Navbar = (props) => {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([])
  const [notifications1, setNotifications1] = useState([])
  const [mobileLeftArrow, setMobileLeftArrow] = useState(false)
  const [mobnav, setMobNav] = useState(true)
  const [background1, setBackground1] = useState(false)
  const [background2, setBackground2] = useState(false)
  const [background3, setBackground3] = useState(false)


  const { nav, sidebar, setNav, setSidebar, setLeftArrow, anchorRef, mainNav, setMainNav, leftArrow, colorchange, newDemo2, setColorChange } = useContext(AuthContext)


  const common = useSelector(state => state.commonReducer)

  const dispatch = useDispatch()

  useEffect(() => {
    if (window.location.origin + "/" == window.location.href) {
      return setColorChange(true)
    }
  }, [])

  useEffect(() => {
    axios.post(`${Base_Url}/Get_Data`,
      { "START": 0, "END": 10000, "WORD": "", "GET_DATA": "Get_Notification", "ID1": `${common.usarid}`, "ID2": "", "ID3": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}`, "STATUS": "" }
    ).then((res) => {
      console.log(res.data.DATA);
      setNotifications(res.data.DATA)
      setNotifications1(res.data.DATA[0].UNSEEN_COUNT)
    })
  }, [notifications, notifications1])




  const Styles1 = {
    color1: {
      background: "#fff"
    },
    color2: {
      background: "#2BB741"
    }
  }

  const colorChange = "#ffa5007a";





  const marathi = () => {
    setMainNav(true)
    dispatch(updateState({
      language: "mr",
      language_id: "1"
    }))
    navigate('/')
  }

  const hindi = () => {
    setMainNav(true)
    dispatch(updateState({
      language: "hi",
      language_id: "2"
    }))
    // window.location.reload()
    navigate('/')
  }

  // const kannada = () => {
  //   setMainNav(true)
  //   dispatch(updateState({
  //     language: "ka",
  //     language_id: "3"
  //   }))/
  //   navigate("/")
  // }

  const English = () => {
    setMainNav(true)
    dispatch(updateState({
      language: "en",
      language_id: "3"
    }))
    // navigate("/")
    navigate('/')
  }

  useEffect(() => {
    dispatch(updateState())
  }, [])

  useEffect(() => {
    if (common.language_id == "1") {
      setBackground1(true);
      setBackground2(false);
      setBackground3(false);
    } else if (common.language_id == "2") {
      setBackground1(false);
      setBackground2(true);
      setBackground3(false);
    } else if (common.language_id == "3") {
      setBackground1(false);
      setBackground2(false);
      setBackground3(true);
    }
  }, [common.language_id])

  const logout = () => {
    dispatch(resetState())
    navigate('/login')
  }

  const Styles = {
    sidebar1: {
      left: "-300px"
    },
    sidebar2: {
      left: ""
    }
  }


  useEffect(() => {
    if (common.usarname) {
      setMainNav(true)
    }


    if (window.innerWidth > 768) {
      setSidebar(true)
      setMobileLeftArrow(true)
      setMobNav(false)
    }
  }, [])

  const showNotifications = (i) => {
    axios.post(`${Base_Url}/Notification`,
      { "NOTI_ID": `${i.NOTI_ID}`, "USER_ID": `${common.usarid}`, "TASK": "View", "EXTRA1": "", "EXTRA2": "" }
    )
    navigate('/notifications_detail', { state: i })
  }




  const prevOpen = useRef(sidebar);
  useEffect(() => {
    if (prevOpen.current === true && sidebar === true) {
      anchorRef.current.focus();
    }
    prevOpen.current = sidebar
  }, [sidebar])




  const { t } = useTranslation();
  useEffect(() => {
    const lang = common.language
    i18next.changeLanguage(lang)
  }, [])


  return (
    <div >
      {mainNav ? (
        <>

          {mobnav ?
            (
              <header id="header" className="header fixed-top d-flex align-items-center main-page d-lg-none" style={colorchange ? Styles1.color1 : Styles1.color2}>

                {nav ? (

                  <>
                    <div className="d-flex align-items-center justify-content-between" >

                      {leftArrow ? (
                        <>
                          <i className="bi bi-list toggle-sidebar-btn togglebutton" ref={anchorRef} onClick={() => setSidebar(!sidebar)}></i>
                          <a onClick={() => navigate('/')} style={{ cursor: "pointer" }} className="logo d-flex align-items-center" >
                            {/* <img src={logo} alt="" style={{ marginLeft: "10px" }} /> */}
                            <p style={{ marginTop: "0", marginLeft: "10px" }}><span style={{ color: "#ff4700b0 " }}>KRISHI</span><span style={{ color: "#00800094;" }}>VIKAS</span></p>
                          </a>
                        </>
                      ) : (
                        <>
                          {mobileLeftArrow ? (
                            <a onClick={() => navigate('/')} style={{ cursor: "pointer" }} className="logo d-flex align-items-center" >
                              <img src={logo} alt="" style={{ marginLeft: "25px" }} />
                              <p style={{ marginTop: "0" }}><span style={{ color: "#ff4700b0 " }}>KRISHI</span><span>VIKAS</span></p>
                            </a>

                          ) : (
                            <>
                              <div className='mob-navbar'>
                                <a><i class="bi bi-arrow-left" style={{ marginLeft: "10px", color: "#fff", fontWeight: "bold" }} onClick={() => navigate(-1)}></i></a>
                                <div class=""><h6 style={{ fontSize: "20px" }}>{newDemo2}</h6></div>
                              </div>

                            </>
                          )}
                        </>
                      )}


                    </div>


                    {leftArrow && <nav class="header-nav ms-auto">
                      <ul class="d-flex align-items-center">
                        {/* 
                    <li class="nav-item d-block d-lg-none">
                      <a class="nav-link nav-icon search-bar-toggle " href="#">
                        <i class="bi bi-search"></i>
                      </a>
                    </li> */}
                        <a class="nav-link nav-icon" href="https://wa.me/9112296201" >
                          <img src={whatsapp} alt="" style={{ width: "30px" }} />
                        </a>
                        <a class="nav-link nav-icon" href="tel:18008899571" >
                          <i class="fa-solid fa-phone-volume"></i>
                        </a>
                        <li class="nav-item dropdown">

                          <a class="nav-link nav-icon" onClick={() => navigate('/notification')}>
                            <i class="bi bi-bell"></i>
                            {notifications1 > 0 && <span class="badge bg-primary badge-number">{notifications1}</span>}

                          </a>

                          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                            {notifications1 && <>
                              <li class="dropdown-header">
                                You have {notifications1} new notifications
                                <a style={{ cursor: "pointer" }} onClick={() => navigate('/notification')}><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                              </li>
                            </>}
                            <li>
                              <hr class="dropdown-divider" />
                            </li>
                            {notifications.slice(0, 4).map((i) => {
                              return (
                                <>
                                  {i.IS_VIEW === "No" &&
                                    <>
                                      <div className="row" onClick={() => showNotifications(i)}>
                                        <div className="col-3 notifications-img">
                                          <img src={i.NOTI_IMAGE === "" ? logo : i.NOTI_IMAGE} alt="" style={{ width: "50px" }} />
                                        </div>
                                        <div className="col-9">
                                          <li class="notification-item" style={{ cursor: "pointer" }}>


                                            <div>
                                              <h4>{i.NOTI_TITLE}</h4>
                                              <p style={{ marginTop: "0" }}>{i.DESCRIPTION}</p>

                                            </div>

                                          </li>
                                        </div>
                                      </div>
                                      <li>
                                        <hr class="dropdown-divider " />
                                      </li>
                                    </>
                                  }
                                </>
                              )
                            })}

                            <li>
                              <hr class="dropdown-divider" />
                            </li>
                            <li class="dropdown-footer">
                              <a style={{ cursor: "pointer" }} onClick={() => navigate('/notification')}>Show all notifications</a>
                            </li>

                          </ul>

                        </li>


                        <li class="nav-item dropdown pe-3">

                          <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <span class="d-none d-md-block dropdown-toggle ps-2">{common.usarname}</span>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li class="dropdown-header">
                              <h6>{common.usarname}</h6>
                              {/* <span>Web Designer</span> */}
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a class="dropdown-item d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate('/profile')}>
                                <i class="bi bi-person"></i>
                                <span>{t('माझी प्रोफाईल')}</span>
                              </a>
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a class="dropdown-item d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => dispatch(resetState())}>
                                <i class="bi bi-box-arrow-right"></i>
                                <span>{t('बाहेर पडा')}</span>
                              </a>
                            </li>

                          </ul>
                        </li>

                      </ul>
                    </nav>}
                  </>

                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <a className="logo d-flex align-items-center">
                        <img src={logo} alt="" />
                      </a>
                    </div>
                    <nav className="header-nav ms-auto">
                      <ul className="landing-menu nav nav-pills">
                        <li className="nav-item"><a className="nav-link" onClick={() => navigate('/login')} style={{ cursor: "pointer" }}>{t('साइन इन करा')}</a></li>
                        {/* <li className="nav-item"><a className="nav-link" data-bs-toggle="modal" data-bs-target=".language-modal" style={{ cursor: "pointer" }} onclick="showLanguage()">English</a></li> */}
                        <li className="nav-item"><a className="nav-link" onClick={() => navigate('/Register')} style={{ cursor: "pointer" }}>{t('साइनअप')}</a></li>
                      </ul>
                    </nav>
                  </>

                )}

              </header>) : (
              <header id="header" className="header fixed-top d-flex align-items-center main-page" style={colorchange ? Styles1.color1 : Styles1.color1}>

                {nav ? (

                  <>
                    <div className="d-flex align-items-center justify-content-between" >

                      {leftArrow ? (
                        <>
                          <i className="bi bi-list toggle-sidebar-btn togglebutton" ref={anchorRef} onClick={() => setSidebar(!sidebar)}></i>
                          <a onClick={() => navigate('/')} style={{ cursor: "pointer" }} className="logo d-flex align-items-center" >
                            <img src={logo} alt="" style={{ marginLeft: "10px" }} />
                            <p style={{ marginTop: "0" }}><span style={{ color: "#ff4700b0 " }}>KRISHI</span><span style={{ color: "#00800094;" }}>VIKAS</span></p>
                          </a>
                        </>
                      ) : (
                        <>
                          {mobileLeftArrow ? (
                            <a onClick={() => navigate('/')} style={{ cursor: "pointer" }} className="logo d-flex align-items-center" >
                              <img src={logo} alt="" style={{ marginLeft: "25px" }} />
                              <p style={{ marginTop: "0" }}><span style={{ color: "#ff4700b0 " }}>KRISHI</span><span>VIKAS</span></p>
                            </a>

                          ) : (
                            <>
                              <div className='mob-navbar'>
                                <a><i class="bi bi-arrow-left" style={{ marginLeft: "10px", color: "#fff", fontWeight: "bold" }} onClick={() => navigate(-1)}></i></a>
                                <div class=""><h6 style={{ fontSize: "20px" }}>{newDemo2}</h6></div>
                              </div>

                            </>
                          )}
                        </>
                      )}


                    </div>


                    {leftArrow && <nav class="header-nav ms-auto">
                      <ul class="d-flex align-items-center">
                        {/* 
<li class="nav-item d-block d-lg-none">
<a class="nav-link nav-icon search-bar-toggle " href="#">
<i class="bi bi-search"></i>
</a>
</li> */}
                        <a class="nav-link nav-icon" href="tel:https://wa.me/9112296201" >
                          <img src={whatsapp} alt="" style={{ width: "30px" }} />
                        </a>
                        <a class="nav-link nav-icon" href="tel:18008899571" >
                          <i class="fa-solid fa-phone-volume"></i>

                        </a>
                        <li class="nav-item dropdown">

                          {notifications1 === 0 ? (
                            <a class="nav-link nav-icon" style={{ cursor: "pointer" }} onClick={() => navigate('/notification')}>
                              <i class="bi bi-bell"></i>
                              {/* <span class="badge bg-primary badge-number">{notifications1}</span> */}
                            </a>
                          ) : (
                            <>
                              <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i class="bi bi-bell"></i>
                                <span class="badge bg-primary badge-number">{notifications1}</span>
                              </a>
                              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                {notifications1 && <>
                                  <li class="dropdown-header">
                                    You have {notifications1} new notifications
                                    <a style={{ cursor: "pointer" }} onClick={() => navigate('/notification')}><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                  </li>
                                </>}
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                {notifications.slice(0, 4).map((i) => {
                                  return (
                                    <>
                                      {i.IS_VIEW === "No" &&
                                        <>
                                          <div className="row" onClick={() => showNotifications(i)}>
                                            <div className="col-3 notifications-img">
                                              <img src={i.NOTI_IMAGE === "" ? logo : i.NOTI_IMAGE} alt="" style={{ width: "50px" }} />
                                            </div>
                                            <div className="col-9">
                                              <li class="notification-item" style={{ cursor: "pointer" }}>


                                                <div>
                                                  <h4>{i.NOTI_TITLE}</h4>
                                                  <p style={{ marginTop: "0" }}>{i.DESCRIPTION.slice(0, 20)}</p>

                                                </div>

                                              </li>
                                            </div>
                                          </div>
                                          <li>
                                            <hr class="dropdown-divider " />
                                          </li>
                                        </>
                                      }
                                    </>
                                  )
                                })}

                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li class="dropdown-footer">
                                  <a style={{ cursor: "pointer" }} onClick={() => navigate('/notification')}>Show all notifications</a>
                                </li>

                              </ul>
                            </>
                          )}





                        </li>


                        <li class="nav-item dropdown pe-3">

                          <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <span class="d-none d-md-block dropdown-toggle ps-2">{common.usarname}</span>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li class="dropdown-header">
                              <h6>{common.usarname}</h6>
                              {/* <span>Web Designer</span> */}
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a class="dropdown-item d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate('/profile')}>
                                <i class="bi bi-person"></i>
                                <span>{t('माझी प्रोफाईल')}</span>
                              </a>
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a class="dropdown-item d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => dispatch(resetState())}>
                                <i class="bi bi-box-arrow-right"></i>
                                <span>{t('बाहेर पडा')}</span>
                              </a>
                            </li>

                          </ul>
                        </li>

                      </ul>
                    </nav>}
                  </>

                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <a className="logo d-flex align-items-center">
                        <img src={logo} alt="" />
                      </a>
                    </div>
                    <nav className="header-nav ms-auto">
                      <ul className="landing-menu nav nav-pills">
                        <li className="nav-item"><a className="nav-link" onClick={() => navigate('/login')} style={{ cursor: "pointer" }}>{t('साइन इन करा')}</a></li>
                        {/* <li className="nav-item"><a className="nav-link" data-bs-toggle="modal" data-bs-target=".language-modal" style={{ cursor: "pointer" }} onclick="showLanguage()">English</a></li> */}
                        <li className="nav-item"><a className="nav-link" onClick={() => navigate('/Register')} style={{ cursor: "pointer" }}>{t('साइनअप')}</a></li>
                      </ul>
                    </nav>
                  </>

                )}

              </header>
            )}

          <aside id="sidebar" className='sidebar' style={sidebar ? Styles.sidebar2 : Styles.sidebar1} >

            <ul className="sidebar-nav" id="sidebar-nav">

              <li className="nav-item">
                <a className="nav-link " style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-grid"></i>
                  <span>{t('होम')}   </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/product_enquiry_list')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-basket2"></i>
                  <span>{t('उत्पादन चौकशी')} </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/lab_enquiry_list')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-thermometer-sun"></i>
                  <span>{t('प्रयोगशाळा चौकशी')}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/profile')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-person"></i>
                  <span>{t('प्रोफाइल')} </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/aboutus')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-building"></i>
                  <span>{t('आमच्याबद्दल')}  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/social_media')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <img src={social_media} className='img-fluid grayscale' style={{ width: "9%" }} alt="" />
                  <span style={{ marginLeft: "10px" }}>{t('सोशल मीडिया')}  </span>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer", border: "none" }} data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" onClick={() => {
                  navigate('/aboutus')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-shuffle"></i>
                  <span>{t('भाषा बदला')}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/contact_us')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-thermometer-sun"></i>
                  <span>{t('आमच्याशी संपर्क करा')}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i className="bi bi-share"></i>
                  <span>{t('ऍप डाऊनलोड करा')}</span>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  navigate('/privacy_policy')
                  if (window.innerWidth < 768) {
                    return setSidebar(false)
                  }
                }}>
                  <i class="bi bi-file-text"></i>
                  <span>{t('गोपनीयता धोरण')}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" style={{ cursor: "pointer" }} onClick={() => {
                  setSidebar(false)
                  logout()
                }}>
                  <i class="bi bi-box-arrow-right"></i>
                  <span>{t('बाहेर पडा')}</span>
                </a>
              </li>
            </ul>
          </aside>
        </>
      ) : (
        <div></div>
      )
      }
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ width: "-webkit-fill-available" }}>
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <section id="language-page" className="section register d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container-fluid">
                <div className="row justify-content-center">

                  <div className="col-lg-6 col-md-6">
                    <section className="demo-section section-center" id="home">
                      <div className="title">
                        <h2>Choose Language</h2><br />
                        <h2 className="text-center">भाषा चुनिए</h2>
                      </div>
                      <div className="custom-container">
                        <div className="row demo-block demo-imgs">
                          <div className="col-lg-12 col-sm-12">
                            <ul className="language-text">

                              <li id="hindi_2" data-bs-dismiss="modal" className="lang-list" style={{ cursor: "pointer", background: background1 ? colorChange : "" }} onClick={() => marathi()}>
                                <a className="">
                                  <img className="lang-img" src={Marathi} alt="" />
                                  <span className="lang-text">मराठी</span>
                                </a>
                              </li>

                              <li id="hindi_1" data-bs-dismiss="modal" className="lang-list" style={{ cursor: "pointer", background: background2 ? colorChange : "" }} onClick={() => hindi()}>
                                <a className="" style={{ cursor: "pointer" }}>
                                  <img className="lang-img" src={Hindi1} alt="" />
                                  <span className="lang-text">हिंदी</span>
                                </a>
                              </li>


                              <li id="hindi_3" data-bs-dismiss="modal" className="lang-list" style={{ cursor: "pointer", background: background3 ? colorChange : "" }} onClick={() => English()}>
                                <a className="" style={{ cursor: "pointer" }} >
                                  <img className="lang-img" src={English1} alt="" />
                                  <span className="lang-text">English</span>
                                </a>
                              </li>

                            </ul>

                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Navbar