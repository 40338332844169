import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import kannada1 from '../images/kannada.png'
import Hindi1 from '../images/Hindi1.jpg'
import Marathi from '../images/Marathi1.jpg'
import { AuthContext } from '../useContext/AuthContext'
import English1 from '../images/English1.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { updateState } from '../redux/Persist'

const Language = () => {
    const navigate = useNavigate()
    const {setMainNav} = useContext(AuthContext)
    const [background1, setBackground1] = useState(false)
    const [background2, setBackground2] = useState(false)
    const [background3, setBackground3] = useState(false)

    const common = useSelector((state) => state.commonReducer)

    const dispatch = useDispatch()

    const colorChange = "#ffa5007a";

    useEffect(() => {
        if(common.language_id == "1"){
            return setBackground1(true)
        }else if (common.language_id == "2"){
            return setBackground2(true)
        }else if(common.language_id == "3"){
            return setBackground3(true)
        }
    },[])

    const marathi = () => {        
        setMainNav(true)
        dispatch(updateState({
            language: "mr",
            language_id:"1"
        }))
        navigate('/login')
    }
    const hindi = () => {        
        setMainNav(true)
        dispatch(updateState({
            language: "hi",
            language_id:"2"
        }))
        navigate('/login')
    }
    const English = () => {
        setMainNav(true)
        dispatch(updateState({
            language: "en",
            language_id:"3"
        }))
        navigate('/login')
    }
    const kannada = () => {        
        setMainNav(true)
        dispatch(updateState({
            language: "ka",
            language_id:"3"
        }))
        navigate('/login')
    }
    useEffect(() => {
        setMainNav(false)
    })
    return (
        <div>
            <section id="language-page" className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-lg-6 col-md-6">
                            <section className="demo-section section-center" id="home">
                                <div className="title">
                                    <h2>Choose Language</h2><br />
                                    <h2 className="text-center">भाषा चुनिए</h2>
                                </div>
                                <div className="custom-container">
                                    <div className="row demo-block demo-imgs">
                                        <div className="col-lg-12 col-sm-12">
                                            <ul className="language-text">
                                                <li id="hindi_2" className="lang-list" style={{cursor:"pointer", background:background1 ? colorChange : ""}} onClick={() => marathi()}>
                                                    <a className=""  >
                                                        <img className="lang-img" src={Marathi} alt="" />
                                                        <span className="lang-text">मराठी</span>
                                                    </a>
                                                </li>
                                                <li id="hindi_1" className="lang-list" style={{cursor:"pointer", background:background2 ? colorChange : ""}} onClick={() =>hindi()}>
                                                    <a className=""  style={{cursor:"pointer"}}>
                                                        <img className="lang-img" src={Hindi1} alt="" />
                                                        <span className="lang-text">हिंदी</span>
                                                    </a>
                                                </li>
                                                {/* 
                                                <li id="hindi_3" className="lang-list" style={{cursor:"pointer"}} onClick={() => kannada()}>
                                                    <a className="" style={{cursor:"pointer"}} >
                                                        <img className="lang-img" src={kannada1} alt="" />
                                                        <span className="lang-text"> ಕನ್ನಡ</span>
                                                    </a>
                                                </li> */}
                                                <li id="hindi_3" className="lang-list" style={{cursor:"pointer", background:background3 ? colorChange : ""}} onClick={() => English()}>
                                                    <a className="" style={{cursor:"pointer"}} >
                                                        <img className="lang-img" src={English1} alt="" />
                                                        <span className="lang-text">English</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Language