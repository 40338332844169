import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../useContext/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCrop } from '../redux/Crop'
import { animateScroll } from 'react-scroll'
import logo from '../images/logo.png'
import axios from 'axios'
import { Base_Url } from '../Base_Url/Base_Url'
import not_found from '../images/not-found.png'
import { t } from 'i18next'


const DeseasControl = () => {
    const navigate = useNavigate()

    const { sidebar, setLeftArrow, setnewDemo, setColorChange } = useContext(AuthContext)
    // const [loading, setLoading] = useState(false)
    const [croplist, setCropList] = useState([])
    const [subCroplist, setSubCropList] = useState([])

    const location = useLocation()

    const common = useSelector((state) => state.commonReducer)

    useEffect(() => {
        axios.post(`${Base_Url}/CropCategory`,
            { "START": "1", "END": "10000", "WORD": "", "EXTRA1": "", "EXTRA2": "", "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data);
            setCropList(res.data.DATA)
        })

    }, [])

    const cropList = (i) => {
        axios.post(`${Base_Url}/GET_DATA`,
            { "START": "0", "END": "10000", "WORD": "", "GET_DATA": "Crop_List", "ID1": "", "ID2": "", "ID3": "", "STATUS": "", "START_DATE": "", "END_DATE": "", "EXTRA1": "", "EXTRA2": `${i.CROPCAT_ID}`, "EXTRA3": "", "LANG_ID": `${common.language_id}` }
        ).then((res) => {
            console.log(res.data);
            setSubCropList(res.data.DATA)
        })
    }

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 0
        })
    }, [location.pathname])
    const { crop, loading } = useSelector((state) => state.crop)
    console.log("crop", crop)

    const dispatch = useDispatch()
    const Styles = {
        sidebar1: {
            marginLeft: "300px"
        },
        sidebar2: {
            marginLeft: ""
        }
    }

    useEffect(() => {
        setLeftArrow(false)
        setnewDemo(t("रोग नियंत्रण"))
        setColorChange(false)
    }, [])

    const selectPlot = (item) => {
        navigate('/disease_control', { state: item })
    }

    useLayoutEffect(() => {
        dispatch(fetchCrop())
    }, [])
    return (
        <div className="main">
            <main id="main" className={sidebar ? 'sidebar1' : 'sidebar2'}>
                <section className="section dashboard" id="faq">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {cropList.length === 0 &&
                                <div className='text-center'>
                                    <img src={not_found} className="img-fluid" alt="" />
                                </div>}
                            {croplist.map((i) => {
                                return (
                                    <div className="card info-card sales-card">
                                        <div className="card-body" >
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item" >
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <div style={{ display: "flex" }}>
                                                            <img src={i.CROPCAT_IMAGE} alt="" className='img-class' />
                                                            <button className="accordion-button" data-bs-target={"#collapseOne" + i.CROPCAT_ID} type="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="collapseOne" onClick={() => cropList(i)}>
                                                                {i.CROPCAT_NAME}
                                                            </button>
                                                        </div>
                                                    </h2>
                                                    <div className="accordion-collapse collapse" aria-labelledby="headingOne" id={"collapseOne" + i.CROPCAT_ID} data-bs-parent="#accordionExample">
                                                        <div className="accordion-body" style={{ cursor: "pointer" }} >

                                                            <div className="row" id="product-tab">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    {subCroplist.length === 0 &&
                                                                        <div className='text-center'>
                                                                            <img src={not_found} className="img-fluid" alt="" />
                                                                        </div>}
                                                                    {subCroplist.map((item) => {
                                                                        return (
                                                                            <div className="card mb-3">
                                                                                <div className="card-body">
                                                                                    <div className="row">

                                                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-12" onClick={() => selectPlot(item)} style={{ marginRight: "10px" }}>
                                                                                            <div className="row">
                                                                                                <div className="select-plot-outer-blk" style={{ cursor: "pointer" }}>

                                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-4" >
                                                                                                        <div className="product-ouetr-blk" >
                                                                                                            <div className="select-plot-icon-circle select-plot-mr-t3">
                                                                                                                <img src={loading ? logo : item.CROP_IMAGE} className="img-fluid" style={{ height: "79px", width: "100px" }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-9" >
                                                                                                        <div className="plot-name" style={{ textAlign: "center" }}>{item.CROP_NAME}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default DeseasControl